<template>
  <v-card
    class="pt-8 pb-2"
    :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-12'"
  >
    <v-btn @click="close" class="closeButton" icon color="black">
      <v-icon>clear</v-icon>
    </v-btn>
    <v-img
      :height="$vuetify.breakpoint.smAndDown ? '100px' : '200px'"
      contain
      src="@/assets/images/undraw_no_data.svg"
    ></v-img>
    <v-card-title
      v-if="!showErrorMessage"
      class="justify-center mb-2"
      :class="$vuetify.breakpoint.smAndDown ? 'mt-0 pt-0' : 'mt-5'"
      >{{
        $t("dashboardHistoryComponents.patientBulkReminderDialog.title")
      }}</v-card-title
    >
    <v-card-title
      v-if="showErrorMessage"
      class="justify-center mb-2"
      :class="$vuetify.breakpoint.smAndDown ? 'mt-0 pt-0' : 'mt-5'"
      >{{
        $t("dashboardHistoryComponents.patientBulkReminderDialog.errorTitle")
      }}</v-card-title
    >
    <v-card-subtitle>
      <span v-if="!showErrorMessage">
        {{
          $t(
            "dashboardHistoryComponents.patientBulkReminderDialog.reminderText.preText",
            { "0": emails.length }
          )
        }}
        <br />
        <br />{{
          $t(
            "dashboardHistoryComponents.patientBulkReminderDialog.reminderText.sendText"
          )
        }}
      </span>
      <div
        v-if="showErrorMessage"
        class="red--text mt-2 px-2"
        v-html="
          $t(
            'dashboardHistoryComponents.patientBulkReminderDialog.errorSubtitle'
          )
        "
      ></div>
      <v-row justify="center" no-gutters>
        <v-col
          ><v-list max-height="250px" style="overflow-y: auto">
            <v-list-item v-for="email in emails" :key="email">
              <v-list-item-icon>
                <v-icon :color="getEmailColor(email)">{{
                  getEmailIcon(email)
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ email }}</v-list-item-title>
            </v-list-item>
          </v-list></v-col
        >
      </v-row>

      <v-row justify="center">
        <v-col cols="10">
          <v-btn
            width="100%"
            rounded
            color="primary"
            :loading="loading"
            :disabled="loading"
            class="ma-2"
            @click="SendReminders"
            >{{ $t("buttons.bulkReminder") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="sendingCount > 0" justify="center">
        {{
          $t(
            "dashboardHistoryComponents.patientBulkReminderDialog.sendingMessage",
            { "0": sendingCount, "1": emails.length }
          )
        }}</v-row
      >
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { sendReminderToCompleteAssessment } from "@/customApi";

export default {
  props: {
    incompleteAssessments: Array
  },
  data() {
    return {
      assessments: [],
      emails: [],
      loader: null,
      loading: false,
      showErrorMessage: false,
      sendingCount: 0,
      sent: [],
      failed: []
    };
  },
  mounted() {
    this.getEmailsToRemind();
  },
  methods: {
    getEmailsToRemind() {
      const vm = this;
      const allEmails = this.incompleteAssessments.map(
        item => item.email.value
      );
      const filteredEmails = allEmails.filter(email => email != null);
      this.emails = [...new Set(filteredEmails)];

      this.assessments = this.emails.map(getLatestAssessmentForEmail);
      function getLatestAssessmentForEmail(email) {
        //get all assessments with this email
        const assessmentsForGivenEmail = vm.incompleteAssessments.filter(
          item => item.email.value == email
        );

        if (assessmentsForGivenEmail.length == 1) {
          return assessmentsForGivenEmail[0];
        } else {
          const latest = assessmentsForGivenEmail.reduce(function(a, b) {
            const date1 = vm.getFormattedDate(a.createdAt);
            const date2 = vm.getFormattedDate(b.createdAt);
            return date1 > date2 ? a : b;
          });
          return latest;
        }
      }

      this.showSendBulkRemindersDialog = true;
    },
    getFormattedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    async SendReminders() {
      for (const assessment of this.assessments) {
        await this.SendOneReminder(assessment);
      }
      this.sendingCount = 0;
      if (this.failed.length === 0) {
        this.$emit("sent");
        this.close();
      } else {
        this.emails = this.failed;
        this.showErrorMessage = true;
        this.resetLoader();
      }
    },
    SendOneReminder(assessment) {
      this.sendingCount++;
      this.showErrorMessage = false;
      this.loader = "loading";

      let currentUrl = window.location.href;
      let baseUrl = currentUrl.split("#")[0];
      baseUrl +=
        assessment.assessmentType === "Desk assessment"
          ? "#/deskassessment/"
          : "#/burnoutassessment/";

      let state = assessment.state == "Pending" ? "Pending" : "InProgress";
      const assessmentDto = {
        id: assessment.id,
        assessmentType: assessment.assessmentType,
        baseUrl: baseUrl,
        state: state
      };

      return sendReminderToCompleteAssessment(this._i18n.locale, assessmentDto)
        .then(() => {
          this.sent.push(this.emails[this.sendingCount - 1]);
        })
        .catch(() => {
          this.failed.push(this.emails[this.sendingCount - 1]);
          this.showErrorMessage = true;
          this.resetLoader();
        });
    },
    getEmailColor(email) {
      if (this.sent.includes(email)) {
        return "success";
      } else if (this.failed.includes(email)) {
        return "error";
      }
      return "primary";
    },
    getEmailIcon(email) {
      if (this.sent.includes(email)) {
        return "check_circle";
      } else if (this.failed.includes(email)) {
        return "close";
      }
      return "check_circle";
    },
    close() {
      this.resetLoader();
      this.showErrorMessage = false;
      this.sent = [];
      this.failed = [];
      this.$emit("input", false);
    },
    resetLoader() {
      this.loading = false;
      this.loader = null;
    }
  },
  watch: {
    loader() {
      const load = this.loader;
      this[load] = !this[load];
    }
  }
};
</script>

<style scoped>
.closeButton {
  position: absolute;
  right: 0;
  top: 0;
}

/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>
