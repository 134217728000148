var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "fill-height",
      class: _vm.loading ? "" : "pt-8",
      attrs: {
        align: _vm.loading ? "center" : "start",
        justify: "center",
        "no-gutters": ""
      }
    },
    [
      _vm.loading
        ? _c("loading-symbol", {
            attrs: { size: 120, color: _vm.$vuetify.theme.currentTheme.primary }
          })
        : _vm._e(),
      !_vm.confirm && !_vm.loading
        ? _c(
            "v-col",
            { attrs: { lg: "4", md: "6", sm: "8", cols: "10" } },
            [
              _c("login-options", {
                attrs: {
                  signUp: false,
                  enterpriseSSOLoginAvailable: _vm.showEnterpriseSSOLogin,
                  ssoIdpName: _vm.idpName,
                  ssoTeamId: _vm.teamId,
                  automaticSSOSignIn: _vm.automaticSSOSignIn,
                  "error-msg": _vm.errorMessage,
                  isReturningToPartialAssessment:
                    _vm.isReturningToPartialAssessment
                },
                on: {
                  confirm: function($event) {
                    return _vm.signInWithEmail($event)
                  },
                  forgotPassword: function($event) {
                    return _vm.$router.push("ResetPassword")
                  },
                  completeSignIn: function($event) {
                    return _vm.completeSignIn()
                  },
                  confirmSignUp: function($event) {
                    return _vm.redirectFromSignInToSignUp($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.loading && _vm.confirm
        ? _c(
            "v-col",
            { attrs: { lg: "4", md: "6", sm: "12" } },
            [
              _c("confirm-code", {
                attrs: { email: _vm.email, password: _vm.redirectPassword },
                on: {
                  confirmed: function($event) {
                    return _vm.userSignedUp()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "2500", color: "red" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { dark: "", text: "" },
                        on: {
                          click: function($event) {
                            _vm.showErrorMessage = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showErrorMessage,
            callback: function($$v) {
              _vm.showErrorMessage = $$v
            },
            expression: "showErrorMessage"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }