import i18n from "@/plugins/i18n.js";

export default {
  methods: {
    getBenefitText(benefit) {
      if (benefit === "reducePain") {
        return i18n.t("insightsPage.table.benefits.reducePain");
      } else if (benefit === "improveWellbeing") {
        return i18n.t("insightsPage.table.benefits.improveWellbeing");
      } else if (benefit === "preventProductivityLoss") {
        return i18n.t("insightsPage.table.benefits.preventProductivityLoss");
      }
      return i18n.t("insightsPage.table.benefits.productivityGain");
    },
    getImpactText(score) {
      if (score > 7) {
        return i18n.t("insightsPage.table.impact.veryHigh");
      } else if (score > 4) {
        return i18n.t("insightsPage.table.impact.high");
      }
      return i18n.t("insightsPage.table.impact.medium");
    },
    getIssueText(action) {
      return i18n.t("insightsPage.table.issues." + action);
    },
    getActionText(action) {
      return i18n.t("insightsPage.table.actions." + action);
    },
    getTooltipText(insights) {
      var keyName =
        "insightsPage.table.descriptiveTooltips." + insights[0].actionName;

      let benefits = insights.flatMap(x => x.benefits);
      return benefits.includes("reducePain") && i18n.te(keyName + ".withPain")
        ? i18n.t(keyName + ".withPain")
        : i18n.t(keyName + ".standard");
    },
    getIndividualAverageScore(insights) {
      return (
        insights.map(x => x.score).reduce((a, b) => a + b, 0) / insights.length
      );
    }
  }
};
