<template>
  <v-scroll-y-transition>
    <div
      class="rounded-lg text-body-2 tooltiptext text-center"
      :class="position"
      :style="'width:' + width + 'px'"
    >
      {{ text }}
    </div>
  </v-scroll-y-transition>
</template>

<script>
export default {
  name: "VitrueOnboardingTooltip",
  props: {
    position: {
      type: String,
      default: "right"
    },
    width: {
      type: Number,
      default: 150
    },
    text: String
  }
};
</script>

<style scoped>
.tooltiptext {
  background: var(--v-vitrueGrey-base);
  color: #fff;
  border-radius: 8px;
  border: 2px solid var(--v-vitrueGrey-base);
  padding: 5px 0;
  position: absolute;
  bottom: 0;
  overflow: visible;
}

.tooltiptext::after {
  content: "";
  position: absolute;
}

.right {
  left: 120%;
}

.right::after {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--v-vitrueGrey-base);
  left: 0;
  bottom: 50%;
  transform: translate(-100%, 50%);
}

.bottom {
  transform: translate(-0%, 100%);
}

.bottom::after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--v-vitrueGrey-base);
  left: 25%;
  top: 0;
  transform: translate(-50%, -100%);
}
</style>
