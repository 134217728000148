import { render, staticRenderFns } from "./AnalyticsTable.vue?vue&type=template&id=397710dc&"
import script from "./AnalyticsTable.vue?vue&type=script&lang=js&"
export * from "./AnalyticsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('397710dc')) {
      api.createRecord('397710dc', component.options)
    } else {
      api.reload('397710dc', component.options)
    }
    module.hot.accept("./AnalyticsTable.vue?vue&type=template&id=397710dc&", function () {
      api.rerender('397710dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/analytics/AnalyticsTable.vue"
export default component.exports