var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "8", xl: "6" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pt-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "auto" } },
                        [
                          _c("v-img", {
                            attrs: {
                              width: "75px",
                              src: require("@/assets/images/glowingTick.png")
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 mr-8 text-center",
                          attrs: { cols: "auto", "align-self": "center" }
                        },
                        [
                          _c("h1", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.breakpoint.xs
                                    ? _vm.$t("deskAssessment.report.doneShort")
                                    : _vm.$t("deskAssessment.report.done")
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.viewingOwnResults
                ? _c(
                    "v-col",
                    { staticClass: "pa-0 text-center", attrs: { cols: "12" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("deskAssessment.report.nextStep")) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.isDeskAssessment
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-center text-h6 my-3",
                      attrs: { cols: "12", id: "painCoachTeaser" }
                    },
                    [_vm._v(" " + _vm._s(_vm.painCoachTeaserText) + " ")]
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  class: _vm.viewingOwnResults ? "" : "pt-6",
                  attrs: { cols: "12", sm: "8", lg: "6" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        right: "",
                        "max-width": "200px",
                        color: "transparent"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "div",
                                _vm._g(_vm._b({}, "div", attrs, false), on),
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        id: "dashboardRedirect",
                                        color: "secondary",
                                        large: "",
                                        rounded: "",
                                        width: "100%",
                                        loading: _vm.sendingReport
                                      },
                                      on: { click: _vm.goToDashboard }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("buttons.goToDashboard")
                                        ) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("vitrue-tooltip", {
                        attrs: { message: _vm.$t("tooltips.signUpTooltip") }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.viewingOwnResults
                ? _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              id: "sendReportCheckbox",
                              disabled: _vm.reportSent,
                              "hide-details": "",
                              label: _vm.reportSent
                                ? _vm.$t(
                                    "deskAssessment.report.emailSentSuccessfully"
                                  )
                                : _vm.$t("deskAssessment.report.sendEmail")
                            },
                            model: {
                              value: _vm.emailReportCheckbox,
                              callback: function($$v) {
                                _vm.emailReportCheckbox = $$v
                              },
                              expression: "emailReportCheckbox"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isDeskAssessment
                ? _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("v-img", {
                        staticClass: "mt-6 zoom",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          src: _vm.imageToShow,
                          contain: "",
                          "max-height": "300px"
                        },
                        on: { click: _vm.goToDashboard }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showError
                ? _c("v-col", { attrs: { cols: "12" } }, [
                    _c("p", {
                      staticClass:
                        "px-4 py-0 my-0 text-body-1 warning--text text-center",
                      attrs: { id: "failedToSendError" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("deskAssessment.report.emailFailedToSend")
                        )
                      }
                    })
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }