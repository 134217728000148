<template>
  <v-row
    justify="center"
    class="fill-height"
    no-gutters
    :class="reportPageSignUp ? '' : 'pt-8'"
  >
    <v-col
      v-if="!loading"
      :cols="reportPageSignUp ? 12 : 10"
      :lg="reportPageSignUp ? 12 : 5"
      :md="reportPageSignUp ? 12 : 7"
      :sm="reportPageSignUp ? 12 : 9"
    >
      <login-options
        v-show="!confirm"
        signUp
        :errorMsg="errorMessage"
        :reportPageSignUp="reportPageSignUp"
        :enterpriseSSOLoginAvailable="showEnterpriseSSOLogin"
        :ssoIdpName="teamIdpName"
        :simpleUserSignup="simpleUserSignup"
        :fromLinkGenerator="fromLinkGenerator"
        :codeConfirmSignIn="codeConfirmed"
        :assessmentId="assessmentId"
        :isReturningToPartialAssessment="isReturningToPartialAssessment"
        @setInitialSignupStep="firstSignUpStep = $event"
        @confirmSignUp="confirmSignUp($event)"
        @regularSignInComplete="$emit('regularSignInComplete')"
        :autoJoinTeamId="autoJoinTeamId"
        :autoJoinTeamInfo="autoJoinTeamInfo"
      />
      <confirm-code
        v-show="confirm"
        signUp
        :email="email"
        :password="password"
        :reportPageSignUp="reportPageSignUp"
        :fromLinkGenerator="fromLinkGenerator"
        :assessmentId="assessmentId"
        @confirmed="confirm = false"
      />
    </v-col>

    <v-col
      v-if="showJoinCreateTeamSwitch"
      cols="12"
      align-self="end"
      class="text-center text-body-1"
      >{{
        simpleUserSignup
          ? $t("authentication.signUp.simpleUser.text")
          : $t("authentication.signUp.regularUser.text")
      }}
      <v-hover v-slot="{ hover }">
        <span
          :class="hover ? 'text-decoration-underline' : ''"
          class="primary--text"
          style="cursor: pointer"
          @click="simpleUserSignup = !simpleUserSignup"
          >{{
            " " +
            (simpleUserSignup
              ? $t("authentication.signUp.simpleUser.link")
              : $t("authentication.signUp.regularUser.link"))
          }}</span
        >
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import LoginOptions from "./LoginOptions.vue";
import ConfirmCode from "./ConfirmCode.vue";
import { getJoiningTeamsInfo } from "@/customApi";

export default {
  name: "SignUp",
  props: {
    reportPageSignUp: Boolean,
    errorMsg: String,
    assessmentId: String,
    fromLinkGenerator: Boolean,
    isReturningToPartialAssessment: Boolean
  },
  data() {
    return {
      email: "",
      password: "",
      code: "",
      confirm: false,
      simpleUserSignup: false,
      firstSignUpStep: true,
      userExists: false,
      errorMessage: this.errorMsg,
      codeConfirmed: false,
      autoJoinTeamId: undefined,
      autoJoinTeamInfo: null,
      loading: true
    };
  },
  components: {
    LoginOptions,
    ConfirmCode
  },
  async mounted() {
    await this.loadTeamInfo();

    if (this.$route.query.error) {
      this.errorMessage = this.$route.query.error;
    }

    if (this.signedIn) {
      this.$router.push("/", () => {});
    }
  },
  computed: {
    ...mapGetters(["teamIdpName", "teamId", "userEmail", "signedIn"]),
    showJoinCreateTeamSwitch() {
      return !this.autoJoinTeamId && this.firstStepOfMainPageSignUp;
    },
    highZoom() {
      return window.devicePixelRatio > 1.25;
    },
    showEnterpriseSSOLogin() {
      return (
        !!this.teamIdpName &&
        (this.teamIdpName.toLowerCase().includes("azure") ||
          this.teamIdpName.toLowerCase().includes("okta"))
      );
    },
    firstStepOfMainPageSignUp() {
      return !this.reportPageSignUp && !this.confirm && this.firstSignUpStep;
    }
  },
  methods: {
    confirmSignUp(user) {
      this.email = user.email;
      this.password = user.password;
      this.confirm = true;
    },
    async loadTeamInfo() {
      try {
        this.loading = true;
        if (this.$route.query.autojointeamid) {
          this.autoJoinTeamId = this.$route.query.autojointeamid;
          this.simpleUserSignup = !!this.autoJoinTeamId;
          this.autoJoinTeamInfo = await getJoiningTeamsInfo(
            this.autoJoinTeamId
          );
        }
      } catch (ex) {
        this.$logger.captureException(ex);
        this.autoJoinTeamId = null;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
