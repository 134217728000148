import { DeskAssessmentRecommendations } from "@/services/recommendations/deskassessment/recommendations.js";
import { BurnoutRecommendations } from "@/services/recommendations/burnout/recommendations.js";

function GetDeskAssessmentRecommendationCards(featureFlags) {
  let allCards = DeskAssessmentRecommendations.filter(x => !x.fixed);
  let recommendations = getDeskCardIds(featureFlags);
  recommendations = [...new Set(recommendations)];
  let cards = [];

  recommendations.forEach(id => {
    if (id === "*") {
      let defaults = allCards.filter(x => !x.custom);
      cards = [...cards, ...defaults];
      return;
    }
    var cardToAdd = allCards.find(x => x.id === id);
    cards.push(cardToAdd);
  });

  return cards;
}

function GetBurnoutAssessmentRecommendationCards(featureFlags) {
  let allCards = BurnoutRecommendations;
  let recommendations = getBurnoutCardIds(featureFlags);
  let cards = {};
  Object.keys(recommendations).forEach(key => {
    cards[key] = [];
    let categoryCards = Object.values(allCards[key]);
    recommendations[key].forEach(id => {
      if (id === "*") {
        let defaults = categoryCards.filter(x => !x.custom);
        cards[key] = [...cards[key], ...defaults];
        return;
      }
      var cardToAdd = categoryCards.find(x => x.id === id);
      cards[key].push(cardToAdd);
    });
  });

  return cards;
}

const getDeskCardIds = featureFlags => {
  if (featureFlags.customRecommendations_ykavx) {
    return [
      "pain_coach",
      "feet_supported_upgrade_ykavx",
      "feet_supported_budget_ykavx",
      "back_support_upgrade_ykavx",
      "back_support_budget_ykavx",
      "wrist_rest_upgrade_ykavx",
      "raise_screen_budget",
      "raise_screen_upgrade_ykavx",
      "hours_upgrade_ykavx",
      "hours_budget_ykavx",
      "lunch_ykavx",
      "water_bottle_ykavx",
      "natural_light_ykavx",
      "fresh_air_ykavx",
      "meditation_ykavx",
      "clutter_ykavx",
      "smoking_ykavx",
      "poor_setup_ykavx",
      "custom_knee_pain_ykavx",
      "monitor_distance_ykavx",
      "beanBag",
      "wrist_rest_budget",
      "greenery",
      "breaks",
      "breakActivity",
      "breakIdeas"
    ];
  }
  if (featureFlags.customRecommendations_cMwjk) {
    return [
      "physical_activity_classes_cMwjk",
      "wellbeing_programme_cMwjk",
      "msk_programme_cMwjk",
      "self_care_pack_cMwjk",
      "your_support_cMwjk",
      "occupational_health_cMwjk",
      "react_cMwjk",
      "peer_support_cMwjk",
      "book_session_cMwjk",
      "*"
    ];
  }
  if (featureFlags.customRecommendations_kItGM) {
    return ["cycle_to_work_kItGM", "*"];
  }
  if (featureFlags.customRecommendations_ttqWel) {
    // Replacing back support upgrade card, wrist rest card and feet supported card
    return [
      "pain_coach",
      "in_person_visit_ttqWel",
      "regular_keyboard_upgrade_ttqWel",
      "regular_keyboard_budget_ttqWel",
      "ergonomic_keyboard_upgrade_ttqWel",
      "ergonomic_keyboard_budget_ttqWel",
      "ergonomic_mouse_upgrade_ttqWel",
      "ergonomic_mouse_budget_ttqWel",
      "regular_mouse_budget_ttqWel",
      "regular_mouse_upgrade_ttqWel",
      "headphones_budget_ttqWel",
      "headphones_upgrade_ttqWel",
      "low_noise_budget",
      "low_noise_upgrade",
      "high_noise_budget_ttqWel",
      "high_noise_upgrade_ttqWel",
      "low_temperature",
      "high_temperature",
      "back_support_budget_ttqWel",
      "back_support_upgrade_ttqWel",
      "back_support_upgrade_pregnant_ttqWel",
      "change_your_wrist_rest_card",
      "adjust_chair_home_ttqWel",
      "adjust_chair_resident_office_ttqWel",
      "adjust_chair_hq_ttqWel",
      "chair_ttqWel",
      "beanBag",
      "seat_wedge_lower_back_budget",
      "seat_wedge_lower_back_upgrade",
      "seat_wedge_hip_budget",
      "seat_wedge_hip_upgrade",
      "seat_wedge_lower_back_hip_budget",
      "seat_wedge_lower_back_hip_upgrade",
      "raise_your_screen_card_upgrade_ttqWel",
      "raise_your_screen_card_budget_ttqWel",
      "raise_your_monitor_card",
      "raise_your_laptop_stand_card",
      "feet_supported_upgrade_ttqWel",
      "feet_supported_budget_ttqWel",
      "wrist_rest_upgrade_ttqWel",
      "wrist_rest_budget_ttqWel",
      "greenery",
      "breaks",
      "breakActivity",
      "breakActivity_pregnant",
      "breakIdeas",
      "lunch",
      "water_bottle",
      "natural_light",
      "fresh_air",
      "meditation",
      "clutter",
      "smoking",
      "building_guide_ttqWel"
    ];
  }
  return ["*"];
};

const getBurnoutCardIds = featureFlags => {
  if (featureFlags.customRecommendations_ykavx) {
    return {
      exhaustion: [
        "health_and_wellbeing_hub_ykavx",
        "relaxing_activities",
        "engage_manager",
        "exercise",
        "muscle_relaxation",
        "sleep_hygeine",
        "fake_commute",
        "pomodoro",
        "regular_breaks",
        "deep_breathing",
        "morning_routine"
      ],
      mentaldistance: [
        "support_ykavx",
        "engage_manager",
        "reflection",
        "socialise",
        "morning_routine",
        "team_sport_ykavx"
      ],
      cognitiveimpairment: [
        "eat_healthy",
        "avoid_stimulants_ykavx",
        "exercise",
        "meditation",
        "sleep_hygeine",
        "regular_breaks",
        "fake_commute",
        "pomodoro"
      ],
      emotionalimpairment: ["*"],
      secondarysymptoms: ["*"]
    };
  }
  return {
    exhaustion: ["*"],
    mentaldistance: ["*"],
    cognitiveimpairment: ["*"],
    emotionalimpairment: ["*"],
    secondarysymptoms: ["*"]
  };
};

const getFixedDeskCards = featureFlags => {
  var ids = [];
  if (featureFlags.customRecommendations_ykavx) {
    ids = [
      "be_me_app_ykavx",
      "desk_stretches_ykavx",
      "aches_and_pains_ykavx",
      "back_tension_ykavx",
      "personal_energy_ykavx",
      "health_and_wellbeing_hub_ykavx",
      "anytime_health_line_ykavx"
    ];
  }
  return DeskAssessmentRecommendations.filter(
    x => x.fixed && ids.includes(x.id)
  );
};

const getFixedBurnoutCards = featureFlags => {
  var ids = [];
  if (featureFlags.customRecommendations_ykavx) {
    ids = [
      "speak_to_manager_ykavx",
      "healthy_minds_helpline_ykavx",
      "general_burnout_ykavx",
      "manager_burnout_ykavx",
      "work_related_stress_ykavx",
      "working_from_home_mental_health_ykavx",
      "cbt_techniques_ykavx",
      "personal_energy_ykavx",
      "be_me_app_ykavx"
    ];
  } else if (featureFlags.customRecommendations_ttqWel) {
    ids = [
      "support_services_ttqWel",
      "wellbeing_resources_ttqWel",
      "mental_health_support_ttqWel",
      "medical_care_ttqWel",
      "learning_resources_ttqWel"
    ];
  }
  return BurnoutRecommendations.fixed.filter(x => ids.includes(x.id));
};

export {
  GetDeskAssessmentRecommendationCards,
  GetBurnoutAssessmentRecommendationCards,
  getDeskCardIds,
  getBurnoutCardIds,
  getFixedDeskCards,
  getFixedBurnoutCards
};
