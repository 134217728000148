var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    style: _vm.largeLayout ? "" : "max-width: 250px",
    attrs: {
      items: _vm.languages,
      "prepend-inner-icon": "mdi-earth",
      "disable-lookup": "",
      dense: _vm.largeLayout ? false : true,
      "hide-details": "",
      solo: !_vm.largeLayout,
      flat: !_vm.largeLayout,
      outlined: _vm.largeLayout,
      label: _vm.$t("account.personalSettings.textFieldLabels.language"),
      "item-text": "text",
      "item-value": "id"
    },
    model: {
      value: _vm.languageValue,
      callback: function($$v) {
        _vm.languageValue = $$v
      },
      expression: "languageValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }