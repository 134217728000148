<template>
  <v-autocomplete
    style="min-width:125px;"
    :style="`max-width:${maxWidth}px`"
    v-model="tags"
    :items="items"
    :label="label"
    multiple
    hide-details
    clearable
    class="pb-2"
    item-text="text"
    item-value="value"
    return-object
    @input="$emit('input', tags)"
  >
    <template v-slot:selection="{ item, index }">
      <span class="font-weight-regular" v-if="index < 1">{{ item.text }}</span>
      <span v-if="index === 1" class="grey--text caption ml-1"
        >(+{{ tags.length - 1 }}
        {{
          $t(
            "dashboardHistoryComponents.assessmentHistoryTable.text.additionalTags"
          )
        }})</span
      >
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{
              item === "No Tag"
                ? $t(
                    "dashboardHistoryComponents.assessmentHistoryTable.text.noTag"
                  )
                : item.text
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:no-data>
      <span class="ml-3 caption">{{
        $t("dashboardHistoryComponents.assessmentHistoryTable.text.noTags")
      }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "TagFilter",
  props: {
    label: String,
    items: Array,
    maxWidth: {
      type: Number,
      default: 300
    }
  },
  data() {
    return { tags: [] };
  }
};
</script>
