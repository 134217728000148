import locale from "@/plugins/i18n.js";

export default {
  emailRequiredSignup: v =>
    !!v || locale.t("textInputRules.emailRequiredSignup"),
  emailRequired: v => !!v || locale.t("textInputRules.emailRequired"),
  emailValid: v => /.+@.+\..+/.test(v) || locale.t("textInputRules.emailValid"),
  passwordRequired: v =>
    v !== "" || locale.t("textInputRules.passwordRequired"),
  passwordAtLeast8Characters: v =>
    v.length > 7 || locale.t("textInputRules.passwordAtLeast8Characters"),
  passwordContainsNumber: v =>
    /\d/.test(v) || locale.t("textInputRules.passwordContainsNumber"),
  passwordContainsSpecialCharacter: v =>
    //eslint-disable-next-line
    /[~`!#$£€¬%\^&*()+=_\-\[\]\\'@;,./{}|\\":<>\?]/g.test(v) ||
    locale.t("textInputRules.passwordContainsSpecialCharacter"),
  passwordContainsBothCases: v =>
    (v.toUpperCase() !== v && v.toLowerCase() !== v) ||
    locale.t("textInputRules.passwordContainsBothCases"),
  codeRequired: v => !!v || locale.t("textInputRules.codeRequired"),
  nameRequired: v => !!v || locale.t("textInputRules.nameRequired"),
  roleRequired: v => !!v || locale.t("textInputRules.roleRequired"),
  companyNameRequired: v =>
    !!v || locale.t("textInputRules.companyNameRequired"),
  genericRequired: v => !!v || locale.t("textInputRules.genericRequired")
};
