import { render, staticRenderFns } from "./AdminTour.vue?vue&type=template&id=48831b65&scoped=true&"
import script from "./AdminTour.vue?vue&type=script&lang=js&"
export * from "./AdminTour.vue?vue&type=script&lang=js&"
import style0 from "./AdminTour.vue?vue&type=style&index=0&id=48831b65&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48831b65",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48831b65')) {
      api.createRecord('48831b65', component.options)
    } else {
      api.reload('48831b65', component.options)
    }
    module.hot.accept("./AdminTour.vue?vue&type=template&id=48831b65&scoped=true&", function () {
      api.rerender('48831b65', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/account/onboarding/AdminTour.vue"
export default component.exports