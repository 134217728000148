var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.item.tags, function(tag) {
        return _c("v-hover", {
          key: tag.id,
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function(ref) {
                  var hover = ref.hover
                  return [
                    _c(
                      "v-chip",
                      {
                        staticClass: "ma-1",
                        class: hover && _vm.editable ? "white--text" : "",
                        attrs: {
                          outlined: !hover || !_vm.editable,
                          "close-icon": "close",
                          close: _vm.editable && hover && !_vm.disabled,
                          color: tag.color,
                          small: ""
                        },
                        on: {
                          "click:close": function($event) {
                            return _vm.deleteTag(_vm.item, tag)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "chipText" }, [
                          _vm._v(" " + _vm._s(tag.name) + " ")
                        ])
                      ]
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      }),
      _vm.addMultipleTags && _vm.item.tags.length !== 0 && !_vm.disabled
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { icon: "", color: "primary" },
              on: { click: _vm.toggleAddTags }
            },
            [_c("v-icon", [_vm._v(" mdi-plus ")])],
            1
          )
        : _vm._e(),
      _vm.editable && !_vm.disabled
        ? _c(
            "div",
            {
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _vm.item.tags.length === 0 || _vm.showAddTagDropdown
                ? _c("v-combobox", {
                    staticClass: "mx-2",
                    staticStyle: { "max-width": "50%" },
                    attrs: {
                      items: _vm.tagsToDisplayInDropDown,
                      label: _vm.$t(
                        "dashboardHistoryComponents.assessmentHistoryTags.addTagLabel"
                      ),
                      solo: "",
                      flat: "",
                      dense: "",
                      "hide-details": "",
                      "item-text": "name",
                      disabled: _vm.disabled
                    },
                    on: {
                      input: function($event) {
                        return _vm.addTag(
                          _vm.item,
                          $event.name ? $event.name : $event
                        )
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-data",
                          fn: function() {
                            return [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "dashboardHistoryComponents.assessmentHistoryTags.pressEnterInstruction"
                                            )
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-chip",
                                { attrs: { color: item.color, outlined: "" } },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            ]
                          }
                        },
                        _vm.manageTags
                          ? {
                              key: "append-item",
                              fn: function() {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-3 mb-0",
                                      attrs: { color: "primary", small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("showManageTags")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("buttons.manageTags"))
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }