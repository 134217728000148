var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "px-4 pt-4 emojiContainer",
      staticStyle: { "overflow-x": "hidden" },
      attrs: { color: _vm.feedbackColor }
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center", "no-gutters": "" } },
            [
              !_vm.submitted
                ? _c(
                    "div",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c(
                            "p",
                            { class: "display-1 " + _vm.textColor + "--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("feedback.dialog.title")) +
                                  " "
                              )
                            ]
                          ),
                          _c("p", { class: _vm.textColor + "--text" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("feedback.dialog.info")) + " "
                            )
                          ]),
                          _c("feedback-emojis", {
                            attrs: { showLabels: "", dark: _vm.isDark() },
                            on: {
                              input: function($event) {
                                _vm.showGiveFeedbackMessage = false
                              },
                              hover: function($event) {
                                _vm.hoverEmoji = $event
                              }
                            },
                            model: {
                              value: _vm.reaction,
                              callback: function($$v) {
                                _vm.reaction = $$v
                              },
                              expression: "reaction"
                            }
                          }),
                          _c("v-slide-x-transition", [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showGiveFeedbackMessage,
                                    expression: "showGiveFeedbackMessage"
                                  }
                                ],
                                staticClass:
                                  "errorMessage white--text mt-2 px-2"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("feedback.dialog.emojiInstruction")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("v-textarea", {
                            staticStyle: { "min-height": "80px" },
                            attrs: {
                              clearable: "",
                              color: _vm.textColor,
                              dark: _vm.isDark(),
                              "no-resize": "",
                              "hide-details": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "feedback.dialog.textInsutruction"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3734867194
                            ),
                            model: {
                              value: _vm.feedback,
                              callback: function($$v) {
                                _vm.feedback = $$v
                              },
                              expression: "feedback"
                            }
                          }),
                          _c("v-slide-x-transition", [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showErrorSubmittingMessage,
                                    expression: "showErrorSubmittingMessage"
                                  }
                                ],
                                staticClass:
                                  "errorMessage white--text mt-2 px-2"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("feedback.dialog.errorMessage")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pt-3", attrs: { justify: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-3",
                              attrs: {
                                "max-width": "200px",
                                outlined: "",
                                color: _vm.secondaryButtonColor
                              },
                              on: {
                                click: function($event) {
                                  return _vm.resetAndClose()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-3",
                              attrs: {
                                "max-width": "200px",
                                color: _vm.primaryButtonColor
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submitFeedback()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.submit")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.submitted
                ? _c(
                    "v-row",
                    {
                      staticStyle: { "min-height": "400px" },
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "closeButton",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.resetAndClose()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: require("@/assets/gifs/hugEmoji.gif"),
                                  "max-height": "150px",
                                  "max-width": "150px",
                                  contain: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "headline blue--text text-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("feedback.dialog.successTitle")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "body-1 text-center",
                              attrs: { "text-center": "" }
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("feedback.dialog.successInfo"))
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "body-1 gray--text text-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("feedback.dialog.closeCountdown", {
                                      "0": _vm.timeLeft
                                    })
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }