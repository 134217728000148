<template>
  <v-card
    class="elevation-0"
    :loading="loading"
    :min-height="loading ? '500px' : ''"
  >
    <div :class="reportPageSignUp ? 'pa-5' : ''" v-if="!loading">
      <div v-if="!enterpriseSSOLoginAvailable">
        <p
          class="text-center mb-6"
          :class="reportPageSignUp ? 'text-h4' : 'text-h2'"
        >
          {{ titleText }}
        </p>
        <p
          v-if="signUp && autoJoinTeam"
          class="text-h5 vitrueDarkGrey--text text-center mb-6"
        >
          {{
            showEmailField
              ? $t("authentication.loginOptions.createAccount")
              : $t("authentication.loginOptions.createPassword")
          }}
        </p>
      </div>

      <v-row
        justify="center"
        :no-gutters="reportPageSignUp"
        v-if="enterpriseSSOLoginAvailable"
      >
        <v-col :cols="columnWidth">
          <v-row justify="center">
            <v-img
              :src="require('@/assets/images/' + ssoLoginCustomisation.image)"
              contain
              :height="ssoLoginCustomisation.size"
              :max-width="ssoLoginCustomisation.size"
              class="mb-4"
            ></v-img>
          </v-row>
          <v-btn
            width="100%"
            color="primary"
            class="text-none elevation-0 googleBtn mt-2 py-5"
            @click="signInWithEnterpriseSSO()"
            ><v-icon
              v-if="ssoLoginCustomisation.loginButtonIcon"
              class="mr-3"
              >{{ ssoLoginCustomisation.loginButtonIcon }}</v-icon
            >
            {{ $t(ssoLoginCustomisation.text) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" :no-gutters="reportPageSignUp" v-else>
        <v-col :cols="columnWidth">
          <div v-if="showSocialSignOn">
            <v-btn
              id="googleButton"
              width="100%"
              color="primary"
              outlined
              class="text-none elevation-0 googleBtn py-5"
              @click="signInWithFederation('Google')"
            >
              <v-img
                src="@/assets/images/googleLogo.png"
                contain
                height="20px"
                max-width="20px"
                class="mr-4"
              ></v-img>
              {{ $t("authentication.loginOptions.google") }}
            </v-btn>
            <v-divider />
            <v-btn
              width="100%"
              color="disabled"
              outlined
              class="text-none elevation-0 googleBtn py-5 mt-3"
              @click="signInWithFederation('SignInWithApple')"
            >
              <v-icon class="mr-3">mdi-apple</v-icon>
              {{ $t("authentication.loginOptions.apple") }}
            </v-btn>
            <p
              v-if="reportPageSignUp && !enterpriseSSOLoginAvailable"
              class="text-center text-h5 my-4"
              :class="reportPageSignUp ? 'text-h4' : 'text-h2'"
            >
              {{ $t("authentication.loginOptions.or") }}
            </p>
            <v-divider v-else class="mb-8 mx-3" />
          </div>
          <v-form ref="form" v-model="valid" @submit.prevent>
            <v-tooltip top :disabled="!disableEmailField">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-text-field
                    id="email"
                    v-if="showEmailField"
                    filled
                    :label="
                      signUp
                        ? $t('authentication.loginOptions.labels.workEmail')
                        : $t('authentication.loginOptions.labels.email')
                    "
                    :rules="emailRules"
                    v-model="email"
                    type="email"
                    prepend-inner-icon="email"
                    @keydown.enter="confirm"
                    :disabled="disableEmailField || autoJoinDisabledByTeam"
                    @keydown="keyDownEvent"
                    data-cy="email"
                  />
                </div>
              </template>
              <span>{{ $t("signUpSimpleUserDialog.tooltip") }}</span>
            </v-tooltip>
            <v-text-field
              id="password"
              v-if="showPasswordField"
              :autocomplete="reportPageSignUp || signUp ? 'new-password' : ''"
              filled
              class="mb-2"
              :label="$t('authentication.loginOptions.labels.password')"
              v-model="password"
              type="password"
              prepend-inner-icon="lock"
              :rules="passwordRules"
              @keydown.enter="confirm"
              @keydown="keyDownEvent"
              hide-details="auto"
              data-cy="password"
            />
            <v-btn
              text
              color="primary"
              class="text-none text-body-1 pl-0 mb-3"
              @click="$emit('forgotPassword')"
              v-if="!signUp"
            >
              {{ $t("authentication.loginOptions.forgotPassword") }}
            </v-btn>
          </v-form>

          <alert-message
            v-if="errorMessage"
            :message="errorMessage"
            type="error"
          />
          <alert-message v-else-if="customSSOSignInError" type="error"
            ><i18n :path="customSSOSignInError.text">
              <template slot="link">
                <a target="_self" :href="customSSOSignInError.link">{{
                  $t(customSSOSignInError.linkText)
                }}</a>
              </template>
            </i18n></alert-message
          >
          <alert-message
            v-if="successMessage"
            :message="successMessage"
            type="success"
          />
          <alert-message
            v-if="autoJoinDisabledByTeam"
            :message="$t('authentication.autoJoinTeamSettingDisabled')"
            type="error"
          />
          <v-btn
            id="confirmButton"
            class="mt-6 text-none"
            color="primary"
            width="100%"
            @click="confirm"
            :loading="confirmLoading"
            :disabled="autoJoinDisabledByTeam"
          >
            {{
              showSignupButton
                ? signUpButtonText
                : $t("authentication.loginOptions.continueWithEmail")
            }}
          </v-btn>
          <v-row
            v-if="signUp && !userExists && !reportPageSignUp"
            no-gutters
            align="center"
            ><v-col cols="auto"
              ><v-checkbox v-model="termsAndConditions" readonly /></v-col
            ><v-col class="vitrueGrey--text">
              {{ $t("authentication.loginOptions.termsOfService.text") }}
              <v-hover v-slot="{ hover }"
                ><span
                  :class="hover ? 'text-decoration-underline' : ''"
                  :style="hover ? 'cursor: pointer' : ''"
                  class="primary--text"
                  @click="openTermsOfService()"
                  >{{
                    $t("authentication.loginOptions.termsOfService.link")
                  }}</span
                >
              </v-hover>
            </v-col>
          </v-row>

          <v-hover v-slot="{ hover }">
            <p v-if="!reportPageSignUp" class="text-center mt-6">
              <span class="vitrueGrey--text mr-1">
                {{
                  signUp
                    ? $t("authentication.loginOptions.existingAccount.signUp")
                    : $t("authentication.loginOptions.existingAccount.signIn")
                }}</span
              >
              <span
                :class="hover ? 'text-decoration-underline' : ''"
                :style="hover ? 'cursor: pointer' : ''"
                class="primary--text"
                @click="redirect"
                >{{
                  signUp
                    ? $t("authentication.loginOptions.signIn")
                    : $t("authentication.loginOptions.signUp")
                }}</span
              >
            </p>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { Auth } from "aws-amplify";
import AlertMessage from "../account/AlertMessage";
import SSOLoginCustomisation from "@/assets/json/common/SSOLoginCustomisation.json";
import { mapGetters, mapMutations } from "vuex";
import Rules from "@/utils/stringValidation";
import {
  confirmEndUserSignUp,
  associateEndUserReportSignUpToLinkGeneratorAssessment,
  authUserExists,
  userTeamMemberInformation,
  joinEndUserToTeamViaSignupLink,
  isFromExternalProvider
} from "@/customApi";
import {
  completeUserSignInWithoutRedirect,
  completeUserSignIn
} from "@/services/authorization/post-auth-setup.js";
import { EventBus } from "@/services/events/event-bus.js";
import DomainService from "@/services/domain-service.js";
import { startNewAssessment } from "../../customApi";

export default {
  name: "LoginOptions",
  components: {
    AlertMessage
  },
  props: {
    signUp: Boolean,
    reportPageSignUp: Boolean,
    isReturningToPartialAssessment: Boolean,
    enterpriseSSOLoginAvailable: Boolean,
    ssoIdpName: String,
    ssoTeamId: String,
    simpleUserSignup: Boolean,
    fromLinkGenerator: Boolean,
    errorMsg: String,
    codeConfirmSignIn: Boolean,
    assessmentId: String,
    autoJoinTeamId: String,
    autoJoinTeamInfo: Object
  },
  data() {
    return {
      email: "",
      password: "",
      regularSignUpStep: 1,
      valid: true,
      checkbox: false,
      disableEmailField: false,
      rules: Rules,
      confirmLoading: false,
      loading: false,
      errorMessage: this.errorMsg,
      customSSOSignInError: null,
      successMessage: "",
      userExists: false,
      termsAndConditions: true,
      idpName: null
    };
  },
  watch: {
    async userEmail() {
      this.setEmail();
      if (this.reportPageSignUp) {
        await this.checkIfUserExists();
      }
    },
    errorMsg(val) {
      if (val) {
        this.errorMessage = val;
      }
    }
  },
  async mounted() {
    EventBus.$on("signInWithEmail", this.signInWithEmail);
  },
  async created() {
    this.setEmail();
    if (this.reportPageSignUp) {
      await this.checkIfUserExists();
    }

    let paramString = window.location.href.split("?")[1];
    if (!paramString) {
      return;
    }
    paramString = paramString.replace("#/", "");
    let queryString = new URLSearchParams(paramString);
    let teamId = queryString.get("teamid");
    this.idpName = queryString.get("idp");

    if (teamId && this.idpName) {
      await this.signInWithEnterpriseSSO();
    }
  },
  beforeDestroy() {
    EventBus.$off("signInWithEmail");
  },
  computed: {
    ...mapGetters([
      "userEmail",
      "teamIdpName",
      "teamId",
      "redirectReportAssessment"
    ]),
    showSocialSignOn() {
      return (
        !this.fromLinkGenerator &&
        !this.reportPageSignUp &&
        !this.autoJoinTeamId &&
        !this.isReturningToPartialAssessment
      );
    },
    autoJoinDisabledByTeam() {
      return this.autoJoinTeam && !this.autoJoinTeamInfo.allowShareableLink;
    },
    autoJoinTeam() {
      return this.autoJoinTeamId && this.autoJoinTeamInfo;
    },
    titleText() {
      if (!this.signUp) {
        return this.$t("authentication.loginOptions.login");
      }
      if (this.reportPageSignUp) {
        return this.userExists
          ? this.$t("authentication.loginOptions.loginToSeeResults")
          : this.$t("authentication.loginOptions.addPassword");
      }

      if (this.simpleUserSignup) {
        return this.$t("authentication.loginOptions.joinTeam", {
          teamName: this.autoJoinTeam
            ? this.autoJoinTeamInfo.name
            : this.$t("authentication.loginOptions.yourTeam")
        });
      }

      return this.$t("authentication.loginOptions.createAccount");
    },
    emailRules() {
      let rulesToReturn = this.signUp
        ? [this.rules.emailRequiredSignup]
        : [this.rules.emailRequired, this.rules.emailValid];

      if (
        this.signUp &&
        this.autoJoinTeamInfo &&
        this.autoJoinTeamInfo.domainNames.length > 0
      ) {
        const domainRule = v =>
          DomainService.emailMatchesAnyDomain(
            v,
            this.autoJoinTeamInfo.domainNames
          ) || this.domainNamesAsString;
        rulesToReturn.push(domainRule);
      }
      return rulesToReturn;
    },
    passwordRules() {
      let rules = this.rules;
      let loginRules = [rules.passwordRequired];
      let signUpRules = [
        rules.passwordRequired,
        rules.passwordAtLeast8Characters,
        rules.passwordContainsNumber,
        rules.passwordContainsSpecialCharacter,
        rules.passwordContainsBothCases
      ];
      return this.signUp ? signUpRules : loginRules;
    },
    columnWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return 12;
      }
      if (this.reportPageSignUp) {
        return 11;
      }
      return this.signUp ? 8 : 10;
    },
    ssoLoginCustomisation() {
      let idpName = this.idpName ?? this.ssoIdpName;
      if (idpName == "AzureBupaMain") {
        return SSOLoginCustomisation.bupa;
      }
      if (idpName.includes("Okta")) {
        return SSOLoginCustomisation.genericOkta;
      }

      if (idpName.includes("GoogleOIDC")) {
        return SSOLoginCustomisation.genericGoogle;
      }

      return SSOLoginCustomisation.genericAzure;
    },
    showSignupButton() {
      // we want to show sign up button on final step (password) of regular sign up or on report page sign up
      return this.regularSignUpStep == 2 || this.reportPageSignUp;
    },
    regularSignUpFirstStep() {
      // return true if this is regular sign up and we are on the first step (email)
      return this.regularSignUpStep == 1 && !this.reportPageOrLoginPage;
    },
    showEmailField() {
      if (this.reportPageSignUp && this.userEmail) {
        return false;
      }
      return this.regularSignUpStep == 1 || this.reportPageOrLoginPage;
    },
    showPasswordField() {
      return this.regularSignUpStep == 2 || this.reportPageOrLoginPage;
    },
    reportPageOrLoginPage() {
      return this.reportPageSignUp || !this.signUp;
    },
    signUpButtonText() {
      return this.reportPageSignUp
        ? this.$t("authentication.loginOptions.viewReport")
        : this.$t("authentication.loginOptions.signUp");
    },
    domainNamesAsString() {
      if (
        !this.autoJoinTeamInfo ||
        this.autoJoinTeamInfo.domainNames.length == 0
      ) {
        return "";
      }

      let text = this.autoJoinTeamInfo.domainNames.join(", ");
      return this.$tc(
        "textInputRules.matchingDomainName",
        this.autoJoinTeamInfo.domainNames.length,
        { domains: text }
      );
    }
  },
  methods: {
    ...mapMutations(["setUserData", "setRedirectAssessment"]),
    keyDownEvent(event) {
      // required as landing on page fires off key down event due to autocomplete
      if (event.key) {
        this.resetError();
      }
    },
    async confirm() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      // extra check here in case email didn't exist when component created - allows for manually entered emails
      if (!this.userExists && this.reportPageSignUp) {
        await this.checkIfUserExists();
      }

      if (!this.signUp) {
        var userEmailDto = { emailAddress: { value: this.email } };
        var externalProviderInfo = await isFromExternalProvider(userEmailDto);

        if (!externalProviderInfo.canSignInWithEmail) {
          if (externalProviderInfo.teamIdpLink) {
            this.customSSOSignInError = {
              text: "authentication.useSSOLogin",
              linkText: "authentication.pageLink",
              link: externalProviderInfo.teamIdpLink
            };
            return;
          }

          this.errorMessage = this.$t("authentication.useSocialLogin");
          return;
        }
      }

      if (!this.signUp || this.userExists) {
        await this.signInWithEmail();
        return;
      }

      // if first step of regular sign up, move onto step 2.
      if (this.regularSignUpFirstStep) {
        this.regularSignUpStep = 2;
        this.$emit("setInitialSignupStep", false);
        return;
      }
      this.completeSignUp();
    },
    async signInWithEmail() {
      this.resetError();
      this.confirmLoading = true;
      try {
        let user = await Auth.signIn(this.email, this.password);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.$router.push({
            name: "InvitationSignUp",
            params: { signInEmail: this.email, signedInUser: user }
          });
        } else {
          // Ensure user has been set to confirm join this team if on report page
          if (this.reportPageSignUp || this.isReturningToPartialAssessment) {
            var endUserDto = {
              email: { value: this.email }
            };
            if (this.fromLinkGenerator) {
              await associateEndUserReportSignUpToLinkGeneratorAssessment(
                endUserDto,
                this.assessmentId
              );
            } else if (!this.userExists) {
              await confirmEndUserSignUp(endUserDto);
            }
            this.$emit("regularSignInComplete");
          } else if (this.autoJoinTeam) {
            // user has been set to confirm join a team when coming through sign up link
            try {
              await joinEndUserToTeamViaSignupLink(this.autoJoinTeamId);
            } catch (e) {
              if (
                this.autoJoinTeamInfo &&
                this.autoJoinTeamInfo.domainNames.length > 0
              ) {
                this.errorMessage = this.$t(
                  "authentication.failedToAutoJoinTeamWithDomains"
                );
              } else {
                this.errorMessage = this.$t(
                  "authentication.failedToAutoJoinTeam"
                );
              }
              return;
            }
          }
          let teamMemberInfo = await userTeamMemberInformation();
          this.setUserData(teamMemberInfo);
          if (this.reportPageSignUp) {
            await completeUserSignInWithoutRedirect(this.$store);
          } else {
            let paramString = window.location.href.split("?")[1];
            if (paramString) {
              paramString = paramString.replace("#/", "");
              let queryString = new URLSearchParams(paramString);
              let teamId = queryString.get("autojointeamid");
              let assessmentType = queryString.get("assessmenttype");
              if (assessmentType && teamId) {
                let invitation = {
                  email: { value: this.email },
                  assessmentType: assessmentType
                };
                let assessmentId = await startNewAssessment(invitation, teamId);
                this.setRedirectAssessment({
                  id: assessmentId,
                  assessmentType: assessmentType,
                  results: {}
                });
              }
            }
            await completeUserSignIn(this.$store, this.$router);
          }
        }
      } catch (err) {
        //  eslint-disable-next-line no-debugger
        if (err.code === "UserNotConfirmedException") {
          this.$emit("confirmSignUp", {
            email: this.email,
            password: this.password
          });
          return;
        }
        if (err.code === "PasswordResetRequiredException") {
          this.$router.push("ResetPassword");
          return;
        }
        if (err.code === "NotAuthorizedException") {
          this.errorMessage = this.$t(
            "authentication.incorrectUserNameOrPassword"
          );
          return;
        }

        this.errorMessage = this.$t("authentication.genericErrorMessage");
        this.$logger.captureException(err);
        this.signOut();
      } finally {
        this.confirmLoading = false;
      }
    },
    completeSignUp() {
      this.confirmLoading = true;
      this.$gtag.event("Sign up dialog - submit email and password", {
        event_category: this.reportPageSignUp
          ? "Desk Assessment - Report"
          : "Main SignUp"
      });
      let attributes = {
        email: this.email
      };
      Auth.signUp({
        username: this.email,
        password: this.password,
        attributes: attributes,
        validationData: {
          reportSignUp: this.reportPageSignUp ? "true" : "false" // used in pre sign up lambda
        }
      })
        .then(async data => {
          if (!data.userConfirmed) {
            if (this.reportPageSignUp && process.env.NODE_ENV !== "local") {
              this.$logger.captureMessage("Auto sign up failed", "error");
            }
            this.$emit("confirmSignUp", {
              email: this.email,
              password: this.password
            });
          } else {
            await this.signInWithEmail();
          }
        })
        .catch(async err => {
          if (err.code === "UsernameExistsException") {
            await this.signInWithEmail();
            return;
          }
          this.errorMessage = this.$t("authentication.genericErrorMessage");
          this.confirmLoading = false;
          this.$logger.captureException(err);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    async signInWithFederation(provider) {
      try {
        // Set origin and sign up flag for login redirect
        this.setOrigin();
        sessionStorage.setItem("federatedProvider", provider);
        if (this.signUp) {
          sessionStorage.setItem("signUp", true);
        }
        if (this.reportPageSignUp && !this.userExists) {
          sessionStorage.setItem("ssoEndUserSignedUp", true);
        }
        const cred = await Auth.federatedSignIn({ provider: provider });
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async signInWithEnterpriseSSO() {
      let teamId = sessionStorage.getItem("teamId");
      let idpName = sessionStorage.getItem("idpName");
      let assessmentType = null;
      let newAssessment = null;
      let paramString = window.location.href.split("?")[1];
      if (paramString) {
        paramString = paramString.replace("#/", "");
        let queryString = new URLSearchParams(paramString);
        teamId = queryString.get("teamid");
        idpName = queryString.get("idp");
        assessmentType = queryString.get("assessmenttype");
        newAssessment = queryString.get("new-assessment");
        if (assessmentType) {
          newAssessment = true;
        }
      }
      teamId = teamId ?? this.teamId ?? this.ssoTeamId;
      idpName = idpName ?? this.idpName ?? this.ssoIdpName;
      if (
        !this.userExists &&
        (this.reportPageSignUp || this.isReturningToPartialAssessment)
      ) {
        sessionStorage.setItem("ssoEndUserSignedUp", true);
      }

      try {
        if (this.redirectReportAssessment) {
          sessionStorage.setItem(
            "reportAssessment",
            JSON.stringify(this.redirectReportAssessment)
          );
        }
        sessionStorage.setItem("teamId", teamId);
        sessionStorage.setItem("idpName", idpName);
        if (newAssessment) {
          sessionStorage.setItem("newAssessment", newAssessment);
        }
        if (assessmentType) {
          sessionStorage.setItem("assessmentType", assessmentType);
        }
        window.location.href =
          "https://" +
          process.env.VUE_APP_USER_POOL_DOMAIN +
          "/oauth2/authorize?identity_provider=" +
          idpName +
          "&redirect_uri=" +
          process.env.VUE_APP_REDIRECT_URL +
          "&response_type=CODE&client_id=" +
          process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID +
          "&scope=aws.cognito.signin.user.admin email openid phone profile";
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    setOrigin() {
      let url = window.location.href;
      let origin = url.includes("?") ? url.split("?")[0] : url;
      sessionStorage.setItem("originURL", origin);
    },
    resetError() {
      this.errorMessage = "";
      this.successMessage = "";
      this.customSSOSignInError = null;
    },
    signOut() {
      this.setUserData(null);
      Auth.signOut();
    },
    redirect() {
      let destination = this.signUp ? "/" : "SignUp";
      this.$router.push(destination);
    },
    openedTermsAndConditions() {
      this.$gtag.event("Sign up dialog - Opened terms and conditions", {
        event_category: "VIDA - Login/Signup page"
      });
    },
    setEmail() {
      if (this.userEmail) {
        this.email = this.userEmail;
        if (
          this.isReturningToPartialAssessment ||
          (this.reportPageSignUp && !this.fromLinkGenerator)
        ) {
          this.disableEmailField = true;
        }
      }
    },
    async checkIfUserExists() {
      if (!this.email) {
        return;
      }

      try {
        this.loading = true;
        var userEmailDto = { emailAddress: { value: this.email } };
        this.userExists = await authUserExists(userEmailDto);
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
    openTermsOfService() {
      window.open("https://vitrueremote.com/terms-of-service/", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.googleBtn {
  border-width: 3px;
}

::v-deep .v-messages__message {
  hyphens: none !important;
}
</style>
