var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "elevation-0",
      attrs: { loading: _vm.loading, "min-height": _vm.loading ? "500px" : "" }
    },
    [
      !_vm.loading
        ? _c(
            "div",
            { class: _vm.reportPageSignUp ? "pa-5" : "" },
            [
              !_vm.enterpriseSSOLoginAvailable
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "text-center mb-6",
                        class: _vm.reportPageSignUp ? "text-h4" : "text-h2"
                      },
                      [_vm._v(" " + _vm._s(_vm.titleText) + " ")]
                    ),
                    _vm.signUp && _vm.autoJoinTeam
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "text-h5 vitrueDarkGrey--text text-center mb-6"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.showEmailField
                                    ? _vm.$t(
                                        "authentication.loginOptions.createAccount"
                                      )
                                    : _vm.$t(
                                        "authentication.loginOptions.createPassword"
                                      )
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.enterpriseSSOLoginAvailable
                ? _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "center",
                        "no-gutters": _vm.reportPageSignUp
                      }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.columnWidth } },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-img", {
                                staticClass: "mb-4",
                                attrs: {
                                  src: require("@/assets/images/" +
                                    _vm.ssoLoginCustomisation.image),
                                  contain: "",
                                  height: _vm.ssoLoginCustomisation.size,
                                  "max-width": _vm.ssoLoginCustomisation.size
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none elevation-0 googleBtn mt-2 py-5",
                              attrs: { width: "100%", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.signInWithEnterpriseSSO()
                                }
                              }
                            },
                            [
                              _vm.ssoLoginCustomisation.loginButtonIcon
                                ? _c("v-icon", { staticClass: "mr-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ssoLoginCustomisation
                                          .loginButtonIcon
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(_vm.ssoLoginCustomisation.text)
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "center",
                        "no-gutters": _vm.reportPageSignUp
                      }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.columnWidth } },
                        [
                          _vm.showSocialSignOn
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-none elevation-0 googleBtn py-5",
                                      attrs: {
                                        id: "googleButton",
                                        width: "100%",
                                        color: "primary",
                                        outlined: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.signInWithFederation(
                                            "Google"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "mr-4",
                                        attrs: {
                                          src: require("@/assets/images/googleLogo.png"),
                                          contain: "",
                                          height: "20px",
                                          "max-width": "20px"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "authentication.loginOptions.google"
                                            )
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-none elevation-0 googleBtn py-5 mt-3",
                                      attrs: {
                                        width: "100%",
                                        color: "disabled",
                                        outlined: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.signInWithFederation(
                                            "SignInWithApple"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-3" }, [
                                        _vm._v("mdi-apple")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "authentication.loginOptions.apple"
                                            )
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.reportPageSignUp &&
                                  !_vm.enterpriseSSOLoginAvailable
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-center text-h5 my-4",
                                          class: _vm.reportPageSignUp
                                            ? "text-h4"
                                            : "text-h2"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "authentication.loginOptions.or"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _c("v-divider", {
                                        staticClass: "mb-8 mx-3"
                                      })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    top: "",
                                    disabled: !_vm.disableEmailField
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b({}, "div", attrs, false),
                                                on
                                              ),
                                              [
                                                _vm.showEmailField
                                                  ? _c("v-text-field", {
                                                      attrs: {
                                                        id: "email",
                                                        filled: "",
                                                        label: _vm.signUp
                                                          ? _vm.$t(
                                                              "authentication.loginOptions.labels.workEmail"
                                                            )
                                                          : _vm.$t(
                                                              "authentication.loginOptions.labels.email"
                                                            ),
                                                        rules: _vm.emailRules,
                                                        type: "email",
                                                        "prepend-inner-icon":
                                                          "email",
                                                        disabled:
                                                          _vm.disableEmailField ||
                                                          _vm.autoJoinDisabledByTeam,
                                                        "data-cy": "email"
                                                      },
                                                      on: {
                                                        keydown: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.confirm(
                                                              $event
                                                            )
                                                          },
                                                          _vm.keyDownEvent
                                                        ]
                                                      },
                                                      model: {
                                                        value: _vm.email,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.email = $$v
                                                        },
                                                        expression: "email"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1577877393
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("signUpSimpleUserDialog.tooltip")
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm.showPasswordField
                                ? _c("v-text-field", {
                                    staticClass: "mb-2",
                                    attrs: {
                                      id: "password",
                                      autocomplete:
                                        _vm.reportPageSignUp || _vm.signUp
                                          ? "new-password"
                                          : "",
                                      filled: "",
                                      label: _vm.$t(
                                        "authentication.loginOptions.labels.password"
                                      ),
                                      type: "password",
                                      "prepend-inner-icon": "lock",
                                      rules: _vm.passwordRules,
                                      "hide-details": "auto",
                                      "data-cy": "password"
                                    },
                                    on: {
                                      keydown: [
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.confirm($event)
                                        },
                                        _vm.keyDownEvent
                                      ]
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  })
                                : _vm._e(),
                              !_vm.signUp
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-none text-body-1 pl-0 mb-3",
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("forgotPassword")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "authentication.loginOptions.forgotPassword"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.errorMessage
                            ? _c("alert-message", {
                                attrs: {
                                  message: _vm.errorMessage,
                                  type: "error"
                                }
                              })
                            : _vm.customSSOSignInError
                            ? _c(
                                "alert-message",
                                { attrs: { type: "error" } },
                                [
                                  _c(
                                    "i18n",
                                    {
                                      attrs: {
                                        path: _vm.customSSOSignInError.text
                                      }
                                    },
                                    [
                                      _c("template", { slot: "link" }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              target: "_self",
                                              href:
                                                _vm.customSSOSignInError.link
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.customSSOSignInError
                                                    .linkText
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.successMessage
                            ? _c("alert-message", {
                                attrs: {
                                  message: _vm.successMessage,
                                  type: "success"
                                }
                              })
                            : _vm._e(),
                          _vm.autoJoinDisabledByTeam
                            ? _c("alert-message", {
                                attrs: {
                                  message: _vm.$t(
                                    "authentication.autoJoinTeamSettingDisabled"
                                  ),
                                  type: "error"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-6 text-none",
                              attrs: {
                                id: "confirmButton",
                                color: "primary",
                                width: "100%",
                                loading: _vm.confirmLoading,
                                disabled: _vm.autoJoinDisabledByTeam
                              },
                              on: { click: _vm.confirm }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showSignupButton
                                      ? _vm.signUpButtonText
                                      : _vm.$t(
                                          "authentication.loginOptions.continueWithEmail"
                                        )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm.signUp && !_vm.userExists && !_vm.reportPageSignUp
                            ? _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: { readonly: "" },
                                        model: {
                                          value: _vm.termsAndConditions,
                                          callback: function($$v) {
                                            _vm.termsAndConditions = $$v
                                          },
                                          expression: "termsAndConditions"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "vitrueGrey--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "authentication.loginOptions.termsOfService.text"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var hover = ref.hover
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      class: hover
                                                        ? "text-decoration-underline"
                                                        : "",
                                                      style: hover
                                                        ? "cursor: pointer"
                                                        : "",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openTermsOfService()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "authentication.loginOptions.termsOfService.link"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          354599274
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var hover = ref.hover
                                    return [
                                      !_vm.reportPageSignUp
                                        ? _c(
                                            "p",
                                            { staticClass: "text-center mt-6" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "vitrueGrey--text mr-1"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.signUp
                                                          ? _vm.$t(
                                                              "authentication.loginOptions.existingAccount.signUp"
                                                            )
                                                          : _vm.$t(
                                                              "authentication.loginOptions.existingAccount.signIn"
                                                            )
                                                      )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "primary--text",
                                                  class: hover
                                                    ? "text-decoration-underline"
                                                    : "",
                                                  style: hover
                                                    ? "cursor: pointer"
                                                    : "",
                                                  on: { click: _vm.redirect }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.signUp
                                                        ? _vm.$t(
                                                            "authentication.loginOptions.signIn"
                                                          )
                                                        : _vm.$t(
                                                            "authentication.loginOptions.signUp"
                                                          )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4163528866
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }