<template>
  <v-icon v-if="icon" :class="animationClass" :size="iconSize"
    >{{ icon }}
  </v-icon>
  <v-img
    v-else
    :class="animationClass"
    :src="require('@/assets/images/' + imageSource)"
    contain
    :height="height"
    :width="width"
  />
</template>

<script>
export default {
  name: "bouncing-image",
  props: {
    height: Number,
    width: Number,
    imageSource: String,
    icon: String,
    iconSize: String,
    playAnimation: {
      type: Boolean,
      default: true
    },
    smallBounce: Boolean
  },
  computed: {
    animationClass() {
      let animationToPlay = this.smallBounce ? "bounce-small" : "bounce";
      return this.playAnimation ? animationToPlay : "";
    }
  }
};
</script>

<style lang="scss">
.bounce {
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-30px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.bounce-small {
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-name: bounce-small;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce-small {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-3px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>
