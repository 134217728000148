var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-btn",
        {
          staticStyle: { position: "absolute", right: "0" },
          attrs: { icon: "", color: "black" },
          on: { click: _vm.close }
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "pa-12",
          attrs: { "no-gutters": "", justify: "center" }
        },
        [
          _c("v-img", {
            attrs: {
              height: "200px",
              contain: "",
              src: require("@/assets/images/undraw_no_data.svg")
            }
          }),
          _c("v-card-title", { staticClass: "mt-5 mb-2" }, [
            _vm._v(
              _vm._s(
                _vm.$t("dashboardHistoryComponents.patientReminderDialog.title")
              )
            )
          ]),
          _c("v-card-subtitle", [
            !!_vm.assessment.lastUpdatedAt
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "dashboardHistoryComponents.patientReminderDialog.lastOpened",
                          {
                            "0": _vm.getFormattedDate(
                              _vm.assessment.lastUpdatedAt
                            )
                          }
                        )
                      ) +
                      " "
                  )
                ])
              : _vm.assessment.state === "In Progress"
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "dashboardHistoryComponents.patientReminderDialog.notFinished"
                        )
                      ) +
                      " "
                  )
                ])
              : _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "dashboardHistoryComponents.patientReminderDialog.notStarted"
                      )
                    )
                  )
                ]),
            _c("br"),
            _c("br"),
            !_vm.emailAvailable
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "dashboardHistoryComponents.patientReminderDialog.noEmail"
                      )
                    )
                  )
                ])
              : _vm._e()
          ]),
          _vm.emailAvailable
            ? _c("span", [
                _vm.showErrorMessage
                  ? _c("div", {
                      staticClass: "red--text mt-2 px-2",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "dashboardHistoryComponents.patientReminderDialog.errorMessage"
                          )
                        )
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.emailAvailable
            ? _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "content-class": "text-center",
                            "max-width": "300",
                            color: "transparent",
                            disabled: !_vm.disableEmails
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            class: _vm.disableEmails
                                              ? "noClick"
                                              : ""
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              width: "100%",
                                              color: "primary",
                                              rounded: "",
                                              loading: _vm.loading,
                                              disabled:
                                                _vm.loading || _vm.disableEmails
                                            },
                                            on: { click: _vm.SendReminder }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "dashboardHistoryComponents.patientReminderDialog.reminderButton"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2523130109
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.$t("tooltips.disabledByAdmin")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }