<template>
  <div
    style="background-color: #ffefd9"
    class="pa-4"
    :class="textSize"
    v-if="showBanner && teamMemberId && !hideSeatInfoWarning && !isTrialUser"
  >
    <v-btn
      v-if="showCloseButton"
      class="close-button"
      icon
      @click="showBanner = false"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row>
      <v-col cols="auto" class="font-weight-bold py-1" align-self="center">{{
        $t("totalSeatsWarning.percentageUsed", { percentage: percentageUsed })
      }}</v-col>
      <v-col cols="auto" class="py-1" align-self="center">
        {{
          tenOrMoreSeatsOver
            ? $t("totalSeatsWarning.moreThanTenOver")
            : $t("totalSeatsWarning.belowTenOver")
        }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCustomerSeatInfo, sendSeatWarningInternalEmail } from "@/customApi";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "TotalSeatsWarningBar",
  props: {
    showCloseButton: Boolean,
    sendInternalEmail: Boolean,
    value: Boolean
  },
  async created() {
    await this.getSeatInfo();
    if (this.sendInternalEmail && this.showBanner) {
      sendSeatWarningInternalEmail(this.teamMemberId);
    }
  },
  watch: {
    teamMemberId() {
      this.getSeatInfo();
    },
    value(val) {
      if (val) {
        this.getSeatInfo();
        this.$emit("input", false);
      }
    }
  },
  computed: {
    ...mapGetters([
      "teamMemberId",
      "seatInfo",
      "hideSeatInfoWarning",
      "isTrialUser"
    ]),
    percentageUsed() {
      if (!this.seatInfo) {
        return "-";
      }
      return Math.round((this.usedSeats / this.subscriptionSeats) * 100);
    },
    textSize() {
      return this.$vuetify.breakpoint.mdAndDown
        ? "text-caption"
        : "text-body-2";
    },
    subscriptionSeats() {
      if (!this.seatInfo) {
        return "-";
      }
      return this.seatInfo.subscriptionSeats;
    },
    usedSeats() {
      if (!this.seatInfo) {
        return "-";
      }
      return this.seatInfo.usedSeats;
    },
    showBanner() {
      if (!this.seatInfo) {
        return false;
      }

      return this.usedSeats / this.subscriptionSeats > 0.9;
    },
    tenOrMoreSeatsOver() {
      return this.usedSeats - this.subscriptionSeats >= 10;
    }
  },
  methods: {
    ...mapMutations(["setSeatInfo"]),
    async getSeatInfo() {
      if (!this.teamMemberId || this.hideSeatInfoWarning || this.isTrialUser) {
        return;
      }
      try {
        let seatInfo = await getCustomerSeatInfo(this.teamMemberId);
        this.setSeatInfo(seatInfo);
      } catch (err) {
        this.showBanner = false;
      }
    }
  }
};
</script>
