var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    { attrs: { type: _vm.type, text: true } },
    [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }