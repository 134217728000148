<template>
  <v-app id="app">
    <top-bar
      v-model="toggleSideBar"
      :showSideBar="showSideBar"
      class="no-print"
    />
    <side-bar v-model="toggleSideBar" v-if="showSideBar" class="no-print" />
    <v-main>
      <router-view />
    </v-main>
    <AdminChecklist
      v-if="showOnboardingChecklist && showSideBar"
      class="checkList"
    />
    <!-- footer exists only to add some bottom padding to buttons on mobile to raise them away from the bottom of the page -->
    <!-- just using mb-10 on the button container didn't work on ios mobile after bounce -->
    <v-footer style="min-height: 50px; background: transparent"></v-footer>
    <v-overlay :value="activeAsyncRequestsCount" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <cookie-law
      theme="personalisedCookieTheme"
      :buttonText="$t(`cookieBar.okButton`)"
      buttonLink="https://www.vitrueremote.com/privacy"
      :buttonLinkText="$t(`cookieBar.policy`)"
      buttonLinkNewTab
      ><div slot="message">
        {{ $t("cookieBar.message") }}
      </div></cookie-law
    >
    <v-snackbar
      v-model="showRefreshBar"
      top
      color="primary"
      timeout="-1"
      transition="fade-transition"
      content-class="text-center"
    >
      <v-btn
        class="mr-2"
        @click="setShowRefreshBar(false)"
        icon
        small
        color="white"
      >
        <v-icon>clear</v-icon>
      </v-btn>
      {{ $t("newVersion.text") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" outlined text v-bind="attrs" @click="refreshPage">
          {{ $t("newVersion.refresh") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import SideBar from "./components/layout/SideBar.vue";
import TopBar from "./components/layout/TopBar.vue";
import CookieLaw from "vue-cookie-law";
import { mapGetters, mapActions, mapMutations } from "vuex";
import AdminChecklist from "@/components/account/onboarding/AdminChecklist";
import TeamService from "@/services/teamService.js";
import FeatureService from "@/services/new-feature-service.js";
import LanguageService from "@/services/language-service.js";

export default {
  components: {
    SideBar,
    TopBar,
    CookieLaw,
    AdminChecklist
  },
  data() {
    return { toggleSideBar: null };
  },
  created() {
    this.setAppLoading(true);
    LanguageService.syncLocale();
  },
  mounted() {
    if (
      !localStorage.AppVersion ||
      (localStorage.AppVersion &&
        localStorage.AppVersion != process.env.VUE_APP_VERSION)
    ) {
      localStorage.AppVersion = process.env.VUE_APP_VERSION;
    }
  },
  watch: {
    async signedIn(newVal) {
      if (newVal) {
        await LanguageService.syncLocale();
      }
      if (newVal && !this.appLoading) {
        await this.getUserInfo();
        this.setAppLoading(false);
      }
    }
  },
  beforeDestroy() {
    this.setAppLoading(false);
  },
  methods: {
    ...mapActions([
      "setCurrentUserData",
      "getUserTeamAndSubscriptionBaseInformation"
    ]),
    ...mapMutations([
      "setTeamMember",
      "setShowRefreshBar",
      "setAppLoading",
      "setTeamMemberId"
    ]),
    refreshPage() {
      localStorage.AppVersion = process.env.VUE_APP_VERSION;
      this.setShowRefreshBar(false);
      window.location.reload();
    },
    async getUserInfo() {
      if (
        !this.userId ||
        !this.currentUserTeamAndSubscriptionBasicInformation
      ) {
        let teamMberInfo = await TeamService.getTeamMemberInfo();
        await this.setCurrentUserData(teamMberInfo);
        if (!this.isSimpleUser) {
          if (!this.teamMemberId) {
            this.setTeamMemberId(teamMberInfo.teamMemberId);
          }
          if (this.teamMemberId) {
            await this.getUserTeamAndSubscriptionBaseInformation({
              memberId: this.teamMemberId,
              forceToUpdateExistedInformation: false
            });
          }
        }
      }

      let featureIntrosNeedsLoading =
        this.featureIntros &&
        (this.featureIntros.length == 0 ||
          this.featureIntros[0].userId !== this.userId);

      if (this.userId && (!this.featureIntros || featureIntrosNeedsLoading)) {
        await FeatureService.getActiveFeatureIntros();
      }
    }
  },
  computed: {
    ...mapGetters([
      "activeAsyncRequestsCount",
      "isSimpleUser",
      "teamMemberId",
      "signedIn",
      "showRefreshBar",
      "showOnboardingChecklist",
      "featureIntros",
      "appLoading",
      "showDummySidebar",
      "userId",
      "currentUserTeamAndSubscriptionBasicInformation"
    ]),
    showSideBar() {
      return (
        this.showDummySidebar ||
        (this.signedIn && !this.$route.meta.hideSideBar)
      );
    }
  }
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}

ul.sidebar-panel-nav > li > a > i {
  padding-right: 0.5em;
}

#bottom-sidebar {
  position: fixed;
  bottom: 10px;
}

.checkList {
  position: fixed;
  z-index: 5;
  right: 0;
  bottom: 0;
}
</style>
