// eslint-disable-next-line no-unused-vars
const criteria = [
  {
    id: "allowUserIDs",
    check: function (user, allowedIds) {
      return allowedIds.indexOf(user.id) > -1;
    }
  },
  {
    id: "allowedEmails",
    check: function (user, allowedEmails) {
      return allowedEmails.indexOf(user.email) > -1;
    }
  },
  {
    id: "featureId",
    check: function (user, feature) {
      return user.features.includes(feature);
    }
  }
];

export default criteria;
