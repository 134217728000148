var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-3 pr-7 rounded-lg text-body-1 tooltiptext",
      class: _vm.position,
      style: "width:" + _vm.width + "px"
    },
    [
      _c("p", {
        staticClass: "ma-0",
        domProps: { innerHTML: _vm._s(_vm.text) }
      }),
      _c(
        "v-btn",
        {
          staticStyle: { transform: "translate(50%, -50%)" },
          attrs: {
            "x-small": "",
            icon: "",
            absolute: "",
            top: "",
            right: "",
            outlined: "",
            color: "white"
          },
          on: { click: _vm.close }
        },
        [_c("v-icon", { attrs: { color: "white" } }, [_vm._v("mdi-close")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }