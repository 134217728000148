var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-4", attrs: { color: "white" } },
    [
      _vm.$vuetify.breakpoint.xs && _vm.type === "deskSetup"
        ? _c(
            "v-toolbar",
            { attrs: { dense: "", width: "100%", color: "primary" } },
            [
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "white" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("input", false)
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-btn",
            {
              staticClass: "close-button",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("input", false)
                }
              }
            },
            [_c("v-icon", [_vm._v("clear")])],
            1
          ),
      _vm.type === "webcamAnalysis"
        ? _c("initial-desk-measurements", { attrs: { fixResults: "" } })
        : _vm._e(),
      _vm.type === "deskSetup"
        ? _c("sims", {
            model: {
              value: _vm.fakeSimsResults,
              callback: function($$v) {
                _vm.fakeSimsResults = $$v
              },
              expression: "fakeSimsResults"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }