<template>
  <v-row justify="center">
    <v-col cols="12" md="8" xl="6">
      <v-row justify="center">
        <v-col cols="12" class="pt-0"
          ><v-row justify="center" no-gutters
            ><v-col cols="auto" class="pa-0">
              <v-img
                width="75px"
                :src="require('@/assets/images/glowingTick.png')"
              ></v-img>
            </v-col>
            <v-col
              cols="auto"
              align-self="center"
              class="pa-0 mr-8 text-center"
            >
              <h1>
                {{
                  $vuetify.breakpoint.xs
                    ? $t("deskAssessment.report.doneShort")
                    : $t("deskAssessment.report.done")
                }}
              </h1>
            </v-col></v-row
          >
        </v-col>
        <v-col v-if="!viewingOwnResults" cols="12" class="pa-0 text-center">
          {{ $t("deskAssessment.report.nextStep") }}
        </v-col>

        <v-col
          cols="12"
          v-if="isDeskAssessment"
          id="painCoachTeaser"
          class="text-center text-h6 my-3"
        >
          {{ painCoachTeaserText }}
        </v-col>

        <v-col cols="12" sm="8" lg="6" :class="viewingOwnResults ? '' : 'pt-6'">
          <v-tooltip right max-width="200px" color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  id="dashboardRedirect"
                  color="secondary"
                  large
                  rounded
                  @click="goToDashboard"
                  width="100%"
                  :loading="sendingReport"
                  >{{ $t("buttons.goToDashboard") }}
                </v-btn>
              </div>
            </template>
            <vitrue-tooltip :message="$t('tooltips.signUpTooltip')" />
          </v-tooltip>
        </v-col>

        <v-col cols="12" v-if="viewingOwnResults" class="py-0">
          <v-row justify="center">
            <v-checkbox
              id="sendReportCheckbox"
              v-model="emailReportCheckbox"
              :disabled="reportSent"
              hide-details
              :label="
                reportSent
                  ? $t('deskAssessment.report.emailSentSuccessfully')
                  : $t('deskAssessment.report.sendEmail')
              "
            ></v-checkbox>
          </v-row>
        </v-col>
        <v-col v-if="isDeskAssessment" cols="12" class="py-0">
          <v-img
            :src="imageToShow"
            contain
            class="mt-6 zoom"
            @click="goToDashboard"
            style="cursor: pointer"
            max-height="300px"
          />
        </v-col>
        <v-col cols="12" v-if="showError">
          <p
            id="failedToSendError"
            class="px-4 py-0 my-0 text-body-1 warning--text text-center"
            v-html="$t('deskAssessment.report.emailFailedToSend')"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { sendReport } from "@/customApi";
import VitrueTooltip from "@/components/common/VitrueTooltip";
import PainRegions from "@/components/painareas/PainAreasMale.json";
import { mapGetters } from "vuex";
import { viewingOwnResults } from "@/services/privacy-service.js";

export default {
  name: "EndOfReportFooter",
  components: {
    VitrueTooltip
  },
  props: {
    allowReportEmail: Boolean,
    assessmentID: String,
    assessmentType: String,
    results: Object
  },
  data() {
    return {
      emailReportCheckbox: false,
      reportSent: false,
      showError: false,
      sendingReport: false
    };
  },
  methods: {
    async goToDashboard() {
      if (this.emailReportCheckbox) {
        await this.sendReport();
      }

      // Don't navigate away if there was an error sending the report since this is your only chance to send it to yourself
      if (this.showError) {
        return;
      }

      // If on wellness page, emit to close report rather than redirect
      if (this.$route.path === "/wellness") {
        this.$emit("closeReport");
        return;
      }

      if (this.signedIn) {
        this.$mixpanel.track(`Go to dashboard after ${this.assessmentType}`);
        this.$router.push("/wellness");
        return;
      }
    },
    // this method is copy and pasted from SendReportDialog.vue
    async sendReport() {
      this.sendingReport = true;
      let currentUrl = window.location.href;
      let baseUrl = currentUrl.split("#")[0];
      baseUrl += this.isDeskAssessment
        ? "#/deskassessment/"
        : "#/burnoutassessment/";

      let assessmentDto = {
        email: { value: this.userEmail ? this.userEmail : null },
        id: this.assessmentID,
        baseUrl: baseUrl,
        assessmentType: this.assessmentType
      };
      await sendReport(this._i18n.locale, assessmentDto)
        .then(() => {
          this.reportSent = true;
          this.showError = false;
          this.sendingReport = false;
        })
        .catch(err => {
          this.showError = true;
          this.sendingReport = false;
        });
    },
    getPainCoachTeaserMessage(painAreas) {
      if (painAreas.length === 0) {
        return this.$t("deskAssessment.report.painCoachTeaser", {
          0: this.$t("deskAssessment.report.you")
        });
      }

      let painAreaText = this.joinPainAreasTogether(painAreas);

      return this.$t("deskAssessment.report.painCoachTeaser", {
        0: this.$t("deskAssessment.report.painAreas", {
          0: painAreaText
        })
      });
    },
    joinPainAreasTogether(painAreas) {
      let painAreaText;
      if (painAreas.length === 1) {
        painAreaText = painAreas[0];
      } else {
        const last = painAreas.pop();
        let textArray = [
          painAreas.join(", "),
          this.$t("deskAssessment.report.and"),
          last
        ];
        painAreaText = textArray.join(" ");
      }
      return painAreaText;
    }
  },
  computed: {
    ...mapGetters(["signedIn", "userEmail"]),
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    },
    signUpDialogSize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      }

      if (this.$vuetify.breakpoint.mdAndDown) {
        return "50%";
      }

      return "25%";
    },
    fromLinkGenerator() {
      return !this.userEmail;
    },
    isDeskAssessment() {
      return this.assessmentType === "Desk assessment";
    },
    painCoachTeaserText() {
      if (!this.results) {
        return;
      }

      let painKeys = Object.keys(this.results.pain.areas);
      let painAreas = painKeys.map(x =>
        this.$t(PainRegions[x].shortenedName).toLowerCase()
      );
      return this.getPainCoachTeaserMessage(painAreas);
    },
    imageToShow() {
      return this._i18n.locale.includes("en")
        ? require("@/assets/images/wellness/painCoachTeaser.png")
        : require("@/assets/images/wellness/painCoachTeaserEs.jpg");
    }
  }
};
</script>

<style scoped>
.zoom {
  transition: all 0.2s ease-in-out;
}
.zoom:hover {
  transform: scale(1.05);
}
</style>
