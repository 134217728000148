var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            bottom: "",
            "max-width": "250px",
            disabled: !_vm.showToolTip,
            color: "transparent"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        { staticStyle: { cursor: "default" } },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      !_vm.primaryLoaded
                        ? _c(
                            "v-row",
                            {
                              staticClass: "text-h3",
                              attrs: { justify: "center" }
                            },
                            [
                              _c("v-progress-circular", {
                                staticClass: "mb-2",
                                attrs: {
                                  size: 50,
                                  indeterminate: "",
                                  color: "primary"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.primaryLoaded
                        ? _c(
                            "v-row",
                            {
                              staticClass: "text-h3 text-center",
                              attrs: { id: "primaryValue", justify: "center" }
                            },
                            [_vm._v(_vm._s(_vm.primaryValueOrDefault))]
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          staticClass: "text-h6 grey--text text-center",
                          attrs: { id: "primaryLabel", justify: "center" }
                        },
                        [_vm._v(_vm._s(_vm.primaryText))]
                      ),
                      _vm.secondaryText || _vm.secondaryValue
                        ? _c(
                            "v-row",
                            {
                              staticClass: "pt-4",
                              attrs: { justify: "center" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-subtitle-2 border text-center",
                                  class: _vm.textcolour,
                                  attrs: { id: "secondaryValueAndText" }
                                },
                                [
                                  !_vm.secondaryLoaded
                                    ? _c("v-progress-circular", {
                                        attrs: {
                                          size: 20,
                                          indeterminate: "",
                                          color: "primary"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.secondaryValueOrDefault) +
                                      " " +
                                      _vm._s(_vm.secondaryText) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("vitrue-tooltip", { attrs: { message: _vm.toolTipText } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }