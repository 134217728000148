<template>
  <v-container id="container">
    <end-of-report-sign-up
      v-if="!signedIn"
      assessmentType="Burnout assessment"
      :assessmentId="assessmentId"
      :errorMessage="signUpErrorMessage"
    />
    <div
      style="
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
      "
    >
      <canvas
        id="confetti-canvas"
        class="sticky"
        style="z-index: 5; pointer-events: none"
        :width="reportWidth"
        :height="reportHeight"
      >
      </canvas>
    </div>
    <v-btn
      v-scroll="onScroll"
      v-show="toTopVisible"
      fab
      fixed
      bottom
      right
      color="tertiary"
      @click="toPageTop"
      dark
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-dialog
      v-if="emailReportDialog"
      v-model="emailReportDialog"
      :max-width="sendReportDialogSize"
    >
      <SendReportDialog
        @inputClose="$emit('closeEmailReportDialog')"
        :assessmentID="assessmentId"
        assessmentType="Burnout assessment"
      />
    </v-dialog>

    <v-row
      justify="center"
      :class="$vuetify.breakpoint.smAndDown ? 'px-4' : ''"
    >
      <v-expansion-panels
        flat
        v-model="panelExpansion"
        multiple
        hover
        :style="$vuetify.breakpoint.mdAndUp ? 'width:90%' : ''"
        class="pa-0"
      >
        <ReportPanel
          :title="$t('burnoutReportPanelTitles.summary')"
          panelType="BurnoutSummary"
          :results="burnoutResults"
          assessmentType="burnout"
          data-cy="reportSummary"
        />
        <ReportPanel
          :title="$t('burnoutReportDetailsCards.exhaustion.title')"
          panelType="BurnoutDetails"
          :cards="burnoutRecommendations.exhaustion"
          :results="fullResults"
          assessmentType="burnout"
          category="exhaustion"
          :assessmentId="assessmentId"
        />
        <ReportPanel
          :title="$t('burnoutReportDetailsCards.cognitiveimpairment.title')"
          panelType="BurnoutDetails"
          :cards="burnoutRecommendations.cognitiveimpairment"
          :results="fullResults"
          assessmentType="burnout"
          category="cognitiveimpairment"
          :assessmentId="assessmentId"
        />
        <ReportPanel
          :title="$t('burnoutReportDetailsCards.emotionalimpairment.title')"
          panelType="BurnoutDetails"
          :cards="burnoutRecommendations.emotionalimpairment"
          :results="fullResults"
          assessmentType="burnout"
          category="emotionalimpairment"
          :assessmentId="assessmentId"
        />
        <ReportPanel
          :title="$t('burnoutReportDetailsCards.mentaldistance.title')"
          panelType="BurnoutDetails"
          :cards="burnoutRecommendations.mentaldistance"
          :results="fullResults"
          assessmentType="burnout"
          category="mentaldistance"
          :assessmentId="assessmentId"
        />
        <ReportPanel
          :title="$t('burnoutReportDetailsCards.secondarysymptoms.title')"
          panelType="BurnoutDetails"
          :cards="burnoutRecommendations.secondarysymptoms"
          :results="fullResults"
          assessmentType="burnout"
          category="secondarysymptoms"
          :assessmentId="assessmentId"
        />
        <ReportPanel
          v-if="hasFixedRecommendations"
          :title="$t('burnoutReportPanelTitles.extraContent')"
          panelType="FixedBurnoutRecommendations"
          :results="burnoutResults"
          assessmentType="burnout"
          :assessmentId="assessmentId"
          :cards="fixedRecommendationCards"
        />
        <ReportPanel
          :title="$t('burnoutReportPanelTitles.knowledgeBase')"
          panelType="BurnoutKnowledgeBase"
          :results="burnoutResults"
          assessmentType="burnout"
        />
      </v-expansion-panels>
      <v-col v-if="viewingOwnResults" cols="auto">
        <EndOfReportFooter
          :assessmentID="assessmentId"
          assessmentType="Burnout assessment"
          @closeReport="$emit('closeReport')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

import ReportPanel from "@/components/common/report/ReportPanel";
import SendReportDialog from "@/components/deskassessment/report/SendReportDialog";
import {
  getBurnoutRecommendations,
  getBurnoutFixedRecommendations
} from "@/services/recommendations/recommendations-service.js";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import BurnoutValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues";
import { mapGetters } from "vuex";
import { GetLatestDeskAssessmentResult } from "@/services/modules/api-queries.js";
import EndOfReportSignUp from "@/components/authentication/EndOfReportSignUp";
import EndOfReportFooter from "@/components/common/report/EndOfReportFooter.vue";
import { viewingOwnResults } from "@/services/privacy-service.js";

export default {
  props: {
    burnoutResults: Object,
    assessmentId: String,
    emailReportDialog: Boolean,
    signUpErrorMessage: String
  },
  components: {
    ReportPanel,
    SendReportDialog,
    EndOfReportSignUp,
    EndOfReportFooter
  },
  data() {
    return {
      panelExpansion: [0, 1, 2, 3, 4],
      toTopVisible: false,
      fullResults: this.burnoutResults,
      scores: BurnoutScores.getScores(this.burnoutResults),
      reportHeight: 0,
      reportWidth: 0,
      deskAssessmentResults: null
    };
  },
  watch: {
    results() {
      this.scores = BurnoutScores.getScores(this.burnoutResults);
    },
    signedIn() {
      this.updateLatestDeskAssessment();
    }
  },
  mounted() {
    this.panelExpansion = [0, 1, 2, 3, 4, 5, 6];
    if (this.hasFixedRecommendations) {
      this.panelExpansion.push(7);
    }

    this.updateLatestDeskAssessment();
    this.$nextTick(() => {
      this.setupConfetti();
    });
  },
  computed: {
    ...mapGetters([
      "customRecommendations",
      "redirectReportAssessment",
      "signedIn"
    ]),
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    },
    sendReportDialogSize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      }

      if (this.$vuetify.breakpoint.mdAndDown) {
        return "60%";
      }

      return "30%";
    },
    fixedRecommendationCards() {
      return getBurnoutFixedRecommendations(this.customRecommendations);
    },
    burnoutRecommendations() {
      let recommendations = getBurnoutRecommendations(
        this.scores,
        this.fullResults,
        this.customRecommendations
      );

      return recommendations;
    },
    hasFixedRecommendations() {
      return (
        this.fixedRecommendationCards &&
        this.fixedRecommendationCards.length > 0
      );
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.toTopVisible = top > 20;
    },
    toPageTop() {
      this.$vuetify.goTo(0);
    },
    setupConfetti() {
      let selfAssessment = this.signedIn && !this.burnoutResults.isCompleted;
      let justCompletedReport = selfAssessment || this.redirectReportAssessment;
      if (
        this.scores.overall < BurnoutValues.overall.average &&
        justCompletedReport
      ) {
        this.reportHeight = window.innerHeight;
        this.reportWidth = window.innerWidth;

        var particlesPerFrame = 2;
        if (this.$vuetify.breakpoint.smAndDown) {
          particlesPerFrame = 0.5;
        } else if (this.$vuetify.breakpoint.mdAndDown) {
          particlesPerFrame = 1;
        }

        this.$confetti.start({
          canvasElement: document.getElementById("confetti-canvas"),
          particlesPerFrame: particlesPerFrame,
          particles: [
            {
              type: "rect"
            }
          ]
        });

        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
      }
    },
    async updateLatestDeskAssessment() {
      if (this.signedIn) {
        let deskResults = GetLatestDeskAssessmentResult();
        this.fullResults = {
          ...this.burnoutResults,
          ...deskResults
        };
      }
    }
  }
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Required for Safari */
  position: sticky;
  top: 0px;
}
</style>
