var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showPatientReport
        ? _c(
            "v-dialog",
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.showPatientReport,
                callback: function($$v) {
                  _vm.showPatientReport = $$v
                },
                expression: "showPatientReport"
              }
            },
            [
              _c("PatientHistoryDialog", {
                attrs: {
                  assessment: _vm.selectedAssessment,
                  results: _vm.results
                },
                model: {
                  value: _vm.showPatientReport,
                  callback: function($$v) {
                    _vm.showPatientReport = $$v
                  },
                  expression: "showPatientReport"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showSendReminderDialog
        ? _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              model: {
                value: _vm.showSendReminderDialog,
                callback: function($$v) {
                  _vm.showSendReminderDialog = $$v
                },
                expression: "showSendReminderDialog"
              }
            },
            [
              _c("PatientReminderDialog", {
                attrs: { assessment: _vm.selectedAssessment },
                model: {
                  value: _vm.showSendReminderDialog,
                  callback: function($$v) {
                    _vm.showSendReminderDialog = $$v
                  },
                  expression: "showSendReminderDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showSendAllUsersAssessmentsDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.showSendAllUsersAssessmentsDialog,
                callback: function($$v) {
                  _vm.showSendAllUsersAssessmentsDialog = $$v
                },
                expression: "showSendAllUsersAssessmentsDialog"
              }
            },
            [
              _c("SendAssessmentsToAllDialog", {
                model: {
                  value: _vm.showSendAllUsersAssessmentsDialog,
                  callback: function($$v) {
                    _vm.showSendAllUsersAssessmentsDialog = $$v
                  },
                  expression: "showSendAllUsersAssessmentsDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showSendRestrictedAccessDialog
        ? _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              model: {
                value: _vm.showSendRestrictedAccessDialog,
                callback: function($$v) {
                  _vm.showSendRestrictedAccessDialog = $$v
                },
                expression: "showSendRestrictedAccessDialog"
              }
            },
            [
              _c("restricted-assessment-dialog", {
                attrs: { assessmentType: "burnout" },
                on: {
                  close: function($event) {
                    _vm.showSendRestrictedAccessDialog = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showSendBulkRemindersDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "500" },
              model: {
                value: _vm.showSendBulkRemindersDialog,
                callback: function($$v) {
                  _vm.showSendBulkRemindersDialog = $$v
                },
                expression: "showSendBulkRemindersDialog"
              }
            },
            [
              _c("PatientBulkReminderDialog", {
                attrs: { incompleteAssessments: _vm.incompleteAssessments },
                model: {
                  value: _vm.showSendBulkRemindersDialog,
                  callback: function($$v) {
                    _vm.showSendBulkRemindersDialog = $$v
                  },
                  expression: "showSendBulkRemindersDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          staticClass: "no-print",
          attrs: { justify: _vm.dashboard ? "space-between" : "end" }
        },
        [
          _vm.dashboard
            ? _c("h1", { staticClass: "text-h5" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "dashboardHistoryComponents.assessmentHistoryTable.title"
                      )
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: _vm.isSmallScreen ? 12 : 5 } },
            [
              _c(
                "v-row",
                { attrs: { justify: "end", align: "center" } },
                [
                  _c("v-text-field", {
                    staticStyle: {
                      "min-width": "15vw",
                      "padding-right": "2vw"
                    },
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: "",
                      "single-line": ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "content-class": "text-center",
                        "max-width": "300",
                        color: "transparent"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    { staticClass: "mr-3" },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("ExportToCSV", {
                                    attrs: {
                                      data: _vm.assessmentsForCSV,
                                      exportName: _vm.csvName
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("vitrue-tooltip", {
                        attrs: { message: _vm.$t("tooltips.exportTableToCSV") }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-data-table",
        {
          staticStyle: { cursor: "pointer" },
          attrs: {
            loading: _vm.dataTableLoading,
            headers: _vm.allHeaders,
            items: _vm.assessments,
            "items-per-page": 15,
            search: _vm.search,
            "custom-filter": _vm.customFilter,
            "custom-sort": _vm.customSort,
            "sort-by": ["lastUpdatedAt"],
            "sort-desc": [true],
            id: "historyTable"
          },
          on: { "click:row": _vm.showResults },
          scopedSlots: _vm._u([
            {
              key: "header.tags",
              fn: function(ref) {
                var header = ref.header
                return [
                  _c("tag-filter", {
                    attrs: {
                      items: _vm.allTagsWithNoTagOption,
                      label: header.text
                    },
                    model: {
                      value: _vm.filterTags,
                      callback: function($$v) {
                        _vm.filterTags = $$v
                      },
                      expression: "filterTags"
                    }
                  })
                ]
              }
            },
            {
              key: "header.location",
              fn: function(ref) {
                var header = ref.header
                return [
                  _c("tag-filter", {
                    attrs: {
                      items: [
                        {
                          value: "Home",
                          text: _vm.getTranslatedLocation("Home")
                        },
                        {
                          value: "Office",
                          text: _vm.getTranslatedLocation("Office")
                        },
                        {
                          value: "Other",
                          text: _vm.getTranslatedLocation("Other")
                        }
                      ],
                      label: header.text,
                      maxWidth: _vm.dashboard ? 250 : 300
                    },
                    model: {
                      value: _vm.filterLocations,
                      callback: function($$v) {
                        _vm.filterLocations = $$v
                      },
                      expression: "filterLocations"
                    }
                  })
                ]
              }
            },
            {
              key: "header.state",
              fn: function(ref) {
                var header = ref.header
                return [
                  _c("tag-filter", {
                    attrs: { items: _vm.statuses, label: header.text },
                    model: {
                      value: _vm.filterStatuses,
                      callback: function($$v) {
                        _vm.filterStatuses = $$v
                      },
                      expression: "filterStatuses"
                    }
                  })
                ]
              }
            },
            {
              key: "item.assessmentType",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        left: "",
                        color: _vm.getIconColour(item.assessmentType)
                      }
                    },
                    [_vm._v(_vm._s(_vm.getIcon(item.assessmentType)))]
                  ),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.getTranslatedAssessmentType(item.assessmentType)
                      ) + " "
                    )
                  ])
                ]
              }
            },
            {
              key: "item.result",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.score === null
                    ? _c("span", [_vm._v(" - ")])
                    : _c(
                        "v-row",
                        { attrs: { justify: "space-between" } },
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _vm._v(" " + _vm._s(item.score + "%") + " ")
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0",
                              attrs: { cols: 4, xl: "6" }
                            },
                            [
                              _c("assessment-insight-tooltip", {
                                attrs: { id: item.id }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              }
            },
            {
              key: "item.state",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.getStatusColour(item.state),
                        small: "",
                        dark: ""
                      }
                    },
                    [_vm._v(_vm._s(_vm.getTranslatedState(item.state)))]
                  )
                ]
              }
            },
            {
              key: "item.lastUpdatedAt",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getAssessmentLastEdited(item).toLocaleDateString()
                        ) +
                        " "
                    )
                  ])
                ]
              }
            },
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", { staticClass: "data-hj-suppress" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          !!item.name && !_vm.anonymousResults ? item.name : "-"
                        ) +
                        " "
                    )
                  ])
                ]
              }
            },
            {
              key: "item.email",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", { staticClass: "data-hj-suppress" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.anonymousResults ? "-" : item.email.value) +
                        " "
                    )
                  ])
                ]
              }
            },
            {
              key: "item.tags",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("table-tags-drop-down", {
                    attrs: {
                      tags: _vm.teamTags,
                      item: item,
                      editable: false,
                      manageTags: false
                    },
                    on: {
                      error: function($event) {
                        _vm.errorMessage = $event
                        _vm.showSnackbar = true
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "item.location",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.location
                    ? _c(
                        "v-chip",
                        {
                          attrs: {
                            color: _vm.getLocationColour(item.location),
                            outlined: "",
                            small: "",
                            dark: ""
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.getTranslatedLocation(item.location))
                          )
                        ]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _vm.showActionMenu(item)
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-dots-horizontal")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _vm.canSendReminder(item)
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.showResults(item)
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.actionForUserType) +
                                        " " +
                                        _vm._s(item.name)
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canDeleteAssessments
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteAssessment(item.id)
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("buttons.delete")))
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "no-data" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", value: true } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/images/undraw_no_data.svg")
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "dashboardHistoryComponents.assessmentHistoryTable.text.noData"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _vm.showSendNewAssessmentsButton
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "8", md: "6", lg: "4" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "content-class": "text-center",
                        "max-width": "300",
                        color: "transparent",
                        disabled: !_vm.disableEmails
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          disabled: _vm.disableEmails,
                                          color: "primary",
                                          width: "100%"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.showSendAllUsersAssessmentsDialog = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "dashboardHistoryComponents.assessmentHistoryTable.sendNewAssessmentsButton"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2359118127
                      )
                    },
                    [
                      _c("vitrue-tooltip", {
                        attrs: { message: _vm.$t("tooltips.disabledByAdmin") }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showSendMultipleReminders
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "8", md: "6", lg: "4" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "content-class": "text-center",
                        "max-width": "300",
                        color: "transparent",
                        disabled: !_vm.disableEmails
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          disabled: _vm.disableEmails,
                                          color: "primary",
                                          width: "100%"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.showSendBulkRemindersDialog = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "dashboardHistoryComponents.assessmentHistoryTable.sendReminderButton"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        328565779
                      )
                    },
                    [
                      _c("vitrue-tooltip", {
                        attrs: { message: _vm.$t("tooltips.disabledByAdmin") }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { "content-class": "text-center", color: "error" },
          model: {
            value: _vm.showSnackbar,
            callback: function($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }