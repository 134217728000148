const deskPlannerItems = require("@/assets/json/DeskAssessment/SimsComponents.json");
const resultsText = require("@/assets/json/DeskAssessment/ReportText.json");
const schema = require("@/assets/json/DeskAssessment/DeskAssessmentQuestions.json");
const webCamRangeValues = require("@/assets/json/DeskAssessment/WebCamValues.json");
import DeskAssessmentWindowScoring from "./desk-assessment-window-scoring";
import MeasuringSystemConverter from "@/services/converters/measuring-system-converter";
import DeskAssessmentResults from "@/services/deskassessment/desk-assessment-results-util.js";
import router from "@/router";

export default {
  screenDistanceOverviewText(results, report) {
    if (
      results.distanceFromScreen >=
      webCamRangeValues.distanceFromScreen.veryHigh
    ) {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.tooFarReport"
          )
        : router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.tooFar"
          );
    } else if (
      results.distanceFromScreen >= webCamRangeValues.distanceFromScreen.high
    ) {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.farReport"
          )
        : router.app.$t("deskAssessment.webcam.measurement.screenDistance.far");
    } else if (
      results.distanceFromScreen > webCamRangeValues.distanceFromScreen.low
    ) {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.goodReport"
          )
        : router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.good"
          );
    } else if (
      results.distanceFromScreen > webCamRangeValues.distanceFromScreen.tooLow
    ) {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.closeReport"
          )
        : router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.close"
          );
    } else {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.tooCloseReport"
          )
        : router.app.$t(
            "deskAssessment.webcam.measurement.screenDistance.tooClose"
          );
    }
  },
  screenDistancePreValueText: function () {
    return (
      router.app.$t(
        "deskAssessment.webcam.measurement.screenDistance.valuePreText"
      ) + " "
    );
  },
  screenDistanceValueText(results, metricSystem) {
    let value;
    if (
      results.distanceFromScreen >= webCamRangeValues.distanceFromScreen.high
    ) {
      value = Math.abs(
        webCamRangeValues.distanceFromScreen.high - results.distanceFromScreen
      );
    } else if (
      results.distanceFromScreen <= webCamRangeValues.distanceFromScreen.low
    ) {
      value = Math.abs(
        webCamRangeValues.distanceFromScreen.low - results.distanceFromScreen
      );
    }

    // needed for rounding error (can round down to 0cm so keep it at 1)
    value = value == 0 ? 1 : value;

    if (!metricSystem) {
      let inches = MeasuringSystemConverter.cmToInches(value);
      return inches + "in ";
    }
    return value + "cm ";
  },
  screenDistancePostValueText(results) {
    return webCamRangeValues.distanceFromScreen.perfectDistance -
      results.distanceFromScreen <
      0
      ? router.app.$t(
          "deskAssessment.webcam.measurement.screenDistance.valuePostTextFar"
        )
      : router.app.$t(
          "deskAssessment.webcam.measurement.screenDistance.valuePostTextClose"
        );
  },
  screenHeightOverviewText(results, report) {
    if (results.screenHeightAngle >= webCamRangeValues.screenHeight.bad) {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenHeight.tooLowReport"
          )
        : router.app.$t(
            "deskAssessment.webcam.measurement.screenHeight.tooLow"
          );
    } else if (
      results.screenHeightAngle >= webCamRangeValues.screenHeight.average
    ) {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenHeight.lowReport"
          )
        : router.app.$t("deskAssessment.webcam.measurement.screenHeight.low");
    } else if (
      results.screenHeightAngle > -webCamRangeValues.screenHeight.average
    ) {
      return router.app.$t(
        "deskAssessment.webcam.measurement.screenHeight.good"
      );
    } else if (
      results.screenHeightAngle > -webCamRangeValues.screenHeight.bad
    ) {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenHeight.highReport"
          )
        : router.app.$t("deskAssessment.webcam.measurement.screenHeight.high");
    } else {
      return report
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenHeight.tooHighReport"
          )
        : router.app.$t(
            "deskAssessment.webcam.measurement.screenHeight.tooHigh"
          );
    }
  },
  screenHeightPreValueText: function (results) {
    var upDownText =
      results.screenHeightAngle > 0
        ? router.app.$t(
            "deskAssessment.webcam.measurement.screenHeight.downValueText"
          )
        : router.app.$t(
            "deskAssessment.webcam.measurement.screenHeight.upValueText"
          );

    return (
      router.app.$t(
        "deskAssessment.webcam.measurement.screenHeight.valuePreText"
      ) +
      " " +
      upDownText
    );
  },
  screenHeightValueText(results) {
    return Math.abs(results.screenHeightAngle) + "°";
  },
  screenHeightPostValueText: function () {
    return router.app.$t(
      "deskAssessment.webcam.measurement.screenHeight.valuePostText"
    );
  },

  shoulderToEarDifferenceOverviewText: function () {
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderEarDistance.overviewText"
    );
  },
  shoulderToEarDifferencePreValueText(results, displayValueAlways = false) {
    if (results.shoulderToEarDifference === null) {
      return "";
    }
    if (Math.abs(results.shoulderToEarDifference) < 2 && !displayValueAlways) {
      return router.app.$t(
        "deskAssessment.webcam.measurement.shoulderEarDistance.straightHead"
      );
    }
    let side = results.rightShoulderToEarLonger
      ? router.app.$t("deskAssessment.webcam.measurement.left")
      : router.app.$t("deskAssessment.webcam.measurement.right");
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderEarDistance.valuePreText",
      { 0: side }
    );
  },
  shoulderToEarDifferenceValueText(results, displayValueAlways = false) {
    if (results.shoulderToEarDifference === null) {
      return "";
    }
    if (Math.abs(results.shoulderToEarDifference) < 2 && !displayValueAlways) {
      return "";
    }

    return results.shoulderToEarDifference + "%";
  },
  shoulderToEarDifferencePostValueText(results, displayValueAlways = false) {
    if (results.shoulderToEarDifference === null) {
      return "";
    }
    if (Math.abs(results.shoulderToEarDifference) < 2 && !displayValueAlways) {
      return "";
    }
    let side = results.rightShoulderToEarLonger
      ? router.app.$t("deskAssessment.webcam.measurement.right")
      : router.app.$t("deskAssessment.webcam.measurement.left");
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderEarDistance.valuePostText",
      { 0: side }
    );
  },
  shoulderToEarDifferenceSupplementaryText(results) {
    if (results.shoulderToEarDifference === null) {
      return "";
    }
    if (Math.abs(results.shoulderToEarDifference) < 2) {
      return (
        " " +
        router.app.$t(
          "deskAssessment.webcam.measurement.shoulderEarDistance.supplementaryGood"
        )
      );
    }
    let side = results.rightShoulderToEarLonger
      ? router.app.$t("deskAssessment.webcam.measurement.left")
      : router.app.$t("deskAssessment.webcam.measurement.right");
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderEarDistance.supplementaryInfo",
      { 0: side }
    );
  },
  shoulderDistanceOverviewText: function () {
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderHeight.overviewText"
    );
  },
  shoulderDistancePreValueText(results, displayValueAlways = false) {
    if (results.shoulderHeightDifference === 0 && !displayValueAlways) {
      return router.app.$t(
        "deskAssessment.webcam.measurement.shoulderHeight.equalDistance"
      );
    }
    let side = results.rightShoulderHigher
      ? router.app.$t("deskAssessment.webcam.measurement.left")
      : router.app.$t("deskAssessment.webcam.measurement.right");
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderHeight.valuePreText",
      { 0: side }
    );
  },
  shoulderDistanceValueText(results, displayValueAlways = false, metricSystem) {
    if (results.shoulderHeightDifference === 0 && !displayValueAlways) {
      return "";
    }
    if (!metricSystem) {
      let inches = MeasuringSystemConverter.cmToInches(
        results.shoulderHeightDifference
      );
      return inches + "in ";
    }
    return results.shoulderHeightDifference + "cm";
  },
  shoulderDistancePostValueText(results, displayValueAlways = false) {
    if (results.shoulderHeightDifference === 0 && !displayValueAlways) {
      return "";
    }
    let side = results.rightShoulderHigher
      ? router.app.$t("deskAssessment.webcam.measurement.right")
      : router.app.$t("deskAssessment.webcam.measurement.left");
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderHeight.valuePostText",
      { 0: side }
    );
  },
  shoulderDistanceSupplementaryText(results) {
    if (results.shoulderHeightDifference === 0) {
      return "";
    }
    let side = results.rightShoulderHigher
      ? router.app.$t("deskAssessment.webcam.measurement.left")
      : router.app.$t("deskAssessment.webcam.measurement.right");
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderHeight.supplementaryText",
      { 0: side }
    );
  },
  screenDistanceColour(results) {
    if (
      results.distanceFromScreen >=
        webCamRangeValues.distanceFromScreen.veryHigh ||
      results.distanceFromScreen <= webCamRangeValues.distanceFromScreen.tooLow
    ) {
      return "red";
    } else if (
      results.distanceFromScreen >= webCamRangeValues.distanceFromScreen.high ||
      results.distanceFromScreen <= webCamRangeValues.distanceFromScreen.low
    ) {
      return "amber";
    }
    return "green";
  },
  screenHeightColour(results) {
    if (
      results.screenHeightAngle >= webCamRangeValues.screenHeight.bad ||
      results.screenHeightAngle <= -webCamRangeValues.screenHeight.bad
    ) {
      return "red";
    } else if (
      results.screenHeightAngle >= webCamRangeValues.screenHeight.average ||
      results.screenHeightAngle <= -webCamRangeValues.screenHeight.average
    ) {
      return "amber";
    }
    return "green";
  },
  shoulderToEarDifferenceColour(results) {
    if (results.shoulderToEarDifference === null) {
      return "green";
    }

    if (
      results.shoulderToEarDifference >=
        webCamRangeValues.earToShoulderDifference.bad ||
      results.shoulderToEarDifference <=
        -webCamRangeValues.earToShoulderDifference.bad
    ) {
      return "red";
    } else if (
      results.shoulderToEarDifference >=
        webCamRangeValues.earToShoulderDifference.average ||
      results.shoulderToEarDifference <=
        -webCamRangeValues.earToShoulderDifference.average
    ) {
      return "amber";
    }
    return "green";
  },
  shoulderHeightColour(results) {
    if (
      results.shoulderHeightDifference >=
        webCamRangeValues.shoulderHeight.bad ||
      results.shoulderHeightDifference <= -webCamRangeValues.shoulderHeight.bad
    ) {
      return "red";
    } else if (
      results.shoulderHeightDifference >=
        webCamRangeValues.shoulderHeight.average ||
      results.shoulderHeightDifference <=
        -webCamRangeValues.shoulderHeight.average
    ) {
      return "amber";
    }
    return "green";
  },
  getVisualDeskPlannerText(deskItems, textType, canvasHeight) {
    let allItems = deskPlannerItems.map(group => group.items).flat();
    let filteredItems =
      textType === "positive"
        ? allItems.filter(item => item.positive !== undefined && item.positive)
        : allItems.filter(
            item => item.positive !== undefined && !item.positive
          );

    let text = [];
    filteredItems.forEach(item => {
      if (item.id === "window") {
        if (deskItems.find(i => i.id === "window")) {
          text.push({
            priority: item.priority,
            text: router.app.$t(
              this.getWindowText(item, deskItems, canvasHeight)
            )
          });
        }
      } else if (deskItems.find(x => x.id === item.id)) {
        text.push({ priority: item.priority, text: router.app.$t(item.text) });
      }
    });
    return text;
  },
  getVisualDeskPlannerPositiveText(results, canvasHeight) {
    let positiveText = this.getVisualDeskPlannerText(
      results.deskItems,
      "positive",
      canvasHeight
    );

    positiveText = positiveText.sort((a, b) => {
      return a.priority - b.priority;
    });

    return positiveText.slice(0, 2).map(a => a.text);
  },
  getWindowText(windowItem, deskItems, canvasHeight) {
    let windows = deskItems.filter(i => i.id.includes("window"));

    if (windows.length > 1) {
      return windowItem.text.multiple;
    }

    let windowState = DeskAssessmentWindowScoring.classifyWindows(
      deskItems,
      canvasHeight
    );

    if (windowState === "monitorAdjacentToWindow") {
      return windowItem.text.monitorAdjacentToWindow;
    } else if (windowState === "monitorFacingWindow") {
      return windowItem.text.monitorFacingWindow;
    } else if (windowState === "monitorFacingAwayFromWindow") {
      return windowItem.text.monitorFacingAwayFromWindow;
    }
    return windowItem.text.unknownPosition;
  },
  getVisualDeskPlannerNegativeText(results) {
    let negativeText = [];

    if (results.workarea) {
      let workArea = this.getWorkAreaFromValue(results.workarea);
      if (workArea === "sofa") {
        negativeText.push({
          priority: 1,
          text: router.app.$t(resultsText.sims.noDesk.sofa)
        });
      } else if (workArea === "bed") {
        negativeText.push({
          priority: 1,
          text: router.app.$t(resultsText.sims.noDesk.bed)
        });
      }
    }

    if (
      DeskAssessmentResults.isMainSeatingOption(
        results.deskItems,
        "stool",
        results.workarea
      )
    ) {
      negativeText.push({
        priority: 1,
        text: router.app.$t("deskAssessment.setup.sims.negativeText.stool")
      });
    } else if (
      DeskAssessmentResults.isMainSeatingOption(
        results.deskItems,
        "beanBag",
        results.workarea
      )
    ) {
      negativeText.push({
        priority: 1,
        text: router.app.$t("deskAssessment.setup.sims.negativeText.beanBag")
      });
    } else if (
      DeskAssessmentResults.isMainSeatingOption(
        results.deskItems,
        "gymBall",
        results.workarea
      )
    ) {
      negativeText.push({
        priority: 1,
        text: router.app.$t("deskAssessment.setup.sims.negativeText.gymBall")
      });
    }

    negativeText = negativeText.concat(
      this.getVisualDeskPlannerText(results.deskItems, "negative")
    );
    negativeText = negativeText.concat(
      this.getMissingItemsDeskPlannerNegativeText(results)
    );
    negativeText = negativeText.concat(
      this.getSpecialCaseDeskPlannerNegativeText(results)
    );

    negativeText = negativeText.sort((a, b) => {
      return a.priority - b.priority;
    });

    return negativeText.slice(0, 2).map(a => a.text);
  },
  getMissingItemsDeskPlannerNegativeText(results) {
    let negativeText = [];
    let deskItems = results.deskItems;
    let noMouse =
      !deskItems.find(x => x.id === "mouse") &&
      !deskItems.find(x => x.id === "ergonomicMouse") &&
      deskItems.filter(
        x => x.id.includes("laptop") || x.id === "computerScreen"
      ).length > 0;
    if (noMouse) {
      negativeText.push({
        priority: 5,
        text: router.app.$t("deskAssessment.setup.sims.negativeText.mouse")
      });
    }
    if (!deskItems.find(x => x.id === "water")) {
      negativeText.push({
        priority: 6,
        text: router.app.$t("deskAssessment.setup.sims.negativeText.water")
      });
    }
    if (!deskItems.find(x => x.id === "plants")) {
      negativeText.push({
        priority: 7,
        text: router.app.$t("deskAssessment.setup.sims.negativeText.plants")
      });
    }
    if (
      !deskItems.find(x => x.id === "keyboard") &&
      !deskItems.find(x => x.id === "ergonomicKeyboard")
    ) {
      negativeText.push({
        priority: 4,
        text: router.app.$t("deskAssessment.setup.sims.negativeText.keyboard")
      });
    }
    return negativeText;
  },
  getSpecialCaseDeskPlannerNegativeText(results) {
    let negativeText = [];
    let deskItems = results.deskItems;
    let multipleWaters = deskItems.filter(x => x.id === "water").length > 1;
    let poorLighting =
      deskItems.filter(x => x.id === "lamp" || x.id === "window").length ===
        0 && results.brightnesslevel < 0;

    let getLaptopStand =
      deskItems.filter(i => i.id === "laptop").length > 0 &&
      deskItems.filter(i => i.id === "laptopWithStand").length === 0 &&
      deskItems.filter(i => i.id === "computerScreen").length > 0;
    if (getLaptopStand) {
      negativeText.push({
        priority: 2,
        text: router.app.$t("simsComponents.laptop.text")
      });
    }

    if (multipleWaters) {
      negativeText.push({
        priority: 8,
        text: router.app.$t(
          "deskAssessment.setup.sims.negativeText.multipleWaters"
        )
      });
    }
    if (poorLighting) {
      negativeText.push({
        priority: 3,
        text: router.app.$t("deskAssessment.setup.sims.negativeText.lighting")
      });
    }

    return negativeText;
  },
  getValueFromWorkArea(area) {
    let question = this.getWorkAreaQuestion();
    return question.options.find(x => x.label === area).value;
  },
  getWorkAreaFromValue(value) {
    let question = this.getWorkAreaQuestion();
    return question.options.find(x => x.value === value).label;
  },
  getWorkAreaQuestion() {
    return schema
      .find(x => x.theme === "setup")
      .pages.find(x => x.name === "sims")
      .questions.find(x => x.name === "workarea");
  }
};
