var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$route.name !== "404"
        ? _c(
            "v-navigation-drawer",
            {
              staticStyle: { overflow: "visible" },
              attrs: {
                color: "white",
                "mini-variant": "",
                "mini-variant-width": "100px",
                clipped: "",
                app: "",
                touchless: "",
                permanent: _vm.$vuetify.breakpoint.mdAndUp,
                height: "100%"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          { attrs: { dense: _vm.useDenseList, flat: "" } },
                          [
                            _c(
                              "v-list-item-group",
                              {
                                attrs: {
                                  mandatory: _vm.bottomTab >= 0,
                                  color: "primary"
                                },
                                model: {
                                  value: _vm.bottomTab,
                                  callback: function($$v) {
                                    _vm.bottomTab = $$v
                                  },
                                  expression: "bottomTab"
                                }
                              },
                              _vm._l(_vm.bottomTabs, function(item) {
                                return _c(
                                  "v-list-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.show,
                                        expression: "item.show"
                                      }
                                    ],
                                    key: item.key,
                                    staticClass: "py-2 zoom",
                                    attrs: {
                                      disabled: _vm.showDummySidebar,
                                      link: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { position: "relative" } },
                                      [
                                        item.key === "UserDropDown"
                                          ? _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  top: "",
                                                  "offset-x": "",
                                                  "nudge-bottom": "1-px",
                                                  "nudge-right": "10px"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "div",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              !_vm.avatarError
                                                                ? _c("v-img", {
                                                                    staticClass:
                                                                      "avatar",
                                                                    attrs: {
                                                                      src:
                                                                        _vm.avatarURL,
                                                                      height:
                                                                        "40px",
                                                                      width:
                                                                        "40px",
                                                                      contain:
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      error: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.avatarError = true
                                                                      }
                                                                    }
                                                                  })
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-large":
                                                                          "",
                                                                        color: _vm.isAccount
                                                                          ? "primary"
                                                                          : ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-account-circle"
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  {
                                                    staticClass: "py-0",
                                                    attrs: { dense: "" }
                                                  },
                                                  _vm._l(
                                                    _vm.userDropDownList,
                                                    function(item) {
                                                      return _c(
                                                        "v-list-item",
                                                        {
                                                          key: item.key,
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.dropDownItemClicked(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "pb-1 mr-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.icon
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.title
                                                                )
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-list-item-content",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.bottomTabClicked(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  {
                                                    staticClass:
                                                      "v-list_centered_content"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { "x-large": "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.icon)
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "text-caption wrap-text",
                                                    staticStyle: {
                                                      "font-size": "12px"
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                )
                                              ],
                                              1
                                            ),
                                        item.key === "Wellness" &&
                                        _vm.showUserDashPopup
                                          ? _c("vitrue-new-feature", {
                                              staticStyle: { "z-index": "1" },
                                              attrs: {
                                                id: "viewUserDashboard",
                                                text: _vm.$t(
                                                  "tooltips.newFeatures.userDashboard"
                                                ),
                                                width: _vm.$vuetify.breakpoint
                                                  .xs
                                                  ? 250
                                                  : 360
                                              },
                                              model: {
                                                value: _vm.userDashPopup,
                                                callback: function($$v) {
                                                  _vm.userDashPopup = $$v
                                                },
                                                expression: "userDashPopup"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.showOnboardingPainCoachToolTip(
                                          item.key
                                        )
                                          ? _c("vitrue-onboarding-tooltip", {
                                              staticStyle: { "z-index": "1" },
                                              attrs: {
                                                position: "right",
                                                text: _vm.$t(
                                                  "onboarding.adminCheckList.tooltips.viewPainCoach"
                                                ),
                                                width: 250
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                4176849270
              ),
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "py-3",
                  attrs: { flat: "", dense: _vm.useDenseList }
                },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { color: "primary", mandatory: _vm.topTab >= 0 },
                      model: {
                        value: _vm.topTab,
                        callback: function($$v) {
                          _vm.topTab = $$v
                        },
                        expression: "topTab"
                      }
                    },
                    _vm._l(_vm.topTabs, function(item) {
                      return _c(
                        "v-list-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.show,
                              expression: "item.show"
                            }
                          ],
                          key: item.key,
                          staticClass: "py-2 zoom",
                          attrs: {
                            link: "",
                            disabled: _vm.showDummySidebar,
                            "data-cy": item.key
                          },
                          on: {
                            click: function($event) {
                              item.key === "Help"
                                ? _vm.openHelpPage()
                                : _vm.navigate(item)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { position: "relative" },
                              attrs: { id: item.key }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "v-list_centered_content" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            disabled: _vm.showDummySidebar,
                                            "x-large": ""
                                          }
                                        },
                                        [_vm._v(_vm._s(item.icon))]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "v-list_centered_content text-caption wrap-text",
                                      staticStyle: { "font-size": "12px" }
                                    },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                ],
                                1
                              ),
                              _c("vitrue-onboarding-tooltip", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showOnboardingTeamDataToolTip(
                                      item.key
                                    ),
                                    expression:
                                      "showOnboardingTeamDataToolTip(item.key)"
                                  }
                                ],
                                staticStyle: { "z-index": "1" },
                                attrs: {
                                  position: "right",
                                  text: _vm.$t(
                                    "onboarding.adminCheckList.tooltips.viewTeamData"
                                  ),
                                  width: 250
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }