var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("end-of-report-sign-up", {
        attrs: {
          assessmentType: "Desk assessment",
          assessmentId: _vm.assessmentId,
          errorMessage: _vm.signUpErrorMessage
        }
      }),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "0",
            height: "100%",
            width: "100%",
            "pointer-events": "none"
          }
        },
        [
          _c("canvas", {
            staticClass: "sticky",
            staticStyle: { "z-index": "5", "pointer-events": "none" },
            attrs: {
              id: "confetti-canvas",
              width: _vm.reportWidth,
              height: _vm.reportHeight
            }
          })
        ]
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.onScroll,
              expression: "onScroll"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.toTopVisible,
              expression: "toTopVisible"
            }
          ],
          attrs: {
            fab: "",
            fixed: "",
            bottom: "",
            right: "",
            color: "tertiary",
            dark: ""
          },
          on: { click: _vm.toPageTop }
        },
        [_c("i", { staticClass: "material-icons" }, [_vm._v("arrow_upward")])]
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-expansion-panels",
                {
                  staticStyle: { width: "100%" },
                  attrs: { flat: "", multiple: "", hover: "", elevation: "0" },
                  model: {
                    value: _vm.panelDefaultExpansion,
                    callback: function($$v) {
                      _vm.panelDefaultExpansion = $$v
                    },
                    expression: "panelDefaultExpansion"
                  }
                },
                [
                  _c("ReportPanel", {
                    attrs: {
                      id: "summaryPanel",
                      title: _vm.$t(
                        "deskAssessment.results.report.summaryTitle"
                      ),
                      panelType: "DeskAssessmentSummary",
                      results: _vm.results,
                      assessmentType: "deskassessment"
                    }
                  }),
                  _vm.displayPainSection
                    ? _c("ReportPanel", {
                        attrs: {
                          id: "painPanel",
                          title: _vm.$t(
                            "deskAssessment.results.report.painTitle",
                            { 0: "1" }
                          ),
                          panelType: "Pain",
                          results: _vm.results,
                          assessmentType: "deskassessment",
                          showPregnancyContent: _vm.showPregnancyContent
                        }
                      })
                    : _vm._e(),
                  _vm.displayWebcamPanel
                    ? _c(
                        "ReportPanel",
                        _vm._g(
                          {
                            attrs: {
                              id: "webcamPanel",
                              title: _vm.$t(
                                "deskAssessment.results.report.webcamTitle",
                                {
                                  0: _vm.webcamIndex
                                }
                              ),
                              panelType: "WebcamAssessment",
                              results: _vm.results,
                              score: _vm.webcamScore + "%",
                              goodScore: _vm.webcamScore >= 50,
                              assessmentType: "deskassessment"
                            }
                          },
                          _vm.$listeners
                        )
                      )
                    : _vm._e(),
                  _c("ReportPanel", {
                    attrs: {
                      id: "setupPanel",
                      title: _vm.$t(
                        "deskAssessment.results.report.workSetupTitle",
                        {
                          0: _vm.setupPanelIndex
                        }
                      ),
                      score: _vm.setupScore + "%",
                      goodScore: _vm.setupScore >= 50,
                      panelType: "WorkSetup",
                      results: _vm.results,
                      assessmentType: "deskassessment"
                    }
                  }),
                  _vm.showPregnancyContent && _vm.hasPregnancyInformationCards
                    ? _c("PregnancyContent", {
                        staticClass: "mb-2",
                        attrs: {
                          id: "pregnancyContent",
                          results: _vm.results.health
                        }
                      })
                    : _vm._e(),
                  _c("ReportPanel", {
                    attrs: {
                      id: "recommendationsPanel",
                      title: _vm.$t(
                        "deskAssessment.results.report.recommendationsTitle"
                      ),
                      panelType: "PersonalisedDeskAssessmentRecommendations",
                      results: _vm.results,
                      assessmentId: _vm.assessmentId,
                      assessmentType: "deskassessment",
                      showConstantRecommendations: false,
                      showPregnancyContent: _vm.showPregnancyContent
                    }
                  }),
                  _vm.hasFixedRecommendations
                    ? _c("ReportPanel", {
                        attrs: {
                          id: "fixedRecommendationsPanel",
                          title: _vm.$t(
                            "deskAssessment.results.report.extraContentTitle"
                          ),
                          panelType: "FixedDeskAssessmentRecommendations",
                          results: _vm.results,
                          assessmentId: _vm.assessmentId,
                          assessmentType: "deskassessment",
                          showConstantRecommendations: true
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.viewingOwnResults
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("EndOfReportFooter", {
                    attrs: {
                      id: "endOfReportFooter",
                      allowReportEmail: "",
                      assessmentID: _vm.assessmentId,
                      assessmentType: "Desk assessment",
                      results: _vm.results
                    },
                    on: {
                      closeReport: function($event) {
                        return _vm.$emit("closeReport")
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }