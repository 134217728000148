<template>
  <v-btn icon color="primary" @click="csvExport()">
    <v-icon large>
      mdi-export
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ExportToCSV",
  props: {
    data: Array,
    exportName: String
  },
  methods: {
    csvExport() {
      let link = this.createExportElement();
      link.click();
    },
    createExportElement() {
      let content = this.getCSVContent();
      const uri = encodeURI(content);
      const link = document.createElement("a");
      link.setAttribute("href", uri);
      link.setAttribute("download", this.exportName + ".csv");
      return link;
    },
    getCSVContent() {
      let header = this.getCSVHeader();
      return this.addCSVMainBodyToHeader(header);
    },
    getCSVHeader() {
      let headers = Object.keys(this.data[0]);
      return headers.map(x => {
        const result = x.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
      });
    },
    addCSVMainBodyToHeader(header) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        header.join(","),
        ...this.data.map(item => Object.values(item).join(","))
      ].join("\n");
      return csvContent;
    }
  }
};
</script>
