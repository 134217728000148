var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("onboarding-tour", {
    attrs: { pages: _vm.pages },
    on: {
      closed: function($event) {
        return _vm.$emit("input", false)
      },
      tourComplete: function($event) {
        return _vm.$emit("input", false)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }