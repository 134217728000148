var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-scale-transition",
    [
      _c(
        "v-btn",
        {
          style: _vm.buttonStyle,
          attrs: {
            "x-small": !_vm.isSmallScreen,
            depressed: "",
            ripple: false,
            color: _vm.isSmallScreen ? "red" : "transparent",
            fixed: !_vm.absolute,
            absolute: _vm.absolute,
            fab: "",
            dark: "",
            right: "",
            bottom: _vm.isSmallScreen
          },
          on: {
            click: function($event) {
              return _vm.$emit("input", true)
            }
          }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSmallScreen,
                  expression: "isSmallScreen"
                }
              ]
            },
            [_vm._v("mdi-chat")]
          ),
          _c(
            "v-chip",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isSmallScreen,
                  expression: "!isSmallScreen"
                }
              ],
              style: _vm.chipStyle,
              attrs: { label: "", color: "red", "text-color": "white" },
              on: {
                click: function($event) {
                  return _vm.$emit("input", true)
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("feedback.feedbackButton")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-chat")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }