<template>
  <v-app-bar
    app
    color="white"
    clipped-left
    :hide-on-scroll="$vuetify.breakpoint.smAndDown"
    :style="`z-index: ${endUserTourOpen ? 10001 : ''}`"
    :value="true"
  >
    <v-app-bar-nav-icon
      v-show="showSidebarBurgerMenu"
      @click.stop="$emit('input', !value)"
    />
    <v-img
      class="mr-4"
      src="@/assets/images/VitrueVidaLogoWithText.png"
      max-height="70"
      max-width="200"
      contain
    ></v-img>
    <v-spacer></v-spacer>
    <LanguageSelection v-if="showLanguageDropDown" class="my-2" />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageSelection from "@/components/common/LanguageSelection.vue";
export default {
  name: "top-bar",
  components: {
    LanguageSelection
  },
  props: {
    value: Boolean,
    showSideBar: Boolean
  },
  computed: {
    ...mapGetters(["signedIn", "endUserTourOpen", "appLoading"]),
    showSidebarBurgerMenu() {
      return this.showSideBar && this.$vuetify.breakpoint.smAndDown;
    },
    showLanguageDropDown() {
      return (
        !this.signedIn &&
        !this.$route.path.includes("deskassessment") &&
        !this.$route.path.includes("burnoutassessment")
      );
    }
  },
  watch: {
    $route() {
      this.$emit("input", this.value);
    }
  }
};
</script>
