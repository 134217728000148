var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    { staticClass: "pb-2" },
    [
      _c(
        "v-expansion-panel-header",
        {
          class: _vm.$vuetify.breakpoint.xs ? "text-subtitle-1" : "text-h6",
          attrs: { color: "vitrueMidGrey" }
        },
        [
          _c(
            "v-row",
            { staticClass: "px-3", attrs: { justify: "space-between" } },
            [
              _c("p", {
                staticClass: "text-left vitrueDarkGrey--text py-0 my-0 pr-5",
                domProps: { textContent: _vm._s(_vm.title) }
              }),
              _c("p", {
                staticClass:
                  "text-right text-h6 py-0 my-0 pr-5 font-weight-bold header-text",
                class: [_vm.goodScore === true ? "green--text" : "red--text"],
                domProps: { textContent: _vm._s(_vm.score) }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "v-expansion-panel-content",
        { attrs: { color: "pt-2" } },
        [
          _vm.panelType != undefined
            ? _c(
                _vm.panelType + "Content",
                _vm._b(
                  {
                    tag: "component",
                    attrs: {
                      assessmentId: _vm.assessmentId
                        ? _vm.assessmentId
                        : undefined,
                      category: _vm.category,
                      cards: _vm.cards,
                      showConstantRecommendations:
                        _vm.showConstantRecommendations,
                      showPregnancyContent: _vm.showPregnancyContent
                    }
                  },
                  "component",
                  _vm.currentResults,
                  false
                )
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }