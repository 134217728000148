<template>
  <div
    class="pa-3 pr-7 rounded-lg text-body-1 tooltiptext"
    :class="position"
    :style="'width:' + width + 'px'"
  >
    <p class="ma-0" v-html="text"></p>
    <v-btn
      x-small
      icon
      @click="close"
      absolute
      top
      right
      outlined
      color="white"
      style="transform: translate(50%, -50%);"
      ><v-icon color="white">mdi-close</v-icon></v-btn
    >
  </div>
</template>

<script>
import NewFeatureService from "@/services/new-feature-service.js";

export default {
  name: "VitrueNewFeature",
  props: {
    text: String,
    id: String,
    position: {
      type: String,
      default: "right"
    },
    width: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      closed: false,
      timeouts: []
    };
  },
  mounted() {
    this.timeouts.push(
      setTimeout(
        async () => await NewFeatureService.markFeatureSeen(this.id),
        3000
      )
    );
  },
  beforeDestroy() {
    this.timeouts.forEach(timeout => clearTimeout(timeout));
  },
  methods: {
    async close() {
      this.timeouts.forEach(timeout => clearTimeout(timeout));

      this.closed = true;
      await NewFeatureService.markFeatureSeen(this.id, true);
    }
  }
};
</script>

<style scoped>
.tooltiptext {
  background: var(--v-primary-base);
  color: #fff;
  border-radius: 6px;
  border: 1px solid var(--v-primary-base);
  padding: 5px 0;
  position: absolute;
  bottom: 0;
  overflow: visible;
  min-width: var(--min-width);
}

.tooltiptext::after {
  content: "";
  position: absolute;
}

.right {
  left: 120%;
}

.right::after {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--v-primary-base);
  left: 0;
  bottom: 50%;
  transform: translate(-100%, 50%);
}

.bottom {
  left: 50%;
  transform: translate(-50%, 100%);
}

.bottom::after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--v-primary-base);
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
}

.right-bottom {
  left: 120%;
}

.right-bottom::after {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--v-primary-base);
  left: 0;
  bottom: 15%;
  transform: translate(-100%, 50%);
}
</style>
