var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex justify-space-around align-center",
      on: {
        mouseleave: function($event) {
          return _vm.$emit("hover", null)
        }
      }
    },
    _vm._l(_vm.reactions, function(reaction) {
      return _c(
        "div",
        { key: reaction.value, staticClass: "mx-2" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c("vue-reaction-emoji", {
                attrs: {
                  reaction: reaction.emoji,
                  "is-active": _vm.value
                    ? _vm.value.value === reaction.value
                    : false
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", reaction)
                  }
                },
                nativeOn: {
                  mouseover: function($event) {
                    return _vm.$emit("hover", reaction)
                  }
                }
              })
            ],
            1
          ),
          _vm.showLabels
            ? _c(
                "p",
                {
                  staticClass: "mb-0 text-center",
                  class:
                    _vm.value === reaction.value
                      ? "text-body-1 font-weight-bold"
                      : "text-caption",
                  style: _vm.labelColor
                },
                [_vm._v(" " + _vm._s(_vm.$t(reaction.label)) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }