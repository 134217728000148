<template>
  <v-hover>
    <template v-slot="{ hover }">
      <VueGlow
        :intensity="intensity(hover)"
        :elevation="getElevation(hover)"
        mode="hex"
        :color="hexColour"
        style="border-radius:24px; height:100%; width:100%"
      >
        <v-card
          :color="color"
          dark
          ripple
          style="border-radius:24px;"
          height="100%"
          width="100%"
          v-on:click="$emit('input', true)"
          :disabled="disabled || loading"
        >
          <div
            class="pt-5 px-5 pb-0 fill-height"
            :class="chooseGradient"
            style="position:relative;"
          >
            <v-icon size="5vw">{{ icon }}</v-icon>

            <div
              class="top-right ma-4 mr-6"
              :style="
                $vuetify.breakpoint.smAndDown ? 'width:20px;' : 'width: 30px;'
              "
            >
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="white"
                :size="$vuetify.breakpoint.smAndDown ? '20' : '30'"
              ></v-progress-circular>
              <BouncingImage
                v-else-if="!hideIcon"
                imageSource="emojis/letter.png"
                :playAnimation="hover && !loading"
                smallBounce
              />
            </div>

            <v-card-text class="pa-0">
              <span class="text-h5">{{ title }}</span>
              <br />
              <span class="text-body-2">{{ subtitle }}</span>
            </v-card-text>
          </div>
        </v-card>
      </VueGlow>
    </template>
  </v-hover>
</template>

<script>
import VueGlow from "vue-glow";
import BouncingImage from "@/components/common/animations/BouncingImage";

export default {
  name: "dashboard-action-card",
  components: {
    VueGlow,
    BouncingImage
  },
  data() {
    return {};
  },
  props: {
    icon: String,
    color: String,
    title: String,
    subtitle: String,
    gradientDirection: String,
    disabled: Boolean,
    loading: Boolean,
    hideIcon: Boolean
  },
  computed: {
    chooseGradient() {
      return this.gradientDirection === "left"
        ? "left-gradient"
        : "right-gradient";
    },
    hexColour() {
      switch (this.color) {
        case "primary":
          return "#63a8ff";
        case "secondary":
          return "#3fb288";
        case "accent":
          return "#DA68E4";
        default:
          return "#63a8ff";
      }
    }
  },
  methods: {
    getElevation(hover) {
      if (this.disabled || this.loading) {
        return 0;
      }

      return hover ? 22 : 10;
    },
    intensity(hover) {
      if (this.disabled || this.loading) {
        return 0;
      }
      return hover ? 3 : 2;
    },
    onChildClick() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.left-gradient {
  background-image: linear-gradient(to right, #ffffff93 0%, transparent 50%);
}
.right-gradient {
  background-image: linear-gradient(to left, #ffffff93 0%, transparent 50%);
}
.top-right {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
