<template>
  <v-card>
    <v-btn
      @click="$emit('input', false)"
      class="close-button"
      icon
      color="black"
    >
      <v-icon>clear</v-icon>
    </v-btn>
    <v-card-title class="justify-center text-h4 pt-12">
      {{
        $t("dashboardHistoryComponents.sendAssessmentsToAllUsersDialog.title")
      }}
    </v-card-title>
    <v-row justify="center" no-gutters style="min-height:75%;">
      <v-col
        cols="10"
        v-if="!initialSendingFailed"
        class="text-body-1 text-center"
        align-self="center"
      >
        <p id="sendToUsersText" class="mb-0">
          {{
            $t(
              "dashboardHistoryComponents.sendAssessmentsToAllUsersDialog.text",
              { 0: totalUsers }
            )
          }}
        </p>
      </v-col>

      <v-col cols="10" v-if="initialSendingFailed">
        <p
          id="errorText"
          class="mt-2 red--text text-center"
          v-html="
            $t(
              'dashboardHistoryComponents.sendAssessmentsToAllUsersDialog.error'
            )
          "
        ></p>
        <v-list id="errorList" max-height="250px" style="overflow-y: auto">
          <v-list-item v-for="(user, index) in failedUsers" :key="user.id">
            <v-list-item-icon>
              <v-icon color="error">close</v-icon>
            </v-list-item-icon>
            <v-list-item-title :id="'failedUser' + index">{{
              user.email.value
            }}</v-list-item-title>
          </v-list-item>
        </v-list></v-col
      >
    </v-row>

    <v-row justify="center" no-gutters class="py-7">
      <v-col cols="6" class="d-flex justify-center px-2">
        <v-btn
          id="button"
          color="primary"
          @click="sendAssessments()"
          width="100%"
          max-width="200px"
          rounded
          :loading="sending"
          :disabled="disableSending"
        >
          <v-icon v-if="!loading" class="mr-2" color="white">mdi-check</v-icon>
          {{ buttonText }}
        </v-btn>
      </v-col>

      <v-col
        id="sendingProgress"
        cols="10"
        v-if="sending"
        class="text-center mt-4 text-subtitle-1 disabled--text"
      >
        {{
          $t(
            "dashboardHistoryComponents.sendAssessmentsToAllUsersDialog.sendingMessage",
            { "0": sendingCount, "1": totalUsers }
          )
        }}</v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import {
  getActiveEndUsersForCurrentTeamMember,
  sendAssessmentsToEndUser
} from "@/customApi";

export default {
  name: "SendAssessmentsToAllDialog",
  data() {
    return {
      loading: false,
      sending: false,
      initialSendingFailed: false,
      endUsers: [],
      failedUsers: [],
      sendingCount: 0
    };
  },
  computed: {
    totalUsers() {
      if (this.loading) {
        return "-";
      }
      return this.initialSendingFailed
        ? this.failedUsers.length
        : this.endUsers.length;
    },
    disableSending() {
      return this.sending || this.loading || this.totalUsers == 0;
    },
    buttonText() {
      if (this.initialSendingFailed) {
        return this.$t("buttons.resend");
      }
      return this.loading
        ? this.$t("buttons.loading")
        : this.$t("buttons.send");
    }
  },
  async mounted() {
    this.loading = true;
    this.endUsers = await getActiveEndUsersForCurrentTeamMember();
    this.loading = false;
  },
  methods: {
    async sendAssessments() {
      this.sending = true;
      this.sendingCount = 0;

      let usersToSendTo = this.initialSendingFailed
        ? [...this.failedUsers]
        : [...this.endUsers];
      let currentFailedUsers = [];

      let currentUrl = window.location.href;
      let baseUrl = (currentUrl.split("#")[0] += "#/deskassessment/");

      for (var i = 0; i < usersToSendTo.length; i++) {
        let currentUser = usersToSendTo[i];
        let invitation = {
          userId: currentUser.id,
          name: currentUser.name,
          email: currentUser.email,
          baseUrl: baseUrl,
          message: this.$t("invite.dialog.deskAssessmentMessage"),
          assessmentType: "Desk assessment"
        };
        await sendAssessmentsToEndUser(this._i18n.locale, invitation).catch(
          () => {
            currentFailedUsers.push(currentUser);
          }
        );
        this.sendingCount++;
      }

      this.sending = false;
      if (currentFailedUsers.length > 0) {
        this.initialSendingFailed = true;
        this.failedUsers = [...currentFailedUsers];
        return;
      }

      this.$emit("input", false);
    }
  }
};
</script>
