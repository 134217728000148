var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: {
        right: _vm.$vuetify.breakpoint.mdAndUp,
        left: _vm.$vuetify.breakpoint.smAndDown,
        "open-on-click": "",
        "min-width": "200"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { color: "primary" },
                      on: {
                        mouseenter: function($event) {
                          return _vm.getActions()
                        },
                        mouseleave: function($event) {
                          _vm.assessmentActions = null
                        },
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" mdi-information-outline ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        [
          !_vm.assessmentActions
            ? _c("v-progress-circular", {
                attrs: { size: "20", indeterminate: "" }
              })
            : _c("div", [
                _vm.assessmentActions.length === 0
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("insightsPage.table.issues.none")) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c(
                  "ul",
                  _vm._l(_vm.assessmentActions, function(action) {
                    return _c("li", { key: action }, [
                      _vm._v(" " + _vm._s(action) + " ")
                    ])
                  }),
                  0
                )
              ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }