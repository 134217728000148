<template>
  <v-select
    v-model="languageValue"
    :items="languages"
    prepend-inner-icon="mdi-earth"
    disable-lookup
    :dense="largeLayout ? false : true"
    hide-details
    :solo="!largeLayout"
    :flat="!largeLayout"
    :outlined="largeLayout"
    :label="$t('account.personalSettings.textFieldLabels.language')"
    item-text="text"
    item-value="id"
    :style="largeLayout ? '' : 'max-width: 250px'"
  ></v-select>
</template>

<script>
import LanguageService from "@/services/language-service.js";

export default {
  props: {
    largeLayout: Boolean
  },
  data() {
    return {
      currentLanguage: this._i18n.locale,
      languages: [
        { id: "en-gb", text: this.$t("languages.en-gb") },
        { id: "en-us", text: this.$t("languages.en-us") },
        { id: "es", text: this.$t("languages.es") },
        { id: "es-mx", text: this.$t("languages.es-mx") }
      ]
    };
  },
  watch: {
    currentLanguage(newVal) {
      LanguageService.setLocaleValue(newVal);
    }
  },
  computed: {
    languageValue: {
      set(val) {
        this.currentLanguage = val;
      },
      get() {
        return this._i18n.locale;
      }
    }
  }
};
</script>
