var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "px-3",
      staticStyle: { "overflow-x": "hidden" },
      attrs: { rounded: "" }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "fill-height",
          attrs: { align: "center", "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "text-center",
                  attrs: {
                    justify: "center",
                    align: "center",
                    "no-gutters": ""
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.columnPadding,
                      attrs: { cols: "12", id: "titleContainer" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "primary--text",
                          class: _vm.titleSize,
                          attrs: { id: "titleText" }
                        },
                        [_vm._v(_vm._s(_vm.pages[_vm.currentPage].title))]
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "mb-3",
                      class: _vm.subtextColumnClass,
                      attrs: { cols: "12", id: "subTitleContainer" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "vitrueDarkGrey--text",
                          class: _vm.subtextSize,
                          attrs: { id: "subText" }
                        },
                        [_vm._v(_vm._s(_vm.pages[_vm.currentPage].subText))]
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      class: _vm.columnPadding,
                      attrs: { id: "imageContainer", cols: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            justify: "center",
                            align: "center",
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _vm.currentPage > 0 || _vm.showBackOnFirstPage
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        id: "backArrow",
                                        icon: "",
                                        "x-small":
                                          _vm.$vuetify.breakpoint.smAndDown
                                      },
                                      on: { click: _vm.backClick }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            id: "prev",
                                            size: "40",
                                            color: "primary"
                                          }
                                        },
                                        [_vm._v(" mdi-chevron-left ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "10",
                                sm: _vm.highZoom ? 8 : 10,
                                md: _vm.highZoom ? 6 : 8,
                                lg: _vm.highZoom ? 5 : 6,
                                xl: _vm.highZoom ? 4 : 6
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { elevation: "12", rounded: "" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      elevation: "5",
                                      src: require("@/assets/images/onboardingTour/" +
                                        _vm.pages[_vm.currentPage].imageUrl),
                                      contain: "",
                                      eager: "",
                                      width: "100%"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    "x-small": _vm.$vuetify.breakpoint.smAndDown
                                  },
                                  on: { click: _vm.nextClick }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        id: "nextArrow",
                                        size: "40",
                                        color: "primary"
                                      }
                                    },
                                    [_vm._v(" mdi-chevron-right ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.columnPadding,
                      attrs: { cols: "12", md: "8" }
                    },
                    [
                      _c("SimplerStepper", {
                        attrs: {
                          totalSteps: _vm.pages.length,
                          currentStep: _vm.currentPage
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      class: _vm.columnPadding,
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              class: _vm.$vuetify.breakpoint.xs
                                ? "py-1"
                                : "pt-2 pb-0",
                              attrs: { cols: "12", sm: "5", md: "3", lg: "2" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "nextButton",
                                    rounded: "",
                                    color: "primary",
                                    small: _vm.highZoom,
                                    width: "100%"
                                  },
                                  on: { click: _vm.nextClick }
                                },
                                [_vm._v(_vm._s(_vm.nextButtonText))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-4", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "skipTourButton",
                            small: _vm.highZoom,
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("closed")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.skipTour")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
            _c("p", {
              staticClass: "text-caption font-weight-light text-center mb-2",
              domProps: {
                innerHTML: _vm._s(_vm.$t("welcomeToAssessment.termsOfService"))
              }
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }