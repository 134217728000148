<template>
  <v-card class="px-3" rounded style="overflow-x: hidden">
    <v-row align="center" class="fill-height" no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters class="text-center"
          ><v-col cols="12" id="titleContainer" :class="columnPadding"
            ><span id="titleText" class="primary--text" :class="titleSize">{{
              pages[currentPage].title
            }}</span></v-col
          >
          <v-col
            cols="12"
            id="subTitleContainer"
            :class="subtextColumnClass"
            class="mb-3"
            ><span
              id="subText"
              class="vitrueDarkGrey--text"
              :class="subtextSize"
              >{{ pages[currentPage].subText }}</span
            ></v-col
          >
          <v-col
            id="imageContainer"
            cols="12"
            class="py-0"
            :class="columnPadding"
            ><v-row justify="center" align="center" no-gutters>
              <v-col cols="1">
                <v-btn
                  id="backArrow"
                  icon
                  :x-small="$vuetify.breakpoint.smAndDown"
                  @click="backClick"
                  v-if="currentPage > 0 || showBackOnFirstPage"
                >
                  <v-icon id="prev" size="40" color="primary">
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="10"
                :sm="highZoom ? 8 : 10"
                :md="highZoom ? 6 : 8"
                :lg="highZoom ? 5 : 6"
                :xl="highZoom ? 4 : 6"
              >
                <v-card elevation="12" rounded
                  ><v-img
                    elevation="5"
                    :src="
                      require('@/assets/images/onboardingTour/' +
                        pages[currentPage].imageUrl)
                    "
                    contain
                    eager
                    width="100%" /></v-card
              ></v-col>
              <v-col cols="1">
                <v-btn
                  icon
                  :x-small="$vuetify.breakpoint.smAndDown"
                  @click="nextClick"
                >
                  <v-icon id="nextArrow" size="40" color="primary">
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row></v-col
          >
          <v-col cols="12" md="8" :class="columnPadding">
            <SimplerStepper
              :totalSteps="pages.length"
              :currentStep="currentPage"
            />
          </v-col>
          <v-col cols="12" :class="columnPadding" class="pb-0">
            <v-row justify="center">
              <v-col
                cols="12"
                sm="5"
                md="3"
                lg="2"
                :class="$vuetify.breakpoint.xs ? 'py-1' : 'pt-2 pb-0'"
              >
                <v-btn
                  id="nextButton"
                  rounded
                  color="primary"
                  :small="highZoom"
                  @click="nextClick"
                  width="100%"
                  >{{ nextButtonText }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-4">
            <v-btn
              id="skipTourButton"
              :small="highZoom"
              text
              @click="$emit('closed')"
              >{{ $t("buttons.skipTour") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0">
        <p
          class="text-caption font-weight-light text-center mb-2"
          v-html="$t('welcomeToAssessment.termsOfService')"
        ></p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SimplerStepper from "@/components/common/SimpleStepper";

export default {
  name: "OnboardingTour",
  components: {
    SimplerStepper
  },
  props: {
    pages: Array,
    showBackOnFirstPage: Boolean
  },
  data() {
    return {
      currentPage: 0
    };
  },
  mounted() {
    this.animatePage("right");
  },
  methods: {
    async nextClick() {
      if (this.onLastPage) {
        this.$emit("tourComplete");
        return;
      }
      this.currentPage++;
      this.animatePage("right");
      this.$emit("page", this.currentPage);
    },
    async backClick() {
      if (this.currentPage === 0) {
        this.$emit("backOnFirstPage");
        return;
      }
      this.currentPage--;
      this.animatePage("left");
      this.$emit("page", this.currentPage);
    },
    animatePage(direction) {
      var animation =
        direction === "right" ? "transition-right" : "transition-left";
      var title = document.getElementById("titleContainer");
      if (title) {
        this.animateComponent(title, animation);
      }
      var subTitle = document.getElementById("subTitleContainer");
      if (subTitle) {
        this.animateComponent(subTitle, animation);
      }
      var imageContainer = document.getElementById("imageContainer");
      if (imageContainer) {
        this.animateComponent(imageContainer, animation);
      }
    },
    animateComponent(node, animation) {
      node.classList.add(animation);

      node.addEventListener("animationend", function () {
        node.classList.remove(animation);
      });
    }
  },
  computed: {
    onLastPage() {
      return this.currentPage === this.pages.length - 1;
    },
    highZoom() {
      return window.devicePixelRatio > 1;
    },
    nextButtonText() {
      return this.onLastPage
        ? this.$t("buttons.getStarted")
        : this.$t("buttons.next");
    },
    titleSize() {
      return this.highZoom || this.$vuetify.breakpoint.smAndDown
        ? "text-h5"
        : "text-h4";
    },
    subtextSize() {
      return this.highZoom || this.$vuetify.breakpoint.xs
        ? "text-body-1"
        : "text-h6";
    },
    subtextColumnClass() {
      let textPosition = this.$vuetify.breakpoint.xs
        ? "text-start"
        : "text-center";
      return this.columnPadding + " " + textPosition;
    },
    columnPadding() {
      return this.highZoom || this.$vuetify.breakpoint.xs ? "pa-2" : "pa-4";
    },
    imageSize() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%";
      }
      return this.$vuetify.breakpoint.mdAndUp || this.highZoom ? "45%" : "70%";
    }
  }
};
</script>

<style scoped>
.transition-right {
  -webkit-animation: fadeInRight 1.5s;
  -moz-animation: fadeInRight 1.5s;
  -o-animation: fadeInRight 1.5;
  -ms-animation: fadeInRight 1.5s;
  animation: fadeInRight 1.5s;
}

.transition-left {
  -webkit-animation: fadeInLeft 1.5s;
  -moz-animation: fadeInLeft 1.5s;
  -o-animation: fadeInLeft 1.5;
  -ms-animation: fadeInLeft 1.5s;
  animation: fadeInLeft 1.5s;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate(20px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate(-20px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
</style>
