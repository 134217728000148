var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { rounded: "xl" } },
    [
      _c(
        "v-row",
        {
          class: _vm.image ? "dialogMargin" : "",
          staticStyle: { "`width": "100%", overflow: "hidden" },
          attrs: { "no-gutters": "", justify: "center" }
        },
        [
          _c(
            "v-img",
            {
              staticStyle: { width: "100%" },
              attrs: {
                src: require("@/assets/images/modalHeader.svg"),
                height: _vm.bannerSize
              }
            },
            [
              _c(
                "p",
                {
                  staticClass: "ma-0 white--text text-center",
                  class: _vm.titleClass
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            ]
          ),
          _vm.image
            ? _c(
                "div",
                { staticClass: "roundedDialogTopper", class: _vm.topperSize },
                [
                  _c("v-img", {
                    staticClass: "imagePosition",
                    attrs: {
                      width: "70%",
                      height: "70%",
                      src: require("@/assets/images/" + _vm.image)
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.hideCloseButton
            ? _c(
                "v-btn",
                {
                  staticClass: "close-button",
                  attrs: { icon: "", large: _vm.$vuetify.breakpoint.mdAndUp },
                  on: {
                    click: function($event) {
                      return _vm.$emit("input", false)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-close")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }