var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "fill-height",
      class: _vm.reportPageSignUp ? "" : "pt-8",
      attrs: { justify: "center", "no-gutters": "" }
    },
    [
      !_vm.loading
        ? _c(
            "v-col",
            {
              attrs: {
                cols: _vm.reportPageSignUp ? 12 : 10,
                lg: _vm.reportPageSignUp ? 12 : 5,
                md: _vm.reportPageSignUp ? 12 : 7,
                sm: _vm.reportPageSignUp ? 12 : 9
              }
            },
            [
              _c("login-options", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.confirm,
                    expression: "!confirm"
                  }
                ],
                attrs: {
                  signUp: "",
                  errorMsg: _vm.errorMessage,
                  reportPageSignUp: _vm.reportPageSignUp,
                  enterpriseSSOLoginAvailable: _vm.showEnterpriseSSOLogin,
                  ssoIdpName: _vm.teamIdpName,
                  simpleUserSignup: _vm.simpleUserSignup,
                  fromLinkGenerator: _vm.fromLinkGenerator,
                  codeConfirmSignIn: _vm.codeConfirmed,
                  assessmentId: _vm.assessmentId,
                  isReturningToPartialAssessment:
                    _vm.isReturningToPartialAssessment,
                  autoJoinTeamId: _vm.autoJoinTeamId,
                  autoJoinTeamInfo: _vm.autoJoinTeamInfo
                },
                on: {
                  setInitialSignupStep: function($event) {
                    _vm.firstSignUpStep = $event
                  },
                  confirmSignUp: function($event) {
                    return _vm.confirmSignUp($event)
                  },
                  regularSignInComplete: function($event) {
                    return _vm.$emit("regularSignInComplete")
                  }
                }
              }),
              _c("confirm-code", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.confirm,
                    expression: "confirm"
                  }
                ],
                attrs: {
                  signUp: "",
                  email: _vm.email,
                  password: _vm.password,
                  reportPageSignUp: _vm.reportPageSignUp,
                  fromLinkGenerator: _vm.fromLinkGenerator,
                  assessmentId: _vm.assessmentId
                },
                on: {
                  confirmed: function($event) {
                    _vm.confirm = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showJoinCreateTeamSwitch
        ? _c(
            "v-col",
            {
              staticClass: "text-center text-body-1",
              attrs: { cols: "12", "align-self": "end" }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.simpleUserSignup
                    ? _vm.$t("authentication.signUp.simpleUser.text")
                    : _vm.$t("authentication.signUp.regularUser.text")
                ) + " "
              ),
              _c("v-hover", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var hover = ref.hover
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "primary--text",
                              class: hover ? "text-decoration-underline" : "",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  _vm.simpleUserSignup = !_vm.simpleUserSignup
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  " " +
                                    (_vm.simpleUserSignup
                                      ? _vm.$t(
                                          "authentication.signUp.simpleUser.link"
                                        )
                                      : _vm.$t(
                                          "authentication.signUp.regularUser.link"
                                        ))
                                )
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2660208619
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }