<template>
  <div>
    <total-seats-warning-bar
      v-if="showSeatInfoWarning"
      id="dashboardSeatWarning"
      class="pa-6"
      showCloseButton
    />

    <div class="mx-10 mb-4" v-if="showAccountBanner">
      <account-banner v-model="showAccountBanner" />
    </div>
    <v-dialog v-if="webcamQuickAssess" v-model="webcamQuickAssess">
      <quick-assess type="webcamAnalysis" v-model="webcamQuickAssess" />
    </v-dialog>
    <v-dialog
      v-if="simsQuickAssess"
      v-model="simsQuickAssess"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <quick-assess type="deskSetup" v-model="simsQuickAssess" />
    </v-dialog>
    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
      persistent
    >
      <feedback-dialog v-model="feedbackDialog" />
    </v-dialog>
    <v-dialog v-if="showWelcomeDialog" v-model="welcomeDialog">
      <AdminTour v-if="welcomeDialog" v-model="welcomeDialog" />
    </v-dialog>
    <v-dialog
      v-if="inviteDialog"
      v-model="inviteDialog"
      :width="inviteDialogWidth"
      persistent
    >
      <InviteUserToDeskAssessmentDialog
        v-if="inviteDialog"
        v-model="inviteDialog"
        :teamTags="teamTags"
        :assessmentType="inviteAssessmentType"
      />
    </v-dialog>

    <v-dialog
      v-if="showPainManagementTeaser"
      v-model="showPainManagementTeaser"
      :width="$vuetify.breakpoint.smAndDown ? '90%' : '75%'"
      content-class="dialogOverflow"
    >
      <pain-management-teaser v-model="showPainManagementTeaser" />
    </v-dialog>

    <feedback-button
      v-model="feedbackDialog"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'top:50%;right: 0%'"
    />
    <v-row
      v-if="!deactivatedUser"
      :justify="
        !hideWellnessCard && !hideBurnoutCompletely ? 'space-around' : 'start'
      "
      class="mx-8 pt-4"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        :class="$vuetify.breakpoint.smAndDown ? 'pb-14' : ''"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'min-height: 200px'
            : 'min-height: 225px'
        "
      >
        <v-tooltip
          bottom
          content-class="text-center"
          max-width="300"
          :disabled="!disableDeskAssessment"
          color="transparent"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="fill-height"
              :class="disableDeskAssessment || loading ? 'noClick' : ''"
              id="invite"
            >
              <dashboard-action-card
                :title="$t('dashboardCards.deskAssessment.title')"
                :subtitle="deskAssessmentSubtitle"
                color="primary"
                icon="laptop_chromebook"
                gradientDirection="right"
                :disabled="disableDeskAssessment"
                v-model="deskAssessmentClicked"
                data-cy="deskAssessmentInvite"
                :loading="loading"
              />
            </div>
          </template>
          <vitrue-tooltip :message="$t('tooltips.disabledByAdmin')" />
        </v-tooltip>
        <div style="position: relative; top: 15px; z-index: 5">
          <vitrue-onboarding-tooltip
            v-if="showOnboardingInviteAssessmentToolTip"
            position="bottom"
            :text="$t('onboarding.adminCheckList.tooltips.inviteUser')"
            :width="250"
          />
        </div>
        <v-tooltip
          bottom
          nudge-left="150"
          nudge-top="25"
          :disabled="!disableAssessments"
          color="transparent"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                id="adminDoAssessment"
                class="mt-4 ml-4 text-h6 text-none"
                color="primary"
                min-width="200px"
                data-cy="doAssessment"
                :disabled="disableAssessments"
                @click="startNewAssessmentForSimpleUser('Desk assessment')"
              >
                {{ $t("buttons.doAssessment") }}
              </v-btn>
            </div>
          </template>
          <vitrue-tooltip :message="$t('tooltips.trialOrSubHasExpired')" />
        </v-tooltip>
        <div style="position: relative; left: 0">
          <vitrue-onboarding-tooltip
            v-if="showOnboardingSelfAssessmentToolTip"
            position="bottom"
            :text="$t('onboarding.adminCheckList.tooltips.selfAssessment')"
            :width="180"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        :class="$vuetify.breakpoint.smAndDown ? 'pb-10' : ''"
        v-if="!hideWellnessCard"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'min-height: 200px'
            : 'min-height: 225px'
        "
      >
        <dashboard-action-card
          :title="$t('dashboardCards.wellness.title')"
          :subtitle="wellnessSubtitle"
          hideIcon
          color="secondary"
          icon="spa"
          gradientDirection="left"
          v-model="showPainManagementTeaser"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        :class="$vuetify.breakpoint.smAndDown ? 'pb-10' : ''"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'min-height: 200px'
            : 'min-height: 225px'
        "
        v-if="!hideBurnoutCompletely"
      >
        <v-tooltip
          bottom
          content-class="text-center"
          max-width="300"
          :disabled="!disableBurnoutInvite"
          color="transparent"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="fill-height"
              v-bind="attrs"
              v-on="on"
              :class="disableBurnoutInvite || loading ? 'noClick' : ''"
            >
              <dashboard-action-card
                :title="$t('dashboardCards.burnout.title')"
                :subtitle="burnoutSubtitle"
                color="accent"
                icon="mdi-lightning-bolt"
                gradientDirection="right"
                :disabled="disableBurnoutInvite"
                v-model="burnoutAssessmentClicked"
                :loading="loading"
              />
            </div>
          </template>
          <vitrue-tooltip :message="burnoutTooltip" />
        </v-tooltip>
        <v-tooltip
          bottom
          nudge-left="150"
          nudge-top="25"
          :disabled="!disableAssessments && burnout"
          color="transparent"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                id="burnoutStartTest"
                color="accent"
                class="text-none text-h6 mt-4 ml-4"
                data-cy="doBurnoutAssessment"
                :disabled="disableAssessments || !burnout"
                @click="startNewAssessmentForSimpleUser('Burnout assessment')"
                min-width="200px"
              >
                {{ $t("buttons.doBurnout") }}
              </v-btn>
            </div>
          </template>
          <vitrue-tooltip :message="burnoutTooltip" />
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-if="deactivatedUser" justify="center" align="center" no-gutters>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '4'"
        ><v-img
          max-height="350px"
          contain
          :src="require('@/assets/images/dashboard_locked.svg')"
      /></v-col>

      <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '6'"
        ><p class="text-h4">{{ $t("accountBanner.hasNoActiveTeam.title") }}</p>
        <p>{{ $t("accountBanner.hasNoActiveTeam.text") }}</p></v-col
      >
    </v-row>

    <v-row
      no-gutters
      :class="deactivatedUser ? 'mt-4' : 'mt-12'"
      justify="center"
      ><analytics-table ref="analyticsTable" class="ma-0" />
    </v-row>

    <div align="center" :class="$vuetify.breakpoint.smAndDown ? 'px-8' : ''">
      <history-table
        ref="historyTable"
        v-model="teamTags"
        dashboard
        style="max-width: 95%; margin-top: 2vh"
      />
    </div>
  </div>
</template>

<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import VitrueOnboardingTooltip from "@/components/common/VitrueOnboardingTooltip.vue";
import AccountBanner from "@/components/common/AccountBanner";
import DashboardActionCard from "./DashboardActionCard";
const InviteUserToDeskAssessmentDialog = () =>
  import("./InviteDialog/InviteUserToDeskAssessmentDialog");
import HistoryTable from "@/components/common/assessment-history/AssessmentHistoryTable.vue";
import AnalyticsTable from "./analytics/AnalyticsTable";
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
import FeedbackDialog from "@/components/common/Feedback/FeedbackDialog.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { startNewAssessment, getTeamMember } from "@/customApi";
import { Auth } from "aws-amplify";
import AdminTour from "@/components/account/onboarding/AdminTour";
import _ from "lodash";
import { updateCheckListFromFeatures } from "@/services/onboarding/adminOnboarding";
import TeamService from "@/services/teamService.js";
import FeatureService from "@/services/new-feature-service.js";
import PainManagementTeaser from "./PainManagementTeaser.vue";
import QuickAssess from "@/components/dashboard/QuickAssess";
import TotalSeatsWarningBar from "@/components/dashboard/TotalSeatsWarningBar.vue";

export default {
  name: "Dashboard",
  components: {
    QuickAssess,
    AccountBanner,
    DashboardActionCard,
    HistoryTable,
    AnalyticsTable,
    InviteUserToDeskAssessmentDialog,
    FeedbackButton,
    FeedbackDialog,
    VitrueTooltip,
    AdminTour,
    VitrueOnboardingTooltip,
    PainManagementTeaser,
    TotalSeatsWarningBar
  },
  data() {
    return {
      welcomeDialog: true,
      feedbackDialog: false,
      deskAssessmentClicked: false,
      burnoutAssessmentClicked: false,
      teamTags: [],
      incompleteAssessment: {},
      loading: true,
      showPainManagementTeaser: false,
      simsQuickAssess: false,
      webcamQuickAssess: false,
      showAccountBanner: false
    };
  },
  computed: {
    ...mapGetters([
      "currentUserTeamAndSubscriptionBasicInformation",
      "isSimpleUser",
      "hideWellnessCard",
      "userEmail",
      "teamMemberId",
      "burnout",
      "disableEmails",
      "deactivatedUser",
      "onboardingChecklist",
      "disableAssessments",
      "featureIntros",
      "hideBurnoutCompletely",
      "isTrialUser",
      "subscriptionHasExpired",
      "showSeatInfoWarning"
    ]),
    inviteDialog: {
      get() {
        return this.deskAssessmentClicked || this.burnoutAssessmentClicked;
      },
      set(inviteDialogNeeded) {
        this.deskAssessmentClicked = inviteDialogNeeded;
        this.burnoutAssessmentClicked = inviteDialogNeeded;
      }
    },
    deskAssessmentSubtitle() {
      return this.$t("dashboardCards.deskAssessment.description.regularUser");
    },
    burnoutSubtitle() {
      return this.$t("dashboardCards.burnout.description.regularUser");
    },
    wellnessSubtitle() {
      return this.$t("dashboardCards.wellness.description.regularUser");
    },
    showWelcomeDialog() {
      return (
        !!this.currentUserTeamAndSubscriptionBasicInformation &&
        this.currentUserTeamAndSubscriptionBasicInformation.isNew
      );
    },
    inviteDialogWidth() {
      return this.$vuetify.breakpoint.smAndDown
        ? "90%"
        : window.devicePixelRatio === 1
        ? "40%"
        : "50%";
    },
    inviteAssessmentType() {
      if (this.deskAssessmentClicked) {
        return "Desk assessment";
      }
      if (this.burnoutAssessmentClicked) {
        return "Burnout assessment";
      }
      return "";
    },
    disableBurnoutInvite() {
      return !this.burnout || this.disableEmails;
    },
    disableDeskAssessment() {
      return this.disableEmails;
    },
    burnoutTooltip() {
      if (!this.burnout) {
        return this.$t("dashboardCards.burnout.tooltip.regularUser");
      }
      return this.disableAssessments
        ? this.$t("tooltips.trialOrSubHasExpired")
        : this.$t("tooltips.disabledByAdmin");
    },
    showOnboardingInviteAssessmentToolTip() {
      return this.onboardingChecklist.hoveredItem === "inviteUser";
    },
    showOnboardingSelfAssessmentToolTip() {
      return this.onboardingChecklist.hoveredItem === "selfAssessment";
    }
  },
  async mounted() {
    try {
      if (this.isSimpleUser) {
        this.$router.push("/wellness", {});
        return;
      }
      if (!this.teamMemberId) {
        let teamMberInfo = await TeamService.getTeamMemberInfo();
        this.setTeamMemberId(teamMberInfo.teamMemberId);
      }

      await this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });

      this.showAccountBanner = this.isTrialUser || this.subscriptionHasExpired;

      let teamMember = await getTeamMember(this.teamMemberId);

      if (teamMember.disabledAt !== null) {
        Auth.signOut().then(() => {
          this.resetLoginState();
          this.$router.push("/", () => {});
        });
      }

      let featureIntrosNeedsLoading =
        !this.featureIntros ||
        (this.featureIntros &&
          (this.featureIntros.length == 0 ||
            this.featureIntros[0].userId !== teamMberInfo.userId));

      if (featureIntrosNeedsLoading) {
        await FeatureService.getActiveFeatureIntros();
      }

      updateCheckListFromFeatures(this.featureIntros);
    } catch (err) {
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([
      "getUserTeamAndSubscriptionBaseInformation",
      "resetLoginState"
    ]),
    ...mapMutations(["setTeamMemberId"]),
    goTo(destination) {
      this.$router.push(destination, () => {});
    },
    openDeskAssessmentPreview() {
      this.$gtag.event("Desk Assessment preview button clicked", {
        event_category: "Dashboard Action"
      });
      this.goTo("/deskassessment-preview");
    },
    openBurnoutAssessmentPreview() {
      this.$gtag.event("Burnout Assessment preview button clicked", {
        event_category: "Dashboard Action"
      });
      this.goTo("/burnoutassessment-preview");
    },
    startNewAssessmentForSimpleUser(assessmentType) {
      let invitation = {
        email: { value: this.userEmail },
        assessmentType: assessmentType,
        cognitoUserId: this.userId
      };
      startNewAssessment(invitation)
        .then(assessmentID => {
          let newAssessmentUrl = "";
          if (assessmentType === "Burnout assessment") {
            newAssessmentUrl = "/burnoutassessment/" + assessmentID;
          } else if (assessmentType === "Desk assessment") {
            newAssessmentUrl = "/deskassessment/" + assessmentID;
          }

          this.$router.push(newAssessmentUrl, () => {});
        })
        .catch(err => {});
    }
  },
  watch: {
    inviteDialog: function (newVal) {
      if (newVal === true) {
        return;
      }
      if (this.$refs.historyTable != undefined) {
        this.$refs.historyTable.updateHistory();
      }
      if (this.$refs.analyticsTable != undefined) {
        this.$refs.analyticsTable.updateAnalytics();
      }
      this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });
    },
    feedbackDialog: function (newVal) {
      if (newVal === true) {
        this.$gtag.event("Open regular feedback dialog from dashboard", {
          event_category: "Feedback"
        });
      }
    }
  }
};
</script>

<style scoped>
.noClick {
  cursor: default;
}
.checkList {
  position: fixed;
  z-index: 5;
  right: 0;
  bottom: 0;
}
</style>
