var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showSeatInfoWarning
        ? _c("total-seats-warning-bar", {
            staticClass: "pa-6",
            attrs: { id: "dashboardSeatWarning", showCloseButton: "" }
          })
        : _vm._e(),
      _vm.showAccountBanner
        ? _c(
            "div",
            { staticClass: "mx-10 mb-4" },
            [
              _c("account-banner", {
                model: {
                  value: _vm.showAccountBanner,
                  callback: function($$v) {
                    _vm.showAccountBanner = $$v
                  },
                  expression: "showAccountBanner"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.webcamQuickAssess
        ? _c(
            "v-dialog",
            {
              model: {
                value: _vm.webcamQuickAssess,
                callback: function($$v) {
                  _vm.webcamQuickAssess = $$v
                },
                expression: "webcamQuickAssess"
              }
            },
            [
              _c("quick-assess", {
                attrs: { type: "webcamAnalysis" },
                model: {
                  value: _vm.webcamQuickAssess,
                  callback: function($$v) {
                    _vm.webcamQuickAssess = $$v
                  },
                  expression: "webcamQuickAssess"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.simsQuickAssess
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: _vm.$vuetify.breakpoint.xs },
              model: {
                value: _vm.simsQuickAssess,
                callback: function($$v) {
                  _vm.simsQuickAssess = $$v
                },
                expression: "simsQuickAssess"
              }
            },
            [
              _c("quick-assess", {
                attrs: { type: "deskSetup" },
                model: {
                  value: _vm.simsQuickAssess,
                  callback: function($$v) {
                    _vm.simsQuickAssess = $$v
                  },
                  expression: "simsQuickAssess"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.feedbackDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.smAndDown ? "100%" : "40%",
                persistent: ""
              },
              model: {
                value: _vm.feedbackDialog,
                callback: function($$v) {
                  _vm.feedbackDialog = $$v
                },
                expression: "feedbackDialog"
              }
            },
            [
              _c("feedback-dialog", {
                model: {
                  value: _vm.feedbackDialog,
                  callback: function($$v) {
                    _vm.feedbackDialog = $$v
                  },
                  expression: "feedbackDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showWelcomeDialog
        ? _c(
            "v-dialog",
            {
              model: {
                value: _vm.welcomeDialog,
                callback: function($$v) {
                  _vm.welcomeDialog = $$v
                },
                expression: "welcomeDialog"
              }
            },
            [
              _vm.welcomeDialog
                ? _c("AdminTour", {
                    model: {
                      value: _vm.welcomeDialog,
                      callback: function($$v) {
                        _vm.welcomeDialog = $$v
                      },
                      expression: "welcomeDialog"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.inviteDialog
        ? _c(
            "v-dialog",
            {
              attrs: { width: _vm.inviteDialogWidth, persistent: "" },
              model: {
                value: _vm.inviteDialog,
                callback: function($$v) {
                  _vm.inviteDialog = $$v
                },
                expression: "inviteDialog"
              }
            },
            [
              _vm.inviteDialog
                ? _c("InviteUserToDeskAssessmentDialog", {
                    attrs: {
                      teamTags: _vm.teamTags,
                      assessmentType: _vm.inviteAssessmentType
                    },
                    model: {
                      value: _vm.inviteDialog,
                      callback: function($$v) {
                        _vm.inviteDialog = $$v
                      },
                      expression: "inviteDialog"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.showPainManagementTeaser
        ? _c(
            "v-dialog",
            {
              attrs: {
                width: _vm.$vuetify.breakpoint.smAndDown ? "90%" : "75%",
                "content-class": "dialogOverflow"
              },
              model: {
                value: _vm.showPainManagementTeaser,
                callback: function($$v) {
                  _vm.showPainManagementTeaser = $$v
                },
                expression: "showPainManagementTeaser"
              }
            },
            [
              _c("pain-management-teaser", {
                model: {
                  value: _vm.showPainManagementTeaser,
                  callback: function($$v) {
                    _vm.showPainManagementTeaser = $$v
                  },
                  expression: "showPainManagementTeaser"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("feedback-button", {
        style: _vm.$vuetify.breakpoint.smAndDown ? "" : "top:50%;right: 0%",
        model: {
          value: _vm.feedbackDialog,
          callback: function($$v) {
            _vm.feedbackDialog = $$v
          },
          expression: "feedbackDialog"
        }
      }),
      !_vm.deactivatedUser
        ? _c(
            "v-row",
            {
              staticClass: "mx-8 pt-4",
              attrs: {
                justify:
                  !_vm.hideWellnessCard && !_vm.hideBurnoutCompletely
                    ? "space-around"
                    : "start"
              }
            },
            [
              _c(
                "v-col",
                {
                  class: _vm.$vuetify.breakpoint.smAndDown ? "pb-14" : "",
                  style: _vm.$vuetify.breakpoint.smAndDown
                    ? "min-height: 200px"
                    : "min-height: 225px",
                  attrs: { cols: "12", sm: "12", md: "4" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "content-class": "text-center",
                        "max-width": "300",
                        disabled: !_vm.disableDeskAssessment,
                        color: "transparent"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "fill-height",
                                        class:
                                          _vm.disableDeskAssessment ||
                                          _vm.loading
                                            ? "noClick"
                                            : "",
                                        attrs: { id: "invite" }
                                      },
                                      "div",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("dashboard-action-card", {
                                      attrs: {
                                        title: _vm.$t(
                                          "dashboardCards.deskAssessment.title"
                                        ),
                                        subtitle: _vm.deskAssessmentSubtitle,
                                        color: "primary",
                                        icon: "laptop_chromebook",
                                        gradientDirection: "right",
                                        disabled: _vm.disableDeskAssessment,
                                        "data-cy": "deskAssessmentInvite",
                                        loading: _vm.loading
                                      },
                                      model: {
                                        value: _vm.deskAssessmentClicked,
                                        callback: function($$v) {
                                          _vm.deskAssessmentClicked = $$v
                                        },
                                        expression: "deskAssessmentClicked"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1970669728
                      )
                    },
                    [
                      _c("vitrue-tooltip", {
                        attrs: { message: _vm.$t("tooltips.disabledByAdmin") }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        top: "15px",
                        "z-index": "5"
                      }
                    },
                    [
                      _vm.showOnboardingInviteAssessmentToolTip
                        ? _c("vitrue-onboarding-tooltip", {
                            attrs: {
                              position: "bottom",
                              text: _vm.$t(
                                "onboarding.adminCheckList.tooltips.inviteUser"
                              ),
                              width: 250
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "nudge-left": "150",
                        "nudge-top": "25",
                        disabled: !_vm.disableAssessments,
                        color: "transparent"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "mt-4 ml-4 text-h6 text-none",
                                        attrs: {
                                          id: "adminDoAssessment",
                                          color: "primary",
                                          "min-width": "200px",
                                          "data-cy": "doAssessment",
                                          disabled: _vm.disableAssessments
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.startNewAssessmentForSimpleUser(
                                              "Desk assessment"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("buttons.doAssessment")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4226337968
                      )
                    },
                    [
                      _c("vitrue-tooltip", {
                        attrs: {
                          message: _vm.$t("tooltips.trialOrSubHasExpired")
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { position: "relative", left: "0" } },
                    [
                      _vm.showOnboardingSelfAssessmentToolTip
                        ? _c("vitrue-onboarding-tooltip", {
                            attrs: {
                              position: "bottom",
                              text: _vm.$t(
                                "onboarding.adminCheckList.tooltips.selfAssessment"
                              ),
                              width: 180
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.hideWellnessCard
                ? _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "pb-10" : "",
                      style: _vm.$vuetify.breakpoint.smAndDown
                        ? "min-height: 200px"
                        : "min-height: 225px",
                      attrs: { cols: "12", sm: "12", md: "4" }
                    },
                    [
                      _c("dashboard-action-card", {
                        attrs: {
                          title: _vm.$t("dashboardCards.wellness.title"),
                          subtitle: _vm.wellnessSubtitle,
                          hideIcon: "",
                          color: "secondary",
                          icon: "spa",
                          gradientDirection: "left"
                        },
                        model: {
                          value: _vm.showPainManagementTeaser,
                          callback: function($$v) {
                            _vm.showPainManagementTeaser = $$v
                          },
                          expression: "showPainManagementTeaser"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.hideBurnoutCompletely
                ? _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "pb-10" : "",
                      style: _vm.$vuetify.breakpoint.smAndDown
                        ? "min-height: 200px"
                        : "min-height: 225px",
                      attrs: { cols: "12", sm: "12", md: "4" }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: "",
                            "content-class": "text-center",
                            "max-width": "300",
                            disabled: !_vm.disableBurnoutInvite,
                            color: "transparent"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "fill-height",
                                            class:
                                              _vm.disableBurnoutInvite ||
                                              _vm.loading
                                                ? "noClick"
                                                : ""
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("dashboard-action-card", {
                                          attrs: {
                                            title: _vm.$t(
                                              "dashboardCards.burnout.title"
                                            ),
                                            subtitle: _vm.burnoutSubtitle,
                                            color: "accent",
                                            icon: "mdi-lightning-bolt",
                                            gradientDirection: "right",
                                            disabled: _vm.disableBurnoutInvite,
                                            loading: _vm.loading
                                          },
                                          model: {
                                            value: _vm.burnoutAssessmentClicked,
                                            callback: function($$v) {
                                              _vm.burnoutAssessmentClicked = $$v
                                            },
                                            expression:
                                              "burnoutAssessmentClicked"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            904883244
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: { message: _vm.burnoutTooltip }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: "",
                            "nudge-left": "150",
                            "nudge-top": "25",
                            disabled: !_vm.disableAssessments && _vm.burnout,
                            color: "transparent"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "text-none text-h6 mt-4 ml-4",
                                            attrs: {
                                              id: "burnoutStartTest",
                                              color: "accent",
                                              "data-cy": "doBurnoutAssessment",
                                              disabled:
                                                _vm.disableAssessments ||
                                                !_vm.burnout,
                                              "min-width": "200px"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.startNewAssessmentForSimpleUser(
                                                  "Burnout assessment"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("buttons.doBurnout")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            447646285
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: { message: _vm.burnoutTooltip }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.deactivatedUser
        ? _c(
            "v-row",
            { attrs: { justify: "center", align: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? "8" : "4" }
                },
                [
                  _c("v-img", {
                    attrs: {
                      "max-height": "350px",
                      contain: "",
                      src: require("@/assets/images/dashboard_locked.svg")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? "8" : "6" }
                },
                [
                  _c("p", { staticClass: "text-h4" }, [
                    _vm._v(
                      _vm._s(_vm.$t("accountBanner.hasNoActiveTeam.title"))
                    )
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("accountBanner.hasNoActiveTeam.text")))
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          class: _vm.deactivatedUser ? "mt-4" : "mt-12",
          attrs: { "no-gutters": "", justify: "center" }
        },
        [_c("analytics-table", { ref: "analyticsTable", staticClass: "ma-0" })],
        1
      ),
      _c(
        "div",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "px-8" : "",
          attrs: { align: "center" }
        },
        [
          _c("history-table", {
            ref: "historyTable",
            staticStyle: { "max-width": "95%", "margin-top": "2vh" },
            attrs: { dashboard: "" },
            model: {
              value: _vm.teamTags,
              callback: function($$v) {
                _vm.teamTags = $$v
              },
              expression: "teamTags"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }