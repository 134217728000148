var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "container" } },
    [
      !_vm.signedIn
        ? _c("end-of-report-sign-up", {
            attrs: {
              assessmentType: "Burnout assessment",
              assessmentId: _vm.assessmentId,
              errorMessage: _vm.signUpErrorMessage
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "0",
            height: "100%",
            width: "100%",
            "pointer-events": "none"
          }
        },
        [
          _c("canvas", {
            staticClass: "sticky",
            staticStyle: { "z-index": "5", "pointer-events": "none" },
            attrs: {
              id: "confetti-canvas",
              width: _vm.reportWidth,
              height: _vm.reportHeight
            }
          })
        ]
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.onScroll,
              expression: "onScroll"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.toTopVisible,
              expression: "toTopVisible"
            }
          ],
          attrs: {
            fab: "",
            fixed: "",
            bottom: "",
            right: "",
            color: "tertiary",
            dark: ""
          },
          on: { click: _vm.toPageTop }
        },
        [_c("v-icon", [_vm._v("mdi-arrow-up")])],
        1
      ),
      _vm.emailReportDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": _vm.sendReportDialogSize },
              model: {
                value: _vm.emailReportDialog,
                callback: function($$v) {
                  _vm.emailReportDialog = $$v
                },
                expression: "emailReportDialog"
              }
            },
            [
              _c("SendReportDialog", {
                attrs: {
                  assessmentID: _vm.assessmentId,
                  assessmentType: "Burnout assessment"
                },
                on: {
                  inputClose: function($event) {
                    return _vm.$emit("closeEmailReportDialog")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "px-4" : "",
          attrs: { justify: "center" }
        },
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "pa-0",
              style: _vm.$vuetify.breakpoint.mdAndUp ? "width:90%" : "",
              attrs: { flat: "", multiple: "", hover: "" },
              model: {
                value: _vm.panelExpansion,
                callback: function($$v) {
                  _vm.panelExpansion = $$v
                },
                expression: "panelExpansion"
              }
            },
            [
              _c("ReportPanel", {
                attrs: {
                  title: _vm.$t("burnoutReportPanelTitles.summary"),
                  panelType: "BurnoutSummary",
                  results: _vm.burnoutResults,
                  assessmentType: "burnout",
                  "data-cy": "reportSummary"
                }
              }),
              _c("ReportPanel", {
                attrs: {
                  title: _vm.$t("burnoutReportDetailsCards.exhaustion.title"),
                  panelType: "BurnoutDetails",
                  cards: _vm.burnoutRecommendations.exhaustion,
                  results: _vm.fullResults,
                  assessmentType: "burnout",
                  category: "exhaustion",
                  assessmentId: _vm.assessmentId
                }
              }),
              _c("ReportPanel", {
                attrs: {
                  title: _vm.$t(
                    "burnoutReportDetailsCards.cognitiveimpairment.title"
                  ),
                  panelType: "BurnoutDetails",
                  cards: _vm.burnoutRecommendations.cognitiveimpairment,
                  results: _vm.fullResults,
                  assessmentType: "burnout",
                  category: "cognitiveimpairment",
                  assessmentId: _vm.assessmentId
                }
              }),
              _c("ReportPanel", {
                attrs: {
                  title: _vm.$t(
                    "burnoutReportDetailsCards.emotionalimpairment.title"
                  ),
                  panelType: "BurnoutDetails",
                  cards: _vm.burnoutRecommendations.emotionalimpairment,
                  results: _vm.fullResults,
                  assessmentType: "burnout",
                  category: "emotionalimpairment",
                  assessmentId: _vm.assessmentId
                }
              }),
              _c("ReportPanel", {
                attrs: {
                  title: _vm.$t(
                    "burnoutReportDetailsCards.mentaldistance.title"
                  ),
                  panelType: "BurnoutDetails",
                  cards: _vm.burnoutRecommendations.mentaldistance,
                  results: _vm.fullResults,
                  assessmentType: "burnout",
                  category: "mentaldistance",
                  assessmentId: _vm.assessmentId
                }
              }),
              _c("ReportPanel", {
                attrs: {
                  title: _vm.$t(
                    "burnoutReportDetailsCards.secondarysymptoms.title"
                  ),
                  panelType: "BurnoutDetails",
                  cards: _vm.burnoutRecommendations.secondarysymptoms,
                  results: _vm.fullResults,
                  assessmentType: "burnout",
                  category: "secondarysymptoms",
                  assessmentId: _vm.assessmentId
                }
              }),
              _vm.hasFixedRecommendations
                ? _c("ReportPanel", {
                    attrs: {
                      title: _vm.$t("burnoutReportPanelTitles.extraContent"),
                      panelType: "FixedBurnoutRecommendations",
                      results: _vm.burnoutResults,
                      assessmentType: "burnout",
                      assessmentId: _vm.assessmentId,
                      cards: _vm.fixedRecommendationCards
                    }
                  })
                : _vm._e(),
              _c("ReportPanel", {
                attrs: {
                  title: _vm.$t("burnoutReportPanelTitles.knowledgeBase"),
                  panelType: "BurnoutKnowledgeBase",
                  results: _vm.burnoutResults,
                  assessmentType: "burnout"
                }
              })
            ],
            1
          ),
          _vm.viewingOwnResults
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("EndOfReportFooter", {
                    attrs: {
                      assessmentID: _vm.assessmentId,
                      assessmentType: "Burnout assessment"
                    },
                    on: {
                      closeReport: function($event) {
                        return _vm.$emit("closeReport")
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }