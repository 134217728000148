<template>
  <vitrue-dialog
    :title="
      $vuetify.breakpoint.xs
        ? $t('dashboardCards.wellness.teaser.titleShort')
        : $t('dashboardCards.wellness.teaser.title')
    "
    image="wellness/heartBeat.png"
    @input="$emit('input', $event)"
  >
    <v-card rounded="xl" flat>
      <v-row
        justify="center"
        no-gutters
        style="overflow-y: auto; max-height: 42vh"
      >
        <v-col cols="12" lg="8" class="pa-3">
          <v-row justify="center" no-gutters>
            <p class="text-center text-body-1">
              {{ $t("dashboardCards.wellness.teaser.description") }}
            </p>
            <v-col
              v-for="point in teaserPoints"
              :key="point.title"
              cols="12"
              sm="6"
              lg="4"
            >
              <v-row justify="center" no-gutters>
                <v-col cols="auto">
                  <v-img
                    width="150px"
                    contain
                    :src="require(`@/assets/images/${point.image}`)"
                  />
                </v-col>
              </v-row>
              <p class="text-body-1 font-weight-bold text-center">
                {{ point.title }}
              </p>
              <p class="text-body-1 text-center">{{ point.description }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" style="height: 75px" no-gutters>
        <v-col cols="auto" align-self="center">
          <v-btn
            color="primary"
            rounded
            :width="$vuetify.breakpoint.xs ? '200px' : '375px'"
            @click="$router.push('/wellness', () => {})"
            >{{ $t("buttons.tryItYourself") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </vitrue-dialog>
</template>

<script>
import VitrueDialog from "@/components/common/VitrueDialog.vue";

export default {
  name: "PainManagementTeaser",
  components: {
    VitrueDialog
  },
  data() {
    return {
      teaserPoints: [
        {
          title: this.$t("dashboardCards.wellness.teaser.painRate.title"),
          description: this.$t(
            "dashboardCards.wellness.teaser.painRate.description"
          ),
          image: "wellness/pain.gif"
        },
        {
          title: this.$t("dashboardCards.wellness.teaser.reducePain.title"),
          description: this.$t(
            "dashboardCards.wellness.teaser.reducePain.description"
          ),
          image: "wellness/down.gif"
        },
        {
          title: this.$t("dashboardCards.wellness.teaser.habits.title"),
          description: this.$t(
            "dashboardCards.wellness.teaser.habits.description"
          ),
          image: "wellness/work.gif"
        }
      ]
    };
  }
};
</script>
