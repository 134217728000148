<template>
  <div>
    <v-dialog v-if="showPatientReport" v-model="showPatientReport" scrollable>
      <PatientHistoryDialog
        v-model="showPatientReport"
        :assessment="selectedAssessment"
        :results="results"
      />
    </v-dialog>
    <v-dialog
      v-if="showSendReminderDialog"
      v-model="showSendReminderDialog"
      width="500"
    >
      <PatientReminderDialog
        v-model="showSendReminderDialog"
        :assessment="selectedAssessment"
      />
    </v-dialog>
    <v-dialog
      v-if="showSendAllUsersAssessmentsDialog"
      v-model="showSendAllUsersAssessmentsDialog"
      max-width="500"
    >
      <SendAssessmentsToAllDialog v-model="showSendAllUsersAssessmentsDialog" />
    </v-dialog>
    <v-dialog
      v-if="showSendRestrictedAccessDialog"
      v-model="showSendRestrictedAccessDialog"
      width="500"
    >
      <restricted-assessment-dialog
        @close="showSendRestrictedAccessDialog = false"
        assessmentType="burnout"
      />
    </v-dialog>
    <v-dialog
      v-if="showSendBulkRemindersDialog"
      persistent
      v-model="showSendBulkRemindersDialog"
      width="500"
    >
      <PatientBulkReminderDialog
        v-model="showSendBulkRemindersDialog"
        :incompleteAssessments="incompleteAssessments"
      />
    </v-dialog>
    <v-row :justify="dashboard ? 'space-between' : 'end'" class="no-print">
      <h1 v-if="dashboard" class="text-h5">
        {{ $t("dashboardHistoryComponents.assessmentHistoryTable.title") }}
      </h1>
      <v-col :cols="isSmallScreen ? 12 : 5">
        <v-row justify="end" align="center">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label
            single-line
            style="min-width: 15vw; padding-right: 2vw"
          ></v-text-field>
          <v-tooltip
            bottom
            content-class="text-center"
            max-width="300"
            color="transparent"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="mr-3">
                <ExportToCSV :data="assessmentsForCSV" :exportName="csvName" />
              </div>
            </template>
            <vitrue-tooltip :message="$t('tooltips.exportTableToCSV')" />
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :loading="dataTableLoading"
      :headers="allHeaders"
      :items="assessments"
      :items-per-page="15"
      :search="search"
      :custom-filter="customFilter"
      @click:row="showResults"
      :custom-sort="customSort"
      :sort-by="['lastUpdatedAt']"
      :sort-desc="[true]"
      style="cursor: pointer"
      id="historyTable"
    >
      <template slot="no-data">
        <v-row justify="center" :value="true">
          <v-col cols="1">
            <v-img contain src="@/assets/images/undraw_no_data.svg"></v-img>
          </v-col>
          <v-col cols="12">
            {{
              $t(
                "dashboardHistoryComponents.assessmentHistoryTable.text.noData"
              )
            }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:header.tags="{ header }">
        <tag-filter
          v-model="filterTags"
          :items="allTagsWithNoTagOption"
          :label="header.text"
        />
      </template>
      <template v-slot:header.location="{ header }">
        <tag-filter
          v-model="filterLocations"
          :items="[
            { value: 'Home', text: getTranslatedLocation('Home') },
            { value: 'Office', text: getTranslatedLocation('Office') },
            {
              value: 'Other',
              text: getTranslatedLocation('Other')
            }
          ]"
          :label="header.text"
          :maxWidth="dashboard ? 250 : 300"
        />
      </template>
      <template v-slot:header.state="{ header }">
        <tag-filter
          v-model="filterStatuses"
          :items="statuses"
          :label="header.text"
        />
      </template>
      <template v-slot:item.assessmentType="{ item }">
        <v-icon left class="mr-3" :color="getIconColour(item.assessmentType)">{{
          getIcon(item.assessmentType)
        }}</v-icon>
        <span>{{ getTranslatedAssessmentType(item.assessmentType) }} </span>
      </template>
      <template v-slot:item.result="{ item }">
        <span v-if="item.score === null"> - </span>
        <v-row justify="space-between" v-else>
          <v-col cols="6">
            {{ `${item.score}%` }}
          </v-col>
          <v-col :cols="4" xl="6" class="px-0">
            <assessment-insight-tooltip :id="item.id" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip :color="getStatusColour(item.state)" small dark>{{
          getTranslatedState(item.state)
        }}</v-chip>
      </template>
      <template v-slot:item.lastUpdatedAt="{ item }">
        <span>
          {{ getAssessmentLastEdited(item).toLocaleDateString() }}
        </span>
      </template>
      <template v-slot:item.name="{ item }">
        <span class="data-hj-suppress">
          {{ !!item.name && !anonymousResults ? item.name : "-" }}
        </span>
      </template>
      <template v-slot:item.email="{ item }">
        <span class="data-hj-suppress">
          {{ anonymousResults ? "-" : item.email.value }}
        </span>
      </template>
      <template v-slot:item.tags="{ item }">
        <table-tags-drop-down
          :tags="teamTags"
          :item="item"
          :editable="false"
          :manageTags="false"
          @error="
            errorMessage = $event;
            showSnackbar = true;
          "
        />
      </template>
      <template v-slot:item.location="{ item }">
        <v-chip
          v-if="item.location"
          :color="getLocationColour(item.location)"
          outlined
          small
          dark
          >{{ getTranslatedLocation(item.location) }}</v-chip
        >
      </template>
      <template v-slot:item.action="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" v-if="showActionMenu(item)">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="canSendReminder(item)"
              @click="showResults(item)"
            >
              <v-list-item-title
                >{{ actionForUserType }} {{ item.name }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-if="canDeleteAssessments"
              @click="deleteAssessment(item.id)"
            >
              <v-list-item-title>{{ $t("buttons.delete") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-col v-if="showSendNewAssessmentsButton" cols="12" sm="8" md="6" lg="4">
        <v-tooltip
          top
          content-class="text-center"
          max-width="300"
          color="transparent"
          :disabled="!disableEmails"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                :disabled="disableEmails"
                color="primary"
                width="100%"
                @click="showSendAllUsersAssessmentsDialog = true"
                >{{
                  $t(
                    "dashboardHistoryComponents.assessmentHistoryTable.sendNewAssessmentsButton"
                  )
                }}</v-btn
              >
            </div>
          </template>
          <vitrue-tooltip :message="$t('tooltips.disabledByAdmin')" />
        </v-tooltip>
      </v-col>
      <v-col v-if="showSendMultipleReminders" cols="12" sm="8" md="6" lg="4">
        <v-tooltip
          top
          content-class="text-center"
          max-width="300"
          color="transparent"
          :disabled="!disableEmails"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                :disabled="disableEmails"
                color="primary"
                width="100%"
                @click="showSendBulkRemindersDialog = true"
                >{{
                  $t(
                    "dashboardHistoryComponents.assessmentHistoryTable.sendReminderButton"
                  )
                }}</v-btn
              >
            </div>
          </template>
          <vitrue-tooltip :message="$t('tooltips.disabledByAdmin')" />
        </v-tooltip>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showSnackbar"
      content-class="text-center"
      color="error"
    >
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import PatientHistoryDialog from "./PatientHistoryDialog";
import PatientReminderDialog from "./PatientReminderDialog";
import RestrictedAssessmentDialog from "./RestrictedAssessmentDialog";
import TableTagsDropDown from "../TableTagsDropDown";
import PatientBulkReminderDialog from "@/components/dashboard/PatientBulkReminderDialog";
import SendAssessmentsToAllDialog from "@/components/dashboard/SendAssessmentsToAllDialog";
import {
  getDeskAssessmentResult,
  getAssessmentTableHistoryData
} from "@/services/modules/api-queries";
import { getTeamTags, deleteAssessment } from "@/customApi";
import { mapGetters } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip";
import ExportToCSV from "./ExportToCSV.vue";
import TagFilter from "./TagFilter.vue";
import AssessmentInsightTooltip from "../AssessmentInsightTooltip.vue";

export default {
  name: "AssessmentHistoryTable",
  components: {
    PatientHistoryDialog,
    PatientReminderDialog,
    PatientBulkReminderDialog,
    TableTagsDropDown,
    RestrictedAssessmentDialog,
    VitrueTooltip,
    ExportToCSV,
    SendAssessmentsToAllDialog,
    TagFilter,
    AssessmentInsightTooltip
  },
  props: {
    dashboard: Boolean,
    value: Array
  },
  data() {
    return {
      dataTableLoading: false,
      showPatientReport: false,
      showSendReminderDialog: false,
      showSendBulkRemindersDialog: false,
      showSendRestrictedAccessDialog: false,
      showSendAllUsersAssessmentsDialog: false,
      email: "",
      search: "",
      errorMessage: "",
      results: {},
      assessments: [],
      filterTags: [],
      filterLocations: [],
      filterStatuses: [],
      showSnackbar: false,
      teamTags: this.value || [],
      assessmentActions: null,
      dashboardHeaders: [
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.type"
          ),
          value: "assessmentType",
          width: "12%"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.location"
          ),
          value: "location",
          sortable: false,
          filter: this.sortByLocation
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.email"
          ),
          value: "email"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.tag"
          ),
          value: "tags",
          sortable: false,
          filter: this.sortByTag
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.result"
          ),
          value: "result",
          sortable: true
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.status"
          ),
          value: "state",
          sortable: false,
          filter: this.sortByStatus
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.lastEdited"
          ),
          value: "lastUpdatedAt"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.action"
          ),
          value: "action"
        }
      ],
      certificationHeaders: [
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.name"
          ),
          value: "name"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.email"
          ),
          value: "email"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.location"
          ),
          value: "location",
          sortable: false,
          filter: this.sortByLocation
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.tag"
          ),
          value: "tags",
          width: "20%",
          sortable: false,
          filter: this.sortByTag
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.lastEdited"
          ),
          value: "lastUpdatedAt"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.assessmentHistoryTable.labels.status"
          ),
          value: "state",
          sortable: false,
          filter: this.sortByStatus
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "userEmail",
      "anonymousResults",
      "disableEmails",
      "isSuperAdmin"
    ]),
    showSendNewAssessmentsButton() {
      return this.dashboard;
    },
    showSendMultipleReminders() {
      return this.incompleteAssessments.length > 1 && this.dashboard;
    },
    actionForUserType() {
      return this.$t(
        "dashboardHistoryComponents.assessmentHistoryTable.actions.remind"
      );
    },
    incompleteAssessments() {
      return this.assessments.filter(isIncomplete);
      function isIncomplete(item) {
        if (item.state !== "Complete" && item.state !== "Failed") {
          return item;
        }
      }
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    allTags() {
      return this.teamTags.filter(x => x.expiredAt === null);
    },
    allTagsWithNoTagOption() {
      let tagNames = this.allTags.map(x => {
        return { text: x.name, value: x.name };
      });
      let noTagText = this.$t(
        "dashboardHistoryComponents.assessmentHistoryTable.labels.noTag"
      );
      let noTag = { value: "No Tag", text: noTagText };
      return tagNames.length > 0 ? tagNames.concat([noTag]) : [];
    },
    allHeaders() {
      let headers = this.dashboard
        ? this.dashboardHeaders
        : this.certificationHeaders;
      return headers;
    },
    assessmentsForCSV() {
      if (!this.assessments) {
        return;
      }
      return this.assessments.map(x => {
        return {
          assessmentType: x.assessmentType,
          location: x.location,
          email: this.anonymousResults ? "-" : x.email.value,
          state: x.state,
          tags: x.tags.map(a => a.name).join(";"),
          lastEdited: this.getAssessmentLastEdited(x).toLocaleDateString(),
          score: x.score
        };
      });
    },
    csvName() {
      let today = new Date();
      return (
        "Vitrue Assessments " + today.toLocaleDateString().replaceAll("/", ".")
      );
    },
    canDeleteAssessments() {
      return this.isSuperAdmin;
    },
    statuses() {
      return [
        {
          value: "Pending",
          text: this.getTranslatedState("Pending")
        },
        {
          value: "In Progress",
          text: this.getTranslatedState("In Progress")
        },
        {
          value: "Complete",
          text: this.getTranslatedState("Complete")
        },
        {
          value: "Failed To Send Invitation",
          text: this.getTranslatedState("Failed To Send Invitation")
        }
      ];
    }
  },
  async mounted() {
    this.updateHistory();
  },
  watch: {
    showSendReminderDialog(newVal) {
      if (!newVal) {
        this.updateHistory();
      }
    },
    showSendAllUsersAssessmentsDialog(newVal) {
      if (!newVal) {
        this.updateHistory();
      }
    }
  },
  methods: {
    async showResults(item) {
      this.selectedAssessment = item;
      if (item.state !== "Complete") {
        this.showSendReminderDialog = true;
        return;
      }

      if (this.anonymousResults) {
        this.showSendRestrictedAccessDialog = true;
        return;
      }

      try {
        if (item.assessmentType === "Desk assessment") {
          this.dataTableLoading = true;
          this.results = await getDeskAssessmentResult(item.id);
        } else if (item.assessmentType === "Burnout assessment") {
          this.showSendRestrictedAccessDialog = true;
        }
      } catch (error) {
        this.removeAsyncRequest();
        this.$logger.captureException(error);
      } finally {
        if (item.assessmentType === "Desk assessment") {
          this.showPatientReport = true;
        }

        this.dataTableLoading = false;
      }
    },
    async deleteAssessment(assessmentId) {
      try {
        await deleteAssessment(assessmentId);
        this.assessments = this.assessments.filter(
          item => item.id !== assessmentId
        );
      } catch (error) {
        this.$logger.captureException(error);
      }
    },
    async updateHistory() {
      try {
        await this.getTeamTags();
        this.dataTableLoading = true;
        let assessments = await getAssessmentTableHistoryData(false);
        this.assessments = this.dashboard
          ? assessments
          : assessments.filter(
              x => x.assessmentType === "Desk assessment" && !!x.email
            );
      } catch (error) {
        this.$logger.captureException(error);
      } finally {
        this.dataTableLoading = false;
        this.teamTags = [...this.teamTags];
      }
    },
    getStatusColour(status) {
      if (status === "Failed To Send Invitation") return "vitrueDarkGrey";
      if (status === "Pending") return "red lighten-2";
      if (status === "Complete") return "green lighten-2";
      if (status === "In Progress") return "orange lighten-2";
      else return "orange";
    },
    getIconColour(type) {
      if (type === "Desk assessment") {
        return "primary";
      } else if (type === "Burnout assessment") {
        return "accent";
      }

      return "warning";
    },
    getIcon(type) {
      if (type === "Desk assessment") {
        return "laptop_chromebook";
      } else if (type === "Burnout assessment") {
        return "mdi-lightning-bolt";
      }

      return "help";
    },
    getAssessmentLastEdited(assessment) {
      let lastEdited = assessment.lastUpdatedAt || assessment.createdAt;
      return new Date(lastEdited);
    },
    customSort(items, sortBy, isDesc) {
      let index = sortBy[0];
      items.sort((a, b) => {
        if (index == "lastUpdatedAt") {
          if (!isDesc[0]) {
            return (
              this.getAssessmentLastEdited(a) - this.getAssessmentLastEdited(b)
            );
          } else {
            return (
              this.getAssessmentLastEdited(b) - this.getAssessmentLastEdited(a)
            );
          }
        } else if (index === "result") {
          if (!isDesc[0]) {
            return a.score === null || a.score < b.score ? -1 : 1;
          } else {
            return b.score === null || b.score < a.score ? -1 : 1;
          }
        } else if (index === "email") {
          if (!isDesc[0]) {
            return a[index].value < b[index].value ? -1 : 1;
          } else {
            return b[index].value < a[index].value ? -1 : 1;
          }
        } else if (index !== undefined) {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    customFilter(value, search, item) {
      var searchLowerCase = search.toLowerCase();
      let tagNames = item.tags.map(x => x.name).join();
      let emailMatch = item.email.value?.includes(searchLowerCase);
      let tagMatch = tagNames.toLowerCase().includes(searchLowerCase);
      let nameMatch = item.name?.toLowerCase().includes(searchLowerCase);

      if (this.anonymousResults) {
        return tagMatch;
      }
      if (this.dashboard) {
        return emailMatch || tagMatch;
      }
      return emailMatch || nameMatch || tagMatch;
    },
    sortByTag(value) {
      if (this.filterTags.length === 0) {
        return true;
      }

      var noTagFilter = this.filterTags.find(x => x.value === "No Tag");

      if (!!noTagFilter && value.length === 0) {
        return true;
      }

      let names = value.map(x => x.name);
      return this.filterTags.every(x => names.includes(x.value));
    },
    sortByLocation(value) {
      if (this.filterLocations.length === 0) {
        return true;
      }

      if (this.filterLocations.find(x => x.value === "Other")) {
        if (!!this.filterLocations.find(x => x.value === value)) {
          return true;
        }
        return value !== "Home" && value !== "Office" && value !== null;
      }

      return !!this.filterLocations.find(x => x.value === value);
    },
    sortByStatus(value) {
      if (this.filterStatuses.length === 0) {
        return true;
      }
      return this.filterStatuses.some(x => x.value === value);
    },
    async getTeamTags() {
      try {
        let dto = {
          everythingTag: false
        };
        let tags = (await getTeamTags(dto)) || [];
        this.teamTags = tags;
        this.$emit("input", this.teamTags);
      } catch (err) {
        this.teamTags = [];
      }
    },
    getTranslatedAssessmentType(type) {
      if (type === "Desk assessment") {
        return this.$t(
          "dashboardHistoryComponents.assessmentHistoryTable.assessmentTypes.desk"
        );
      } else if (type === "Burnout assessment") {
        return this.$t(
          "dashboardHistoryComponents.assessmentHistoryTable.assessmentTypes.burnout"
        );
      }

      return "-";
    },
    getTranslatedLocation(location) {
      let baseRoute =
        "dashboardHistoryComponents.assessmentHistoryTable.assessmentLocations";
      if (location === "Home") {
        return this.$t(`${baseRoute}.home`);
      } else if (location === "Office") {
        return this.$t(`${baseRoute}.office`);
      } else if (location === "NotAtUsualWorkspace") {
        return this.$t(`${baseRoute}.notAtUsualWorkspace`);
      } else if (location === "Other") {
        return this.$t(`${baseRoute}.other`);
      }

      return "";
    },
    getLocationColour(location) {
      switch (location) {
        case "Home":
          return "accent";
        case "Office":
          return "primary";
        case "NotAtUsualWorkspace":
          return "secondary";
        default:
          return "primary";
      }
    },
    getTranslatedState(state) {
      switch (state) {
        case "Complete":
          return this.$t("statusCodes.20");
        case "In Progress":
          return this.$t("statusCodes.10");
        case "Pending":
          return this.$t("statusCodes.0");
        case "Failed":
          return this.$t("statusCodes.150");
        case "Failed To Send Invitation":
          return this.$t("statusCodes.120");
        default:
          return "-";
      }
    },
    canSendReminder(item) {
      return (
        item.state !== "Complete" &&
        item.state !== "Failed" &&
        item.endUserActive &&
        !this.disableEmails
      );
    },
    showActionMenu(item) {
      return this.canSendReminder(item) || this.isSuperAdmin;
    }
  }
};
</script>

<style scoped>
.chipText {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noClick {
  cursor: default;
}
</style>
