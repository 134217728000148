import { API, Auth } from "aws-amplify";
import SentryClient from "@/sentry";

const apiName = "ApiGateway";
const sessionHeaderKey = "vitrue-vida-session-id";

async function UpdateAuthorizationHeaders(headers) {
  try {
    var session = await Auth.currentSession();
  } catch (e) {
    return {};
  }
  if (!headers) headers = {};

  var vidaSessionId = localStorage.getItem(sessionHeaderKey);
  if (!!vidaSessionId) headers[sessionHeaderKey] = vidaSessionId;

  if (session && session.isValid) {
    headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
  }

  return headers;
}

async function getApiInit(defaultInit) {
  let myInit = defaultInit || {};
  myInit.headers = await UpdateAuthorizationHeaders();
  myInit.response = true;
  return myInit;
}

async function makeRequest(request) {
  try {
    let response = await request();
    if (response.headers[sessionHeaderKey] !== undefined) {
      localStorage.setItem(
        sessionHeaderKey,
        response.headers[sessionHeaderKey]
      );
    }
    return response.data;
  } catch (errorResponse) {
    SentryClient.captureRestException(errorResponse);
    if (
      errorResponse.response &&
      errorResponse.response.data &&
      errorResponse.response.data.error
    ) {
      throw errorResponse.response.data.error;
    }

    throw errorResponse;
  }
}

export default {
  async post(path, body) {
    let apiInit = await getApiInit();
    if (body) {
      apiInit.body = body;
    }
    return await makeRequest(() => API.post(apiName, path, apiInit));
  },
  async get(path) {
    let apiInit = await getApiInit();
    return await makeRequest(() => API.get(apiName, path, apiInit));
  },
  async delete(path, body) {
    let apiInit = await getApiInit();
    if (body) {
      apiInit.body = body;
    }
    return await makeRequest(() => API.del(apiName, path, apiInit));
  },
  async put(path, body) {
    let apiInit = await getApiInit();
    if (body) {
      apiInit.body = body;
    }
    return await makeRequest(() => API.put(apiName, path, apiInit));
  },
  sessionHeaderKey
};
