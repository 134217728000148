import DeskAssessmentPregnancyResults from "@/assets/json/DeskAssessment/DeskAssessmentPregnancyResults";

export default {
  getPregnancyInformationCards(results) {
    let cardsToShow = [];
    Object.values(DeskAssessmentPregnancyResults).forEach(card => {
      if (results[card.showOn.question] == card.showOn.value) {
        cardsToShow.push(card);
      }
    });
    return cardsToShow;
  },
  getExerciseRecommendationUrl(card, results) {
    let pregnancyUrl = card.json.pregnancyUrl;
    if (pregnancyUrl && results.health.pregnancy === 1) {
      if (pregnancyUrl.overall) {
        return pregnancyUrl.overall;
      }

      if (results.health.pregnancyTrimester === 1) {
        return pregnancyUrl.firstTrimester;
      } else if (results.health.pregnancyTrimester === 2) {
        return pregnancyUrl.secondTrimester;
      }
      return pregnancyUrl.thirdTrimester;
    }

    return card.json.link;
  }
};
