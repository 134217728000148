import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/authentication/Login";
import Dashboard from "@/components/dashboard/Dashboard";
import Store from "../store";
import sentry from "@/sentry";
import TeamService from "@/services/teamService.js";
import { VIDARoles } from "../services/authorization/user-types";

const DevPage = () => import("@/components/internal/DevPage");
const ClinicalPage = () => import("@/components/internal/ClinicalPage");
const SignUp = () => import("@/components/authentication/SignUp");
const ResetPassword = () => import("@/components/authentication/ResetPassword");
const InvitationSignUp = () =>
  import("@/components/authentication/InvitationSignUp");
const Certification = () => import("@/components/deskassessment/Certification");
const DeskAssessment = () =>
  import("@/components/deskassessment/DeskAssessment");
const BurnoutAssessment = () =>
  import("@/components/burnout-assessment/BurnoutAssessment");
const Account = () => import("@/components/account/Account");
const Subscription = () => import("@/components/account/Subscription");
const PersonalSettings = () => import("@/components/account/PersonalSettings");
const Team = () => import("@/components/account/Team");
const ErrorPage = () => import("@/components/layout/ErrorPage");
const PageNotFound = () => import("@/components/layout/PageNotFound");
const CreateTeam = () => import("@/components/authentication/CreateTeam");
const ChooseTeam = () => import("@/components/authentication/ChooseTeam");
const JoinTeam = () => import("@/components/authentication/JoinTeam");
const ShareableLink = () => import("@/components/deskassessment/ShareableLink");
const VisualDeskPlanner = () =>
  import("@/components/deskassessment/preview/VisualDeskPlannerPreview");
const Analytics = () => import("@/components/dashboard/analytics/Analytics");
const Wellness = () =>
  import("@/components/dashboard/wellness/WellnessDashboard");
const BlazePoseTestPage = () =>
  import("@/components/internal/BlazePoseTesting");
const FeedbackPage = () => import("@/components/layout/FeedbackPage");
const EmailPainCheckIn = () =>
  import("@/components/layout/EmailPainCheckinPage");

Vue.use(Router);

let router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
      meta: { noTeamRequiredIfSignedIn: true, hideSideBar: true },
      props: true
    },
    {
      path: "/VitrueLogin",
      name: "VitrueLogin",
      component: Login,
      meta: { noTeamRequiredIfSignedIn: true, hideSideBar: true }
    },
    {
      path: "/signUp",
      name: "SignUp",
      component: SignUp,
      meta: { noTeamRequiredIfSignedIn: true, hideSideBar: true },
      props: true
    },
    {
      path: "/ResetPassword",
      name: "ResetPassword",
      component: ResetPassword,
      meta: { noTeamRequiredIfSignedIn: true, hideSideBar: true }
    },
    {
      path: "/invitation-signup",
      name: "InvitationSignUp",
      component: InvitationSignUp,
      meta: { noTeamRequiredIfSignedIn: true, hideSideBar: true },
      props: true
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: { requiresAuth: true }
    },
    {
      path: "/deskassessment/:assessmentId",
      name: "DeskAssessment",
      component: DeskAssessment,
      props: true
    },
    {
      path: "/deskassessment/:origin/:assessmentId",
      name: "DeskAssessment",
      component: DeskAssessment,
      props: true
    },
    {
      path: "/burnoutassessment/:assessmentId",
      name: "BurnoutAssessment",
      component: BurnoutAssessment,
      props: true
    },
    {
      // shareable link
      path: "/share/:parentCompanyId",
      name: "ShareableLink",
      component: ShareableLink,
      meta: { requiresAuth: false, noTeamRequiredIfSignedIn: true },
      props: { default: true, companyName: "", name: "", emailAddress: "" }
    },
    {
      // embedded shareable link (name optional)
      path: "/share/:parentCompanyId/:companyName/:emailAddress",
      name: "ShareableLinkSomeDetails",
      component: ShareableLink,
      meta: { requiresAuth: false, noTeamRequiredIfSignedIn: true }
    },
    {
      // embedded shareable link full details
      path: "/share/:parentCompanyId/:companyName/:emailAddress/:name",
      name: "ShareableLinkAllDetails",
      component: ShareableLink,
      meta: { requiresAuth: false, noTeamRequiredIfSignedIn: true }
    },
    {
      path: "/visualdeskplanner-preview",
      name: "VisualDeskPlanner",
      component: VisualDeskPlanner,
      meta: { requiresAuth: false, noTeamRequiredIfSignedIn: true }
    },
    {
      path: "/error",
      name: "ErrorPage",
      component: ErrorPage,
      meta: { noTeamRequiredIfSignedIn: true },
      props: true
    },
    {
      path: "/certification",
      name: "Certification",
      meta: { requiresAuth: true },
      component: Certification,
      props: true
    },
    {
      path: "/vitruedev",
      name: "DevPage",
      meta: { requiresAuth: true },
      component: DevPage,
      props: true
    },
    {
      path: "/vitrueclinical",
      name: "ClinicalPage",
      meta: { requiresAuth: true },
      component: ClinicalPage,
      props: true
    },
    {
      path: "/account",
      name: "Account",
      component: Account,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          path: "/account/subscription",
          name: "Subscription",
          component: Subscription,
          meta: { requiresAuth: true },
          props: true
        },
        {
          path: "/account/team",
          name: "Team",
          component: Team,
          meta: { requiresAuth: true }
        },
        {
          path: "/account/personal-settings",
          name: "PersonalSettings",
          component: PersonalSettings,
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: "/analytics",
      name: "Analytics",
      component: Analytics,
      meta: { requiresAuth: true }
    },
    {
      path: "/create-team",
      name: "CreateTeam",
      component: CreateTeam,
      props: true,
      meta: {
        requiresAuth: true,
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true
      }
    },
    {
      path: "/choose-team",
      name: "ChooseTeam",
      component: ChooseTeam,
      props: true,
      meta: {
        requiresAuth: true,
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true
      }
    },
    {
      path: "/join-team",
      name: "JoinTeam",
      component: JoinTeam,
      props: true,
      meta: {
        requiresAuth: true,
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true
      }
    },
    {
      path: "/wellness",
      name: "Wellness",
      component: Wellness,
      props: true,
      meta: { requiresAuth: true, requiresTeamIfSignedIn: true }
    },
    {
      path: "/BlazePoseTestPage",
      name: "BlazePoseTestPage",
      component: BlazePoseTestPage,
      meta: { requiresAuth: true }
    },
    {
      path: "/feedback",
      name: "Feedback",
      component: FeedbackPage,
      props: true,
      meta: { hideSideBar: true }
    },
    {
      path: "/pain-check-in",
      name: "EmailPainCheckIn",
      component: EmailPainCheckIn,
      props: true,
      meta: { hideSideBar: true }
    },
    {
      path: "*",
      name: "404",
      component: PageNotFound,
      meta: { noTeamRequiredIfSignedIn: true },
      props: true
    }
  ]
});

router.beforeResolve(async (to, from, next) => {
  hj("stateChange", to.path);
  if (to.name === "Login" || to.name === "SignUp") {
    next();
    return;
  }
  Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
    .then(async user => {
      let teamMberInfo = await TeamService.getTeamMemberInfo();
      if (!Store.getters.userId) {
        if (teamMberInfo.role !== VIDARoles.EndUser) {
          await Store.dispatch("getUserTeamAndSubscriptionBaseInformation", {
            memberId: teamMberInfo.teamMemberId,
            forceToUpdateExistedInformation: true
          });
        }
        if (!to.matched.some(record => record.meta.noTeamRequiredIfSignedIn)) {
          await TeamService.updateEndUserInfo();
        }

        // For HotJar / Sentry
        // if user returned and we are signing in - set user data
        if (user && user.signInUserSession) {
          await Store.dispatch("setCurrentUserData", teamMberInfo, user);
          let role = "Unknown";
          switch (teamMberInfo.role) {
            case 0:
              role = "Admin";
              break;
            case 10:
              role = "SuperAdmin";
              break;
            case 20:
              role = "RestrictedAdmin";
              break;
            case 40:
              role = "EndUser";
              break;
          }
          window.hj("identify", teamMberInfo.endUserId, {
            teamId: teamMberInfo.teamId,
            role: teamMberInfo.role
          });
          sentry.setUser({ id: teamMberInfo.endUserId });
        }
      }

      // if user has a team to join, then send them to choose team page.
      // can end up in endless loop if not checking for != chooseteam
      if (
        teamMberInfo &&
        teamMberInfo.teamsToJoinRequested.length > 0 &&
        to.name != "ChooseTeam"
      ) {
        next({
          name: "ChooseTeam"
        });
        return;
      }
      // if user signed in, but not part of team - redirect to login if page requires team when signed in
      else if (user && Store.getters.signedIn && !Store.getters.userIsInTeam) {
        if (!to.matched.some(record => record.meta.noTeamRequiredIfSignedIn)) {
          next({ path: "/" });
          return;
        }
      }

      next();
    })
    .catch(err => {
      if (!to.matched.some(record => record.meta.requiresAuth)) {
        next();
        return;
      } else if (!Store.state.signedIn) {
        next({ path: "/" });
      }
      sentry.captureException(err);
    });
});

router.onError(error => {
  if (
    /loading chunk .* failed./i.test(error.message) &&
    !Store.state.showRefreshBar
  ) {
    Store.state.showRefreshBar = true;
  }
});

export default router;
