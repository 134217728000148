var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.completedChecklist
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "1200" },
              model: {
                value: _vm.completedChecklist,
                callback: function($$v) {
                  _vm.completedChecklist = $$v
                },
                expression: "completedChecklist"
              }
            },
            [
              _c("admin-check-list-complete", {
                on: { closed: _vm.completeAdminChecklist },
                model: {
                  value: _vm.completedChecklist,
                  callback: function($$v) {
                    _vm.completedChecklist = $$v
                  },
                  expression: "completedChecklist"
                }
              })
            ],
            1
          )
        : _vm.onboardingChecklist.series === "AdminOnboardingTour"
        ? _c(
            "v-card",
            {
              staticClass: "mx-auto white--text",
              attrs: {
                width: _vm.componentWidth,
                color: "vitrueGrey",
                rounded: "0"
              }
            },
            [
              _c(
                "v-card-actions",
                {
                  staticClass: "clickable",
                  attrs: { id: "card-header" },
                  on: {
                    click: function($event) {
                      _vm.showContent = !_vm.showContent
                    }
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "showContentButton",
                        icon: "",
                        color: "white"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _vm.showContent
                        ? _c(
                            "v-icon",
                            { attrs: { large: "", id: "chevronDownIcon" } },
                            [_vm._v("mdi-chevron-down")]
                          )
                        : _c(
                            "v-icon",
                            { attrs: { large: "", id: "chevronUpIcon" } },
                            [_vm._v("mdi-chevron-up")]
                          )
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "p",
                    {
                      staticClass: "text-h5 py-0 my-0 mr-2",
                      attrs: { id: "checklistTitle" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("onboarding.adminCheckList.title")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "px-4 pb-2",
                  attrs: { "no-gutters": "", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          id: "percentageBar",
                          "background-color": "white",
                          color: "primary",
                          height: "15"
                        },
                        model: {
                          value: _vm.percentageComplete,
                          callback: function($$v) {
                            _vm.percentageComplete = $$v
                          },
                          expression: "percentageComplete"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.showContent
                    ? _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: { cols: "12", id: "percentageText" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.percentageComplete +
                                  "% " +
                                  _vm.$t(
                                    "onboarding.adminCheckList.percentageComplete"
                                  )
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-expand-transition", [
                _vm.showContent
                  ? _c(
                      "div",
                      {
                        staticClass: "pb-3",
                        attrs: { id: "checklistContent" }
                      },
                      _vm._l(_vm.checklistItems, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "mx-4 mt-3 pl-6 pt-4 pb-3",
                            class: _vm.itemStyle(item),
                            attrs: { id: "item" + item.id },
                            on: {
                              mouseover: function($event) {
                                return _vm.setHoveredTask(item.id)
                              },
                              mouseleave: function($event) {
                                return _vm.setHoveredTask(undefined)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "text-subtitle-1",
                                class: _vm.textColor(item)
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mb-1 mr-2",
                                    attrs: { color: _vm.iconColor(item) }
                                  },
                                  [_vm._v(_vm._s(_vm.iconToShow(item)))]
                                ),
                                _vm._v(" " + _vm._s(item.text))
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }