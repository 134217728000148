<template>
  <v-card class="pa-4" color="white">
    <v-toolbar
      v-if="$vuetify.breakpoint.xs && type === 'deskSetup'"
      dense
      width="100%"
      color="primary"
    >
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text color="white" @click="$emit('input', false)"> Close </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-btn v-else class="close-button" @click="$emit('input', false)" icon>
      <v-icon>clear</v-icon>
    </v-btn>

    <initial-desk-measurements v-if="type === 'webcamAnalysis'" fixResults />
    <sims v-if="type === 'deskSetup'" v-model="fakeSimsResults" />
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

const InitialDeskMeasurements = () =>
  import("@/components/deskassessment/InitialDeskMeasurements");
const Sims = () => import("@/components/deskassessment/Sims.vue");

export default {
  name: "QuickAssess",
  props: {
    type: String
  },
  data() {
    return {
      fakeSimsResults: {}
    };
  },
  components: {
    InitialDeskMeasurements,
    Sims
  },
  mounted() {
    this.openModal();
  },
  beforeDestroy() {
    this.closeModal();
  },
  watch: {
    tab() {
      this.fakeSimsResults = {};
    }
  },
  methods: {
    ...mapMutations(["openModal", "closeModal"])
  }
};
</script>
