<template>
  <v-container>
    <v-tooltip
      bottom
      max-width="250px"
      :disabled="!showToolTip"
      color="transparent"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" style="cursor:default">
          <v-row v-if="!primaryLoaded" class="text-h3" justify="center">
            <v-progress-circular
              class="mb-2"
              :size="50"
              indeterminate
              color="primary"
            />
          </v-row>
          <v-row
            id="primaryValue"
            v-if="primaryLoaded"
            class="text-h3 text-center"
            justify="center"
            >{{ primaryValueOrDefault }}</v-row
          >
          <v-row
            id="primaryLabel"
            class="text-h6 grey--text text-center"
            justify="center"
            >{{ primaryText }}</v-row
          >
          <v-row
            v-if="secondaryText || secondaryValue"
            justify="center"
            class="pt-4"
          >
            <div
              id="secondaryValueAndText"
              class="text-subtitle-2 border text-center"
              :class="textcolour"
            >
              <v-progress-circular
                v-if="!secondaryLoaded"
                :size="20"
                indeterminate
                color="primary"
              />
              {{ secondaryValueOrDefault }} {{ secondaryText }}
            </div>
          </v-row>
        </div>
      </template>
      <vitrue-tooltip :message="toolTipText" />
    </v-tooltip>
  </v-container>
</template>

<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
export default {
  name: "analytics-tile",
  components: {
    VitrueTooltip
  },
  data() {
    return {
      primaryValueToDisplay: 0,
      secondaryValueToDisplay: 0
    };
  },
  props: {
    primaryLoaded: Boolean,
    secondaryLoaded: Boolean,
    primaryNumberValue: Number,
    primaryTextValue: String,
    primaryText: String,
    secondaryValue: Number,
    secondaryText: String,
    primaryPercentage: Boolean,
    secondaryPercentage: Boolean,
    toolTipText: String
  },
  watch: {
    primaryNumberValue(newVal) {
      this.animateValueNumber(0, newVal, true);
    },
    secondaryValue(newVal) {
      this.animateValueNumber(0, newVal, false);
    }
  },
  computed: {
    primaryValue() {
      return this.primaryNumberValue ?? this.primaryTextValue;
    },
    primaryValueOrDefault() {
      if (this.primaryValue === null || this.primaryValue === undefined) {
        return "-";
      }

      if (this.primaryTextValue) {
        return this.primaryValue;
      }

      var unit = "";
      if (this.primaryPercentage) {
        unit = "%";
      }
      return this.primaryValueToDisplay + unit;
    },
    secondaryValueOrDefault() {
      if (this.secondaryValue === null || this.secondaryValue === undefined) {
        return "-";
      }

      var unit = "";
      var symbol = "";
      if (this.secondaryPercentage) {
        unit = "%";
        if (this.secondaryValue > 0) {
          symbol = "▲ ";
        } else if (this.secondaryValue < 0) {
          symbol = "▼ ";
        }
      }
      return symbol + Math.abs(this.secondaryValueToDisplay) + unit;
    },
    secondaryColour() {
      if (!this.secondaryPercentage) {
        return "primary";
      }
      if (this.secondaryValue > 0) {
        return "success";
      } else if (this.secondaryValue < 0) {
        return "error";
      }
      return "primary";
    },
    textcolour() {
      return this.secondaryColour + "--text";
    },
    showToolTip() {
      return (
        (this.primaryValue === null || this.primaryValue === undefined) &&
        this.toolTipText
      );
    }
  },
  methods: {
    animateValueNumber(i, endVal, primary) {
      var endValPositive = endVal >= 0;
      var valueDifference = Math.abs(endVal - i);

      // timeOutValue
      // if over 20 remaining, then go at constant speed
      // if less than 20, reduce by factor of remaining values
      var differenceToSlowDown = 20; // value at which we start slowing down speed
      let timeOutValue =
        valueDifference <= differenceToSlowDown
          ? ((differenceToSlowDown - valueDifference) / differenceToSlowDown) *
            100
          : 10;

      if ((endValPositive && i <= endVal) || (!endValPositive && i >= endVal)) {
        if (primary) {
          this.primaryValueToDisplay = i;
        } else {
          this.secondaryValueToDisplay = i;
        }

        var nextVal = valueDifference > 100 ? 100 : 1;
        var nextValue = endValPositive ? i + nextVal : i - nextVal;

        setTimeout(() => {
          this.animateValueNumber(nextValue, endVal, primary);
        }, timeOutValue);
      }
    }
  }
};
</script>

<style scoped>
.border {
  border-style: solid;
  border-width: 1px;
  border-color: var(--v-secondaryColour-base);
  border-radius: 10px;
  padding: 10px;
}
</style>
