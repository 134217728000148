<template>
  <v-card rounded="xl">
    <v-row
      :class="image ? 'dialogMargin' : ''"
      no-gutters
      justify="center"
      style="`width: 100%; overflow: hidden"
    >
      <v-img
        :src="require('@/assets/images/modalHeader.svg')"
        style="width: 100%"
        :height="bannerSize"
        ><p :class="titleClass" class="ma-0 white--text text-center">
          {{ title }}
        </p></v-img
      >
      <div class="roundedDialogTopper" :class="topperSize" v-if="image">
        <v-img
          class="imagePosition"
          width="70%"
          height="70%"
          :src="require(`@/assets/images/${image}`)"
        />
      </div>

      <v-btn
        v-if="!hideCloseButton"
        class="close-button"
        icon
        :large="$vuetify.breakpoint.mdAndUp"
        @click="$emit('input', false)"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <slot> </slot>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "VitrueDialog",
  props: {
    value: Boolean,
    image: String,
    title: String,
    hideCloseButton: Boolean
  },
  mounted() {
    this.openModal();
  },
  beforeDestroy() {
    this.closeModal();
  },
  computed: {
    highZoom() {
      return window.devicePixelRatio > 1;
    },
    titleClass() {
      let textSize =
        this.highZoom || this.$vuetify.breakpoint.smAndDown
          ? "text-h5"
          : " text-h4";
      if (this.$vuetify.breakpoint.smAndDown) {
        return `titleSmall ${textSize}`;
      }
      return `titleLarge ${textSize}`;
    },
    topperSize() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return this.highZoom
          ? "roundedDialogTopperLargeScreenHighZoom"
          : "roundedDialogTopperLargeScreen";
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return this.highZoom
          ? "roundedDialogTopperMediumScreenHighZoom"
          : "roundedDialogTopperMediumScreen";
      }

      return this.highZoom
        ? "roundedDialogTopperSmallScreenHighZoom"
        : "roundedDialogTopperSmallScreen";
    },
    bannerSize() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return this.highZoom ? "100px" : "150px";
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return this.highZoom ? "75px" : "125px";
      }

      return this.highZoom ? "75px" : "100px";
    }
  },
  methods: {
    ...mapMutations(["openModal", "closeModal"])
  }
};
</script>

<style scoped>
.dialogVisibility {
  box-shadow: none;
  overflow-y: visible;
}

.imagePosition {
  position: relative;
  left: 15%;
  top: 15%;
}

.titleSmall {
  position: relative;
  top: 45%;
}

.titleLarge {
  position: relative;
  top: 55%;
}

.roundedDialogTopper {
  position: absolute;
  left: 0;
  right: 0;
  background: linear-gradient(
    207deg,
    rgba(3, 126, 239, 1) 0%,
    rgba(1, 72, 253) 100%
  );
  border-radius: 50%;
  aspect-ratio: 1;
}

.roundedDialogTopperLargeScreen {
  margin: -60px auto auto;
  width: 120px;
}

.roundedDialogTopperMediumScreen {
  margin: -45px auto auto;
  width: 90px;
}

.roundedDialogTopperSmallScreen {
  margin: -32px auto auto;
  width: 75px;
}

.roundedDialogTopperLargeScreenHighZoom {
  margin: -40px auto auto;
  width: 80px;
}

.roundedDialogTopperMediumScreenHighZoom {
  margin: -35px auto auto;
  width: 70px;
}

.roundedDialogTopperSmallScreenHighZoom {
  margin: -30px auto auto;
  width: 60px;
}

.dialogMargin {
  margin-top: 8%;
}
</style>
