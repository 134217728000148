<template>
  <v-container>
    <end-of-report-sign-up
      assessmentType="Desk assessment"
      :assessmentId="assessmentId"
      :errorMessage="signUpErrorMessage"
    />
    <div
      style="
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
      "
    >
      <canvas
        id="confetti-canvas"
        class="sticky"
        style="z-index: 5; pointer-events: none"
        :width="reportWidth"
        :height="reportHeight"
      >
      </canvas>
    </div>

    <v-btn
      v-scroll="onScroll"
      v-show="toTopVisible"
      fab
      fixed
      bottom
      right
      color="tertiary"
      @click="toPageTop"
      dark
    >
      <i class="material-icons">arrow_upward</i>
    </v-btn>

    <v-row justify="center">
      <v-col cols="12"
        ><v-expansion-panels
          flat
          v-model="panelDefaultExpansion"
          multiple
          hover
          style="width: 100%"
          elevation="0"
        >
          <ReportPanel
            id="summaryPanel"
            :title="$t('deskAssessment.results.report.summaryTitle')"
            panelType="DeskAssessmentSummary"
            :results="results"
            assessmentType="deskassessment"
          />

          <ReportPanel
            id="painPanel"
            v-if="displayPainSection"
            :title="$t('deskAssessment.results.report.painTitle', { 0: '1' })"
            panelType="Pain"
            :results="results"
            assessmentType="deskassessment"
            :showPregnancyContent="showPregnancyContent"
          />
          <ReportPanel
            id="webcamPanel"
            v-if="displayWebcamPanel"
            :title="
              $t('deskAssessment.results.report.webcamTitle', {
                0: webcamIndex
              })
            "
            panelType="WebcamAssessment"
            :results="results"
            :score="webcamScore + '%'"
            :goodScore="webcamScore >= 50"
            v-on="$listeners"
            assessmentType="deskassessment"
          />
          <ReportPanel
            id="setupPanel"
            :title="
              $t('deskAssessment.results.report.workSetupTitle', {
                0: setupPanelIndex
              })
            "
            :score="`${setupScore}%`"
            :goodScore="setupScore >= 50"
            panelType="WorkSetup"
            :results="results"
            assessmentType="deskassessment"
          />
          <PregnancyContent
            id="pregnancyContent"
            v-if="showPregnancyContent && hasPregnancyInformationCards"
            class="mb-2"
            :results="results.health"
          />
          <ReportPanel
            id="recommendationsPanel"
            :title="$t('deskAssessment.results.report.recommendationsTitle')"
            panelType="PersonalisedDeskAssessmentRecommendations"
            :results="results"
            :assessmentId="assessmentId"
            assessmentType="deskassessment"
            :showConstantRecommendations="false"
            :showPregnancyContent="showPregnancyContent"
          />
          <ReportPanel
            id="fixedRecommendationsPanel"
            v-if="hasFixedRecommendations"
            :title="$t('deskAssessment.results.report.extraContentTitle')"
            panelType="FixedDeskAssessmentRecommendations"
            :results="results"
            :assessmentId="assessmentId"
            assessmentType="deskassessment"
            :showConstantRecommendations="true"
          /> </v-expansion-panels
      ></v-col>

      <v-col v-if="viewingOwnResults" cols="auto">
        <EndOfReportFooter
          id="endOfReportFooter"
          allowReportEmail
          :assessmentID="assessmentId"
          assessmentType="Desk assessment"
          @closeReport="$emit('closeReport')"
          :results="results"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

import ReportPanel from "@/components/common/report/ReportPanel";
import PregnancyContent from "@/components/deskassessment/report/content/PregnancyContent";
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
import {
  storeDeskAssessmentScore,
  updateLearningManagementSystemsOnAssessmentCompletion
} from "@/customApi";
import { getDeskFixedRecommendations } from "@/services/recommendations/recommendations-service.js";
import { mapGetters } from "vuex";
import EndOfReportSignUp from "@/components/authentication/EndOfReportSignUp";
import PregnancyReport from "@/services/deskassessment/pregnancy-report.js";
import EndOfReportFooter from "@/components/common/report/EndOfReportFooter.vue";
import { viewingOwnResults } from "@/services/privacy-service.js";

export default {
  name: "Report",
  props: {
    results: {
      type: Object
    },
    assessmentId: String,
    signUpErrorMessage: String
  },
  components: {
    ReportPanel,
    PregnancyContent,
    EndOfReportSignUp,
    EndOfReportFooter
  },
  data() {
    return {
      panelDefaultExpansion: [0, 1, 2, 3, 4, 5, 6],
      toTopVisible: false,
      reportHeight: 0,
      reportWidth: 0
    };
  },
  async beforeMount() {
    this.updateAssessmentScore();
  },

  async mounted() {
    this.toPageTop();
    this.$nextTick(() => {
      this.setupConfetti();
    });
  },
  computed: {
    ...mapGetters([
      "signedIn",
      "isSimpleUser",
      "customRecommendations",
      "redirectReportAssessment",
      "signedIn"
    ]),
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    },
    permissionToViewPersonalData() {
      if (!this.signedIn) {
        return true;
      }
      return this.viewingOwnResults;
    },
    showPregnancyContent() {
      return (
        this.permissionToViewPersonalData && this.results.health.pregnancy == 1
      );
    },
    hasPregnancyInformationCards() {
      return (
        PregnancyReport.getPregnancyInformationCards(this.results.health)
          .length > 0
      );
    },
    healthScore() {
      if (this.noHealthSection || this.showPregnancyContent) {
        return "";
      }
      return (
        DeskAssessmentUtil.getPercentageScore(
          DeskAssessmentUtil.calculateHealthScore(this.results),
          DeskAssessmentUtil.maxHealthScore
        ) + "%"
      );
    },
    goodHealthScore() {
      if (this.noHealthSection) {
        return false;
      }
      return DeskAssessmentUtil.calculateHealthScore(this.results) < 6;
    },
    noHealthSection() {
      return this.results.health.smoker === null || !this.results.health.bmi;
    },
    setupScore() {
      return DeskAssessmentUtil.calculateSetupAndHabitsPercentage(this.results);
    },
    webcamScore() {
      const score = DeskAssessmentUtil.getPercentageScore(
        DeskAssessmentUtil.calculateDeskSetupScore(this.results),
        DeskAssessmentUtil.getMaxWebcamScore(this.results)
      );
      return score;
    },
    displayWebcamPanel() {
      return this.results.webcam.pictureTaken;
    },
    displayPainSection() {
      return Object.keys(this.results.pain.areas).length > 0;
    },
    webcamIndex() {
      return this.displayPainSection ? "2" : "1";
    },
    setupPanelIndex() {
      return this.displayWebcamPanel && this.displayPainSection
        ? "3"
        : this.displayWebcamPanel || this.displayPainSection
        ? "2"
        : "1";
    },
    hasFixedRecommendations() {
      let recommendations = getDeskFixedRecommendations(
        this.customRecommendations
      );
      return recommendations && recommendations.length > 0;
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.toTopVisible = top > 20;
    },
    toPageTop() {
      this.$vuetify.goTo(0);
    },
    async updateAssessmentScore() {
      if (this.results.isCompleted) {
        return;
      }
      await this.saveScoreToDb();
      await this.updateLearningManagementSystems();
    },
    async saveScoreToDb() {
      const overallScore = DeskAssessmentUtil.getOverallScore(this.results);

      let setupScore = null;
      if (this.results.setup.deskItems.length > 0) {
        setupScore = Math.round(
          DeskAssessmentUtil.getVisualDeskPlannerScore(this.results.setup, 500)
        );
      }
      const scoreDto = {
        AssessmentId: this.assessmentId,
        Score: overallScore,
        SetupScore: setupScore
      };

      try {
        await storeDeskAssessmentScore(scoreDto);
      } catch (err) {}
    },
    async updateLearningManagementSystems() {
      if (window.opener) {
        window.opener.postMessage("completed", "*");
      }
      updateLearningManagementSystemsOnAssessmentCompletion(this.assessmentId);
    },
    setupConfetti() {
      const overallScore = DeskAssessmentUtil.getOverallScore(this.results);
      let selfAssessment = this.signedIn && !this.results.isCompleted;
      let justCompletedReport = selfAssessment || this.redirectReportAssessment;
      if (overallScore > 75 && justCompletedReport) {
        this.reportHeight = window.innerHeight;
        this.reportWidth = window.innerWidth;

        var particlesPerFrame = 2;
        if (this.$vuetify.breakpoint.smAndDown) {
          particlesPerFrame = 0.5;
        } else if (this.$vuetify.breakpoint.mdAndDown) {
          particlesPerFrame = 1;
        }

        this.$confetti.start({
          canvasElement: document.getElementById("confetti-canvas"),
          particlesPerFrame: particlesPerFrame,
          particles: [
            {
              type: "rect"
            }
          ]
        });

        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
      }
    }
  }
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Required for Safari */
  position: sticky;
  top: 0px;
}
</style>
