var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBanner &&
    _vm.teamMemberId &&
    !_vm.hideSeatInfoWarning &&
    !_vm.isTrialUser
    ? _c(
        "div",
        {
          staticClass: "pa-4",
          class: _vm.textSize,
          staticStyle: { "background-color": "#ffefd9" }
        },
        [
          _vm.showCloseButton
            ? _c(
                "v-btn",
                {
                  staticClass: "close-button",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.showBanner = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "font-weight-bold py-1",
                  attrs: { cols: "auto", "align-self": "center" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("totalSeatsWarning.percentageUsed", {
                        percentage: _vm.percentageUsed
                      })
                    )
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-1",
                  attrs: { cols: "auto", "align-self": "center" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tenOrMoreSeatsOver
                          ? _vm.$t("totalSeatsWarning.moreThanTenOver")
                          : _vm.$t("totalSeatsWarning.belowTenOver")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }