var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" }, style: _vm.containerStyle },
    [
      _c("v-row", { attrs: { justify: "center" } }, [
        _c(
          "div",
          {
            staticClass: "fulfilling-bouncing-circle-spinner",
            style: _vm.spinnerStyle
          },
          [
            _c("div", { staticClass: "circle", style: _vm.circleStyle }),
            _c("div", { staticClass: "orbit", style: _vm.orbitStyle })
          ]
        )
      ]),
      _c("div", { staticClass: "text-h5 primary--text title text-center" }, [
        _vm._v(_vm._s(_vm.text))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }