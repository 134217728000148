var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.icon
    ? _c(
        "v-icon",
        { class: _vm.animationClass, attrs: { size: _vm.iconSize } },
        [_vm._v(_vm._s(_vm.icon) + " ")]
      )
    : _c("v-img", {
        class: _vm.animationClass,
        attrs: {
          src: require("@/assets/images/" + _vm.imageSource),
          contain: "",
          height: _vm.height,
          width: _vm.width
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }