<template>
  <v-scale-transition>
    <v-btn
      :x-small="!isSmallScreen"
      depressed
      :ripple="false"
      :color="isSmallScreen ? 'red' : 'transparent'"
      :fixed="!absolute"
      :absolute="absolute"
      fab
      dark
      right
      :bottom="isSmallScreen"
      :style="buttonStyle"
      @click="$emit('input', true)"
    >
      <v-icon v-show="isSmallScreen">mdi-chat</v-icon>
      <v-chip
        v-show="!isSmallScreen"
        label
        color="red"
        text-color="white"
        :style="chipStyle"
        @click="$emit('input', true)"
      >
        {{ $t("feedback.feedbackButton") }}
        <v-icon right>mdi-chat</v-icon>
      </v-chip>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: "FeedbackButton",
  props: {
    absolute: Boolean
  },
  computed: {
    buttonStyle() {
      return "transform:rotate(" + [!this.isSmallScreen ? 90 : 0] + "deg);";
    },
    chipStyle() {
      return "transform:rotate(" + [!this.isSmallScreen ? 180 : 0] + "deg);";
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
};
</script>
