<template>
  <vue-glow style="width: 100%" :intensity="showExpired ? 1 : 0">
    <v-card
      :style="
        'border: 1px solid ' +
        borderColor +
        '!important; ' +
        'position: relative;'
      "
    >
      <v-btn class="close-button" icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-row align="center" justify="center" class="ma-0 pa-0">
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 4 : 2">
          <v-img
            max-height="75px"
            max-width="140px"
            contain
            :src="imageSource"
          ></v-img>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 8 : 7">
          <p class="text-h5 mb-0">{{ bannerTitle }}</p>
          <v-row v-if="!showExpired">
            <v-col>
              <p class="mb-0 mx-1">
                {{
                  $t("accountBanner.text.freeTierText.preButton", {
                    "0": currentUserTeamAndSubscriptionBasicInformation.freeTrialNumberOfDays,
                    "1":
                      currentUserTeamAndSubscriptionBasicInformation.usedAssessmentInvitationsCount +
                      "/" +
                      this.currentUserTeamAndSubscriptionBasicInformation
                        .freeTrialNumberOfAssessments
                  })
                }}
                <v-btn
                  small
                  outlined
                  color="primary"
                  class="mx-2"
                  @click="goToSubscription()"
                  >{{ $t("buttons.subscription") }}</v-btn
                >{{ $t("accountBanner.text.freeTierText.postButton") }}
              </p>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <p class="mb-0">
                <v-btn
                  small
                  color="primary"
                  outlined
                  class="mr-2"
                  @click="goToSubscription()"
                  >{{ $t("buttons.subscription") }}</v-btn
                >{{ $t("accountBanner.text.expiredText") }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 3">
          <v-row justify="end"
            ><v-btn
              v-if="showExtendTrialButton"
              class="tertiary--text mr-5"
              small
              color="tertiaryLight"
              @click="extendTrial()"
              >{{ $t("buttons.extendTrial") }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </vue-glow>
</template>

<script>
import { mapGetters } from "vuex";
import { extendTeamTrial } from "@/customApi";
import VueGlow from "vue-glow";

export default {
  name: "AccountBanner",
  components: {
    VueGlow
  },
  methods: {
    goToSubscription() {
      this.$router.push({
        name: "Subscription",
        params: {
          showDialogOnLoad: true,
          tabToShow: "SUBSCRIPTION"
        }
      });
    },
    async extendTrial() {
      try {
        await extendTeamTrial();
        this.$router.go(0);
      } catch (err) {}
    }
  },
  computed: {
    ...mapGetters([
      "currentUserTeamAndSubscriptionBasicInformation",
      "trialExpiredOrAssessmentsExceeded",
      "subscriptionHasExpired"
    ]),
    showExpired() {
      return (
        this.subscriptionHasExpired || this.trialExpiredOrAssessmentsExceeded
      );
    },
    bannerTitle() {
      let teamName = this.currentUserTeamAndSubscriptionBasicInformation.name;
      let team = teamName ? " " + teamName : "";

      return this.showExpired
        ? this.$t("accountBanner.titles.expired")
        : this.$t("accountBanner.titles.free", { 0: team });
    },
    imageSource() {
      return this.showExpired
        ? require("@/assets/images/subscriptionExpiredBanner.png")
        : require("@/assets/images/freeTrialWelcomeBanner.png");
    },
    borderColor() {
      return this.showExpired ? "red" : "transparent";
    },
    showExtendTrialButton() {
      return (
        this.currentUserTeamAndSubscriptionBasicInformation.accessTier ===
          "TrialExpired" &&
        !this.currentUserTeamAndSubscriptionBasicInformation.HasExtendedTrial
      );
    }
  }
};
</script>
