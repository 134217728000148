import { render, staticRenderFns } from "./BurnoutReport.vue?vue&type=template&id=dbca4ed2&scoped=true&"
import script from "./BurnoutReport.vue?vue&type=script&lang=js&"
export * from "./BurnoutReport.vue?vue&type=script&lang=js&"
import style0 from "./BurnoutReport.vue?vue&type=style&index=0&id=dbca4ed2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbca4ed2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VDialog,VExpansionPanels,VIcon,VRow})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dbca4ed2')) {
      api.createRecord('dbca4ed2', component.options)
    } else {
      api.reload('dbca4ed2', component.options)
    }
    module.hot.accept("./BurnoutReport.vue?vue&type=template&id=dbca4ed2&scoped=true&", function () {
      api.rerender('dbca4ed2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/burnout-assessment/report/BurnoutReport.vue"
export default component.exports