var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-glow",
    {
      staticStyle: { width: "100%" },
      attrs: { intensity: _vm.showExpired ? 1 : 0 }
    },
    [
      _c(
        "v-card",
        {
          style:
            "border: 1px solid " +
            _vm.borderColor +
            "!important; " +
            "position: relative;"
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-button",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("input", false)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "ma-0 pa-0",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? 4 : 2 } },
                [
                  _c("v-img", {
                    attrs: {
                      "max-height": "75px",
                      "max-width": "140px",
                      contain: "",
                      src: _vm.imageSource
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? 8 : 7 } },
                [
                  _c("p", { staticClass: "text-h5 mb-0" }, [
                    _vm._v(_vm._s(_vm.bannerTitle))
                  ]),
                  !_vm.showExpired
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              { staticClass: "mb-0 mx-1" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "accountBanner.text.freeTierText.preButton",
                                        {
                                          "0":
                                            _vm
                                              .currentUserTeamAndSubscriptionBasicInformation
                                              .freeTrialNumberOfDays,
                                          "1":
                                            _vm
                                              .currentUserTeamAndSubscriptionBasicInformation
                                              .usedAssessmentInvitationsCount +
                                            "/" +
                                            this
                                              .currentUserTeamAndSubscriptionBasicInformation
                                              .freeTrialNumberOfAssessments
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      small: "",
                                      outlined: "",
                                      color: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.goToSubscription()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("buttons.subscription"))
                                    )
                                  ]
                                ),
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "accountBanner.text.freeTierText.postButton"
                                    )
                                  ) + " "
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              { staticClass: "mb-0" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      small: "",
                                      color: "primary",
                                      outlined: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.goToSubscription()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("buttons.subscription"))
                                    )
                                  ]
                                ),
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("accountBanner.text.expiredText")
                                  ) + " "
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 3 } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _vm.showExtendTrialButton
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "tertiary--text mr-5",
                              attrs: { small: "", color: "tertiaryLight" },
                              on: {
                                click: function($event) {
                                  return _vm.extendTrial()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("buttons.extendTrial")) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }