var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("div", { staticClass: "text-h5" }, [
          _vm._v(_vm._s(_vm.$t("analytics.tabs.analytics")))
        ])
      ]),
      _c(
        "v-row",
        { staticClass: "d-flex", attrs: { justify: "space-between" } },
        _vm._l(_vm.cards, function(card) {
          return _c(
            "v-col",
            { key: card.primaryText, attrs: { cols: "6", sm: "6", md: "3" } },
            [
              _c("analytics-tile", {
                attrs: {
                  primaryLoaded: _vm.loaded,
                  secondaryLoaded: _vm.loaded,
                  primaryNumberValue: card.primaryNumberValue,
                  primaryTextValue: card.primaryTextValue,
                  primaryText: card.primaryText,
                  secondaryValue: card.secondaryValue,
                  secondaryText: card.secondaryText,
                  secondaryColour: card.secondaryColour,
                  primaryPercentage: card.primaryPercentage,
                  secondaryPercentage: card.secondaryPercentage,
                  reverseSecondaryColours: card.reverseSecondaryColours,
                  showSecondary: card.showSecondary,
                  toolTipText: card.toolTipText
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }