var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "black" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "pa-12",
          attrs: { "no-gutters": "", justify: "center" }
        },
        [
          _c("v-img", {
            attrs: {
              height: "200px",
              contain: "",
              src: require("@/assets/images/undraw_no_data.svg")
            }
          }),
          _c("v-card-title", { staticClass: "mt-5 mb-2" }, [
            _vm._v(
              _vm._s(
                _vm.$t("dashboardHistoryComponents.restrictedAssessment.title")
              )
            )
          ]),
          _c("v-card-subtitle", { staticClass: "mb-3" }, [
            _vm._v(" " + _vm._s(_vm.bodyText) + " ")
          ]),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { width: "100%", rounded: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.$router.push("/analytics", function() {})
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.analytics")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }