var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", "no-gutters": "" } },
    _vm._l(_vm.totalSteps, function(step, index) {
      return _c("div", {
        key: index,
        staticClass: "dot mx-3",
        style: _vm.stepperColor(index),
        attrs: { id: "step" + index }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }