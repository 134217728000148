import {
  getActiveFeatureIntroductions,
  setFeatureIntroItemComplete
} from "@/customApi";
import store from "@/store";
import _ from "lodash";

export async function processAdminChecklist(completedItem) {
  let intros = await getActiveFeatureIntroductions();
  let checklist = _.find(
    intros,
    intro => intro.series === "AdminOnboardingTour"
  );

  if (!checklist?.items || checklist.items[completedItem] === true) return;

  let updatedIntros = await setFeatureIntroItemComplete(
    checklist.id,
    checklist.items[completedItem].id
  );

  updateCheckListFromFeatures(updatedIntros);
  store.commit("setFeatureIntros", updatedIntros);
}

export async function updateCheckListFromFeatures(features) {
  let checklist = _.find(
    features,
    intro =>
      (intro.series === "AdminOnboardingTour" ||
        intro.series === "AdminOnboardingTourCompletion") &&
      !intro.completed
  );

  store.commit("setOnboardingChecklist", checklist);
}
