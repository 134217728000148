var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      style: "z-index: " + (_vm.endUserTourOpen ? 10001 : ""),
      attrs: {
        app: "",
        color: "white",
        "clipped-left": "",
        "hide-on-scroll": _vm.$vuetify.breakpoint.smAndDown,
        value: true
      }
    },
    [
      _c("v-app-bar-nav-icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showSidebarBurgerMenu,
            expression: "showSidebarBurgerMenu"
          }
        ],
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$emit("input", !_vm.value)
          }
        }
      }),
      _c("v-img", {
        staticClass: "mr-4",
        attrs: {
          src: require("@/assets/images/VitrueVidaLogoWithText.png"),
          "max-height": "70",
          "max-width": "200",
          contain: ""
        }
      }),
      _c("v-spacer"),
      _vm.showLanguageDropDown
        ? _c("LanguageSelection", { staticClass: "my-2" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }