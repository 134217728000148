import DeskRecommendationCardsJson from "@/assets/json/DeskAssessment/RecommendationCards.json";
import DeskBupaRecommendationCardsJson from "@/assets/json/DeskAssessment/CustomisedRecommendations/BupaRecommendationCards.json";
import { DefaultExerciseRecommendations } from "@/services/recommendations/deskassessment/recommendations.js";
import i18n from "@/plugins/i18n.js";
import router from "@/router";
import * as Recommendations from "@/services/recommendations/recommendation-permissions.js";
import sentry from "@/sentry";
import { WorkSpacePriority } from "../../constants/constants";

export function getBurnoutRecommendations(scores, fullResults, featureFlags) {
  let recommendations =
    Recommendations.GetBurnoutAssessmentRecommendationCards(featureFlags);
  Object.keys(recommendations).forEach(category => {
    if (scores[category] === 1) {
      recommendations[category] = [];
    } else {
      let filteredCards = filterCards(
        recommendations[category],
        scores,
        fullResults
      );
      recommendations[category] = filteredCards;
    }
  });
  return assignCardImpactsAndRemoveRepeats(recommendations);
}

export function getBurnoutFixedRecommendations(featureFlags) {
  return Recommendations.getFixedBurnoutCards(featureFlags);
}

export function getDeskFixedRecommendations(featureFlags) {
  return Recommendations.getFixedDeskCards(featureFlags);
}

export function getSetupRecommendations(results, featureFlags) {
  const cards =
    Recommendations.GetDeskAssessmentRecommendationCards(featureFlags);
  var filteredCards = filterDeskCards(cards, results, featureFlags);
  filteredCards.sort(sortByImpactAndPriority);
  return checkForDuplicates(filteredCards);
}

export function getExerciseRecommendations(results, featureFlags) {
  let json =
    featureFlags.customRecommendations_ykavx &&
    featureFlags.pregnancyExerciseVideos == false
      ? DeskBupaRecommendationCardsJson
      : DeskRecommendationCardsJson;

  let cards = DefaultExerciseRecommendations(json);

  return filterDeskCards(Object.values(cards), results, featureFlags);
}

export function displayedExplanationText(card) {
  if (card.json.customText) {
    return i18n.t(card.json.explanation, {
      customText: card.json.customText
    });
  }
  return i18n.t(card.json.explanation);
}

export function sortRecommendationsByCost(recommendations, cost) {
  return recommendations.filter(card => {
    return (
      (cost === "budget" && card.json.budget) ||
      (cost === "upgrade" && card.json.upgrade) ||
      (!card.json.budget && !card.json.upgrade)
    );
  });
}

function filterDeskCards(cards, results, featureFlags) {
  return cards.filter(card => {
    try {
      var show =
        (!card.showAnswer || card.showAnswer(results, featureFlags)) &&
        (!card.showOnPage || card.showOnPage(router.app._route));
      return show;
    } catch (err) {
      sentry.captureException(err);
      return false;
    }
  });
}

function sortByImpactAndPriority(a, b) {
  // If either object has a workspacepriority of MAX, it should come first
  if (b.workSpacePriority === WorkSpacePriority.MAX) {
    return 1;
  }

  if (a.workSpacePriority === WorkSpacePriority.MAX) {
    return -1;
  }

  // If either object has no scores or workSpacePriority property, it should not move
  var workspaceValues = Object.values(WorkSpacePriority);
  var aHasScore = a.scores && workspaceValues.includes(a.workSpacePriority);
  var bHasScore = b.scores && workspaceValues.includes(b.workSpacePriority);

  if (!aHasScore || !bHasScore) {
    return 0;
  }

  const aAverage = averageOfValues(a.scores);
  const bAverage = averageOfValues(b.scores);

  // Sort based on average scores
  if (aAverage !== bAverage) {
    return bAverage - aAverage;
  }

  // If the average scores are the same, sort based on workSpacePriority
  return a.workSpacePriority - b.workSpacePriority;
}

function averageOfValues(obj) {
  var values = Object.values(obj);
  var sum = values.reduce((a, b) => a + b, 0);
  return Math.round((sum / values.length) * 100) / 100;
}

function checkForDuplicates(cards) {
  var typeSet = new Set();

  return cards.filter(card => {
    if (!card.type) {
      return true;
    }
    var newType = !typeSet.has(card.type);
    if (newType) {
      typeSet.add(card.type);
      return true;
    } else {
      sentry.captureMessage(
        `Duplicate card type, type = ${card.type}`,
        "error"
      );
      return false;
    }
  });
}

function filterCards(cards, scores, fullResults) {
  let filteredCards = Object.entries(cards)
    .filter(([, details]) => {
      try {
        var show =
          !details.showAnswer || details.showAnswer(scores, fullResults);
        return show;
      } catch (err) {
        sentry.captureException(err);
        return false;
      }
    })
    .map(([, value]) => value);

  filteredCards.sort((a, b) => {
    return a.getPriority(fullResults) - b.getPriority(fullResults);
  });
  return filteredCards;
}

function assignCardImpactsAndRemoveRepeats(recommendations) {
  // Get all unique subtypes of cards (e.g. exercise)
  let allCardTypes = Object.values(recommendations)
    .flat()
    .map(x => x.json.subType);
  let uniqueTypes = [...new Set(allCardTypes)];
  let typeCounts = {};

  // Get number of occurences of subtype
  uniqueTypes.forEach(type => {
    typeCounts[type] = allCardTypes.filter(x => x === type).length;
  });

  replaceRepeatsWithCardAndImpact(recommendations, typeCounts);
  return recommendations;
}

function replaceRepeatsWithCardAndImpact(recommendations, subTypeCounts) {
  Object.keys(subTypeCounts).forEach(type => {
    let count = subTypeCounts[type];
    if (count <= 1) {
      return;
    }

    let categoryToAddImpact = getMinimumLengthCategoryContainingSubType(
      recommendations,
      type
    );

    let cardToAddImpact = findCategoryCardBySubType(
      recommendations,
      categoryToAddImpact,
      type
    );
    cardToAddImpact.impact = count;

    removeRepetionsFromRecommendations(
      recommendations,
      type,
      categoryToAddImpact
    );
  });
}

function findCategoryCardBySubType(recommendations, key, subType) {
  return recommendations[key].find(card => card.json.subType === subType);
}

function getMinimumLengthCategoryContainingSubType(recommendations, subType) {
  let categoriesWithType = Object.keys(recommendations).filter(
    key => !!findCategoryCardBySubType(recommendations, key, subType)
  );
  let categoryLengths = categoriesWithType.map(
    key => recommendations[key].length
  );
  let min = Math.min.apply(Math, categoryLengths);
  let index = categoryLengths.indexOf(min);
  return categoriesWithType[index];
}

function removeRepetionsFromRecommendations(
  recommendations,
  subType,
  exemptCategory
) {
  Object.keys(recommendations).forEach(category => {
    if (category === exemptCategory) {
      return;
    }
    let cardToRemove = findCategoryCardBySubType(
      recommendations,
      category,
      subType
    );
    if (cardToRemove) {
      let indexToRemove = recommendations[category].indexOf(cardToRemove);
      recommendations[category].splice(indexToRemove, 1);
    }
  });
}
