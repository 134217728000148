import store from "@/store";
import { updateCheckListFromFeatures } from "./onboarding/adminOnboarding.js";
import { find } from "lodash";
import {
  setFeatureIntroItemComplete,
  getActiveFeatureIntroductions
} from "@/customApi";

function markedSeenInLocalStorage(key) {
  var legacyKey;
  switch (key) {
    case "viewUserDashboard":
      legacyKey = "userDashboardSeen";
      break;
    case "viewPainManagement":
      legacyKey = "painManagementSeen";
      break;
  }

  var seen = !!legacyKey && !!localStorage.getItem(legacyKey);
  if (seen) localStorage.removeItem(legacyKey);

  return seen;
}

export default {
  async showFeature(key) {
    let featureIntros = store.getters.featureIntros;
    let onboardingShown = store.getters.showOnboardingChecklist;
    let uiHighlightIntro = find(
      featureIntros,
      intro => intro.series === "NewUIHighlight"
    );

    if (!uiHighlightIntro || onboardingShown) return false;

    let item = uiHighlightIntro.items[key];

    if (!item?.id || item.completed) {
      return false;
    }

    if (markedSeenInLocalStorage(key)) {
      await this.markFeatureSeen(key, true);
      return false;
    }

    return true;
  },
  async markFeatureSeen(key, force) {
    let featureIntros = store.getters.featureIntros;
    let uiHighlightIntro = find(
      featureIntros,
      intro => intro.series === "NewUIHighlight"
    );

    if (!uiHighlightIntro?.id) return;

    let item = uiHighlightIntro.items[key];

    if (!item?.id) return;

    let updated = await setFeatureIntroItemComplete(
      uiHighlightIntro.id,
      item.id
    );
    if (force) {
      store.commit("setFeatureIntros", updated);
    }
  },
  async getActiveFeatureIntros() {
    var featureIntros = null;
    try {
      featureIntros = await getActiveFeatureIntroductions();
      await updateCheckListFromFeatures(featureIntros);
    } catch (error) {
      //suppress, already in Sentry
    } finally {
      store.commit("setFeatureIntros", featureIntros);
    }
  }
};
