import TeamService from "@/services/teamService.js";
import {
  checkHasSubscriptionAndSetCustomerId,
  recordEndUserLogin,
  recordTeamMemberLogin,
  getActiveTeamForEndUserEmail
} from "@/customApi";
import { getAssessmentResult } from "@/services/modules/api-queries";

async function getRedirectDestination(teamMemberInfo, store) {
  let assessmentRedirect = store.getters.redirectReportAssessment;
  if (assessmentRedirect) {
    return await GetAssessmentRedirect(assessmentRedirect, store);
  }
  if (store.getters.isSimpleUser) {
    return await GetEndUserRedirect(teamMemberInfo, store);
  }
  return await GetTeamMemberRedirect(teamMemberInfo, store);
}

async function GetAssessmentRedirect(redirect, store) {
  let type = redirect.assessmentType;
  let id = redirect.id;
  let results = await getAssessmentResult(id, type);
  let name =
    type === "Desk assessment" ? "DeskAssessment" : "BurnoutAssessment";
  let route = {
    name: name,
    params: {
      assessmentId: id,
      loadedResults: results
    }
  };
  store.commit("setRedirectAssessment", null);
  return route;
}

function GetWellnessRedirect(store) {
  if (store.getters.redirectToWellness) {
    store.commit("setWellnessRedirect", false);
  }

  return "Wellness";
}

async function GetEndUserRedirect(teamMemberInfo, store) {
  if (teamMemberInfo.endUserSignedUpToTeam) {
    return { name: GetWellnessRedirect(store) };
  }
  let availableTeams = await getActiveTeamForEndUserEmail();
  if (availableTeams?.teams.length > 0) {
    return {
      name: "JoinTeam",
      params: { endUserTeams: availableTeams.teams }
    };
  } else {
    return { name: "CreateTeam" };
  }
}

async function GetTeamMemberRedirect(teamMemberInfo, store) {
  store.commit("setTeamMemberId", teamMemberInfo.teamMemberId);
  if (teamMemberInfo.teamsToJoinRequested.length > 0) {
    return {
      name: "ChooseTeam",
      params: { teams: teamMemberInfo.teamsToJoinRequested }
    };
  }
  await checkHasSubscriptionAndSetCustomerId();

  let wellnessRedirect = store.getters.redirectToWellness;
  let assessmentRedirect = store.getters.redirectReportAssessment;
  var name =
    assessmentRedirect || wellnessRedirect
      ? GetWellnessRedirect(store)
      : "Dashboard";
  return { name };
}

function recordLogins(destination, store) {
  let notJoinOrCreateTeam =
    destination?.name !== "JoinTeam" && destination?.name !== "CreateTeam";
  if (notJoinOrCreateTeam) {
    if (store.getters.isSimpleUser) {
      recordEndUserLogin();
    } else {
      recordTeamMemberLogin();
    }
  }
}

export async function completeUserSignInWithoutRedirect(store) {
  recordLogins(null, store);
}

export async function completeUserSignIn(store, router) {
  let teamMemberInfo = await TeamService.getTeamMemberInfo();
  let destination = await getRedirectDestination(teamMemberInfo, store);
  recordLogins(destination, store);
  if (!destination) {
    throw Error("No router destination found");
  }
  router.push(destination).catch(() => {});
}
