var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0" },
    [
      _c(
        "v-row",
        {
          class: _vm.reportPageSignUp ? "pa-5" : "",
          attrs: { justify: "center", "no-gutters": "" }
        },
        [
          !_vm.passwordReset
            ? _c(
                "v-col",
                {
                  staticClass: "text-center mb-6",
                  class: _vm.reportPageSignUp ? "text-h4" : "text-h2",
                  attrs: { cols: "12" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("authentication.confirmCode.title")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          !_vm.passwordReset
            ? _c(
                "v-col",
                {
                  staticClass:
                    "text-body-1 font-weight-medium vitrueGrey--text text-center",
                  attrs: { cols: "10" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("authentication.confirmCode.information")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: _vm.columnWidth } },
            [
              _c(
                "v-form",
                {
                  ref: "codeForm",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.codeValid,
                    callback: function($$v) {
                      _vm.codeValid = $$v
                    },
                    expression: "codeValid"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mt-10",
                    attrs: {
                      label: _vm.$t("authentication.confirmCode.labels.code"),
                      filled: "",
                      "prepend-inner-icon": "mdi-ticket-confirmation",
                      rules: [_vm.rules.codeRequired],
                      "success-messages":
                        _vm.userConfirmed && !!_vm.code
                          ? _vm.$t("authentication.confirmCode.confirmed")
                          : "",
                      "error-messages":
                        !_vm.userConfirmed && !!_vm.code && !_vm.passwordReset
                          ? _vm.$t("authentication.confirmCode.invalid")
                          : null,
                      "hide-details": "auto"
                    },
                    on: {
                      input: function($event) {
                        return _vm.confirmUser()
                      }
                    },
                    model: {
                      value: _vm.code,
                      callback: function($$v) {
                        _vm.code = $$v
                      },
                      expression: "code"
                    }
                  })
                ],
                1
              ),
              _c("v-hover", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-none text-body-1 my-3",
                            class: hover ? "text-decoration-underline" : "",
                            attrs: {
                              text: "",
                              color: "primary",
                              width: "100%",
                              loading: _vm.resendingEmail,
                              small: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.resendConfirmationCode()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "authentication.confirmCode.resendCode"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm.errorMessage
                ? _c("alert-message", {
                    attrs: { message: _vm.errorMessage, type: "error" }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.passwordReset
            ? _c(
                "v-col",
                { attrs: { cols: _vm.columnWidth } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "newPasswordForm",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      },
                      model: {
                        value: _vm.passwordValid,
                        callback: function($$v) {
                          _vm.passwordValid = $$v
                        },
                        expression: "passwordValid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          autocomplete: "new-password",
                          type: "password",
                          label: _vm.$t(
                            "authentication.confirmCode.labels.newPassword"
                          ),
                          filled: "",
                          "prepend-inner-icon": "mdi-lock",
                          rules: [
                            _vm.rules.passwordRequired,
                            _vm.rules.passwordAtLeast8Characters,
                            _vm.rules.passwordContainsNumber,
                            _vm.rules.passwordContainsSpecialCharacter,
                            _vm.rules.passwordContainsBothCases
                          ]
                        },
                        model: {
                          value: _vm.newPassword,
                          callback: function($$v) {
                            _vm.newPassword = $$v
                          },
                          expression: "newPassword"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "100%",
                        color: "primary",
                        loading: _vm.resettingPassword
                      },
                      on: {
                        click: function($event) {
                          return _vm.resetPassword()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.confirm")) + " ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }