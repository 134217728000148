export function isGuidValid(guid) {
  var regex =
    /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/;
  return regex.test(guid) && guid !== "00000000-0000-0000-0000-000000000000";
}

export class AuthException extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthException";
  }
}
