var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ribbon" },
    [
      _c(
        "v-row",
        {
          staticClass: "imagePosition",
          attrs: { align: "center", justify: "center", "no-gutters": "" }
        },
        [
          _c("v-img", {
            class: _vm.showIconOnly ? "" : "mr-2",
            attrs: {
              contain: "",
              "max-width": "60px",
              "max-height": "60px",
              src: require("@/assets/images/" + _vm.image)
            }
          }),
          !_vm.showIconOnly
            ? _c("p", { staticClass: "text-h4 white--text ma-0" }, [
                _vm._v(" " + _vm._s(_vm.text) + " ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }