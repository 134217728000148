<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      v-if="$route.name !== '404'"
      color="white"
      mini-variant
      mini-variant-width="100px"
      clipped
      app
      touchless
      :permanent="$vuetify.breakpoint.mdAndUp"
      height="100%"
      style="overflow: visible"
    >
      <v-list class="py-3" flat :dense="useDenseList">
        <v-list-item-group
          v-model="topTab"
          color="primary"
          :mandatory="topTab >= 0"
        >
          <v-list-item
            v-for="item in topTabs"
            :key="item.key"
            link
            class="py-2 zoom"
            :disabled="showDummySidebar"
            v-show="item.show"
            @click="item.key === 'Help' ? openHelpPage() : navigate(item)"
            :data-cy="item.key"
          >
            <div style="position: relative" :id="item.key">
              <v-list-item-content>
                <v-list-item-icon class="v-list_centered_content">
                  <v-icon :disabled="showDummySidebar" x-large>{{
                    item.icon
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  style="font-size: 12px"
                  class="v-list_centered_content text-caption wrap-text"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-content>
              <vitrue-onboarding-tooltip
                v-show="showOnboardingTeamDataToolTip(item.key)"
                style="z-index: 1"
                position="right"
                :text="$t('onboarding.adminCheckList.tooltips.viewTeamData')"
                :width="250"
              />
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list :dense="useDenseList" flat>
          <v-list-item-group
            v-model="bottomTab"
            :mandatory="bottomTab >= 0"
            color="primary"
          >
            <v-list-item
              v-for="item in bottomTabs"
              :key="item.key"
              v-show="item.show"
              :disabled="showDummySidebar"
              link
              class="py-2 zoom"
            >
              <div style="position: relative">
                <v-menu
                  top
                  offset-x
                  v-if="item.key === 'UserDropDown'"
                  nudge-bottom="1-px"
                  nudge-right="10px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-img
                        v-if="!avatarError"
                        class="avatar"
                        :src="avatarURL"
                        height="40px"
                        width="40px"
                        contain
                        @error="avatarError = true"
                      />
                      <v-icon v-else x-large :color="isAccount ? 'primary' : ''"
                        >mdi-account-circle</v-icon
                      >
                    </div>
                  </template>

                  <v-list dense class="py-0">
                    <v-list-item
                      v-for="item in userDropDownList"
                      :key="item.key"
                      @click="dropDownItemClicked(item)"
                    >
                      <v-list-item-title
                        ><v-icon class="pb-1 mr-2">{{ item.icon }}</v-icon
                        >{{ item.title }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-list-item-content v-else @click="bottomTabClicked(item)">
                  <v-list-item-icon class="v-list_centered_content">
                    <v-icon x-large>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    style="font-size: 12px"
                    class="text-caption wrap-text"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-content>
                <vitrue-new-feature
                  v-if="item.key === 'Wellness' && showUserDashPopup"
                  v-model="userDashPopup"
                  id="viewUserDashboard"
                  :text="$t('tooltips.newFeatures.userDashboard')"
                  :width="$vuetify.breakpoint.xs ? 250 : 360"
                  style="z-index: 1"
                />
                <vitrue-onboarding-tooltip
                  v-if="showOnboardingPainCoachToolTip(item.key)"
                  position="right"
                  :text="$t('onboarding.adminCheckList.tooltips.viewPainCoach')"
                  :width="250"
                  style="z-index: 1"
                />
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters, mapActions } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import VitrueNewFeature from "@/components/common/VitrueNewFeature.vue";
import VitrueOnboardingTooltip from "@/components/common/VitrueOnboardingTooltip";
import NewFeatureService from "@/services/new-feature-service.js";
import RestApiClient from "@/infrastructure/restApiClient";

export default {
  name: "side-bar",
  components: {
    VitrueTooltip,
    VitrueNewFeature,
    VitrueOnboardingTooltip
  },
  props: {
    value: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      topTab: undefined,
      bottomTab: undefined,
      drawer: null,
      userDashPopup: true,
      showUserDashPopup: false,
      avatarError: false
    };
  },
  created() {
    let path = this.$route.path;
    let matchedTopTab = this.topTabs.findIndex(x => x.path === path);
    let matchedBottomTab = this.bottomTabs.findIndex(x => x.path === path);

    if (matchedTopTab >= 0) {
      this.topTab = matchedTopTab;
    } else if (matchedBottomTab >= 0) {
      this.bottomTab = matchedBottomTab;
    }
  },
  methods: {
    ...mapActions(["resetLoginState"]),
    signOut() {
      let enterpriseSSO =
        this.teamIdpName?.toLowerCase().includes("azure") ||
        this.teamIdpName?.toLowerCase().includes("okta") ||
        this.teamIdpName?.toLowerCase().includes("google");
      if (this.ssoSignIn && enterpriseSSO) {
        sessionStorage.setItem("ssoSignOut", "true");
        sessionStorage.setItem("teamId", this.teamId);
        sessionStorage.setItem("idpName", this.teamIdpName);
      }
      localStorage.removeItem(RestApiClient.sessionHeaderKey);
      Auth.signOut().then(() => {
        this.resetLoginState();
        this.$router.push("/", () => {});
      });
    },
    navigate(item) {
      if (
        item.path === "/wellness" &&
        this.$route.path.includes("deskasssessment")
      ) {
        this.$mixpanel.track(`Go to dashboard after Desk assessment`, {
          fromSidebar: true
        });
      }
      this.$router.push(item.path, () => {});
    },
    openHelpPage() {
      this.$gtag.event("Open help page", {
        event_category: "Sidebar"
      });
      window.open("https://www.vitrueremote.com/faqs", "_blank");
    },
    bottomTabClicked(item) {
      if (item.key === "Wellness" && !this.showOnboardingChecklist) {
        this.userDashPopup = false;
      }
      this.navigate(item);
    },
    dropDownItemClicked(item) {
      if (item.key === "Logout") {
        this.signOut();
        return;
      }
      if (item.key === "Help") {
        this.openHelpPage();
        return;
      }
      this.navigate(item);
    },
    showOnboardingPainCoachToolTip(key) {
      return (
        key === "Wellness" &&
        this.onboardingChecklist.hoveredItem === "viewPainCoach"
      );
    },
    showOnboardingTeamDataToolTip(key) {
      return (
        key === "Analytics" &&
        this.onboardingChecklist.hoveredItem === "viewTeamData"
      );
    },
    async determineTooltipsToShow() {
      let shouldShowUserDash = await NewFeatureService.showFeature(
        "viewUserDashboard"
      );

      this.showUserDashPopup =
        shouldShowUserDash && this.userDashPopup && !this.modalOpen;
    }
  },
  computed: {
    ...mapGetters([
      "isSimpleUser",
      "userEmail",
      "isSuperAdmin",
      "teamId",
      "teamIdpName",
      "ssoSignIn",
      "onboardingChecklist",
      "modalOpen",
      "showOnboardingChecklist",
      "featureIntros",
      "showDummySidebar"
    ]),
    avatarURL() {
      return (
        "https://avatars.dicebear.com/v2/initials/" + this.userEmail + ".svg"
      );
    },
    isAccount() {
      return this.$route.path && this.$route.path.includes("account");
    },
    topTabs() {
      return [
        {
          show: true,
          key: "Dashboard",
          path: this.isSimpleUser ? "/wellness" : "/dashboard",
          icon: "grid_view",
          title: this.$t("layout.sidebar.dashboard")
        },
        {
          show: !this.isSimpleUser && !this.showDummySidebar,
          key: "Analytics",
          path: "/analytics",
          icon: "mdi-percent-outline",
          title: this.$t("layout.sidebar.analytics")
        },
        {
          show: !this.isSimpleUser && !this.showDummySidebar,
          key: "Regulatory",
          path: "/certification",
          icon: "mdi-license",
          title: this.$t("layout.sidebar.regulatory")
        }
      ];
    },
    bottomTabs() {
      return [
        {
          show: !this.isSimpleUser && !this.showDummySidebar,
          key: "Wellness",
          path: "/wellness",
          icon: "mdi-home-account",
          title: this.$t("layout.sidebar.wellness")
        },
        {
          show: true,
          key: "UserDropDown"
        }
      ];
    },
    userDropDownList() {
      return [
        {
          key: "Settings",
          icon: "mdi-cog-outline",
          title: this.$t("layout.sidebar.settings"),
          path: "/account"
        },
        {
          key: "Help",
          icon: "help_outline",
          title: this.$t("layout.sidebar.help")
        },
        {
          key: "Logout",
          icon: "exit_to_app",
          title: this.$t("layout.sidebar.logout")
        }
      ];
    },
    highestVisibleTabIndex() {
      let lastVisibleTab = this.topTabs.filter(x => x.show).pop();
      return this.topTabs.indexOf(lastVisibleTab);
    },
    useDenseList() {
      return !this.isSimpleUser;
    }
  },
  watch: {
    $route(to) {
      this.topTab = this.topTabs.findIndex(x => to.fullPath.includes(x.path));
      this.bottomTab =
        this.topTab >= 0
          ? undefined
          : this.bottomTabs.findIndex(x => to.fullPath.includes(x.path));
    },
    value() {
      this.drawer = this.value;
    },
    drawer() {
      this.$emit("input", this.drawer);
    },
    topTab(newVal) {
      if (newVal > this.highestVisibleTabIndex) {
        this.topTab = undefined;
      } else if (newVal >= 0) {
        this.bottomTab = undefined;
      }
    },
    bottomTab(newVal) {
      if (newVal >= 0) {
        this.topTab = undefined;
      }
    },
    async featureIntros() {
      await this.determineTooltipsToShow();
    },
    async showOnboardingChecklist() {
      await this.determineTooltipsToShow();
    },
    async modalOpen() {
      await this.determineTooltipsToShow();
    }
  }
};
</script>

<style scoped>
.v-list_centered_content {
  justify-content: center !important;
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
}

.wrap-text {
  text-align: center;
  white-space: normal;
}

.avatar {
  border-radius: 50%;
}

.zoom {
  transition: all 0.2s ease-in-out;
}
.zoom:hover {
  transform: scale(1.2);
}
</style>
