var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("top-bar", {
        staticClass: "no-print",
        attrs: { showSideBar: _vm.showSideBar },
        model: {
          value: _vm.toggleSideBar,
          callback: function($$v) {
            _vm.toggleSideBar = $$v
          },
          expression: "toggleSideBar"
        }
      }),
      _vm.showSideBar
        ? _c("side-bar", {
            staticClass: "no-print",
            model: {
              value: _vm.toggleSideBar,
              callback: function($$v) {
                _vm.toggleSideBar = $$v
              },
              expression: "toggleSideBar"
            }
          })
        : _vm._e(),
      _c("v-main", [_c("router-view")], 1),
      _vm.showOnboardingChecklist && _vm.showSideBar
        ? _c("AdminChecklist", { staticClass: "checkList" })
        : _vm._e(),
      _c("v-footer", {
        staticStyle: { "min-height": "50px", background: "transparent" }
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.activeAsyncRequestsCount, "z-index": "1000" } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      ),
      _c(
        "cookie-law",
        {
          attrs: {
            theme: "personalisedCookieTheme",
            buttonText: _vm.$t("cookieBar.okButton"),
            buttonLink: "https://www.vitrueremote.com/privacy",
            buttonLinkText: _vm.$t("cookieBar.policy"),
            buttonLinkNewTab: ""
          }
        },
        [
          _c("div", { attrs: { slot: "message" }, slot: "message" }, [
            _vm._v(" " + _vm._s(_vm.$t("cookieBar.message")) + " ")
          ])
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            top: "",
            color: "primary",
            timeout: "-1",
            transition: "fade-transition",
            "content-class": "text-center"
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", outlined: "", text: "" },
                        on: { click: _vm.refreshPage }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" " + _vm._s(_vm.$t("newVersion.refresh")) + " ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showRefreshBar,
            callback: function($$v) {
              _vm.showRefreshBar = $$v
            },
            expression: "showRefreshBar"
          }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { icon: "", small: "", color: "white" },
              on: {
                click: function($event) {
                  return _vm.setShowRefreshBar(false)
                }
              }
            },
            [_c("v-icon", [_vm._v("clear")])],
            1
          ),
          _vm._v(" " + _vm._s(_vm.$t("newVersion.text")) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }