var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSignUpDialog && !_vm.signedIn
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px", persistent: "" },
          model: {
            value: _vm.showSignUpDialog,
            callback: function($$v) {
              _vm.showSignUpDialog = $$v
            },
            expression: "showSignUpDialog"
          }
        },
        [
          _c("sign-up", {
            attrs: {
              reportPageSignUp: "",
              errorMsg: _vm.errorMessage,
              assessmentId: _vm.assessmentId,
              fromLinkGenerator: !_vm.userEmail
            },
            on: { regularSignInComplete: _vm.closeDialog }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }