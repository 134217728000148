var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-2" },
    [
      _c("Ribbon", {
        attrs: {
          id: "pregnancyRibbon",
          image: "parentalYoga.png",
          text: _vm.$t("deskAssessment.report.pregnancyRibbonTitle")
        }
      }),
      _c(
        "v-row",
        { attrs: { justify: "start" } },
        _vm._l(_vm.cardsToShow, function(card, cardIndex) {
          return _c(
            "v-col",
            { key: cardIndex, attrs: { cols: "12", lg: "4" } },
            [
              _c("InformationCard", {
                staticClass: "fill-height",
                attrs: {
                  id: card.id,
                  title: _vm.$t(card.title),
                  sideHeader: false,
                  color: _vm.$vuetify.theme.currentTheme.success,
                  imageUrl: card.image,
                  text: [_vm.$t(card.text)],
                  verticalAlignment: "align-start"
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }