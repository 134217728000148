<template>
  <div class="ribbon">
    <v-row align="center" justify="center" no-gutters class="imagePosition"
      ><v-img
        :class="showIconOnly ? '' : 'mr-2'"
        contain
        max-width="60px"
        max-height="60px"
        :src="require('@/assets/images/' + image)"
      />
      <p v-if="!showIconOnly" class="text-h4 white--text ma-0">
        {{ text }}
      </p></v-row
    >
  </div>
</template>

<script>
export default {
  name: "Ribbon",
  props: {
    image: String,
    text: String
  },
  computed: {
    showIconOnly() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style>
/* The ribbon */
.ribbon {
  border: 40px solid #fbac85;
  border-left: 50px solid transparent; /* Right transparent */
  border-right: 50px solid transparent; /* Right transparent */
  width: 100%;
  height: 80px;
}
.imagePosition {
  position: relative;
  top: -30px;
}
</style>
