<template>
  <v-tooltip
    :right="$vuetify.breakpoint.mdAndUp"
    :left="$vuetify.breakpoint.smAndDown"
    open-on-click
    min-width="200"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-on="on"
        v-bind="attrs"
        color="primary"
        @mouseenter="getActions()"
        @mouseleave="assessmentActions = null"
        @click.stop=""
      >
        mdi-information-outline
      </v-icon>
    </template>
    <div>
      <v-progress-circular v-if="!assessmentActions" size="20" indeterminate />
      <div v-else>
        <div v-if="assessmentActions.length === 0">
          {{ $t("insightsPage.table.issues.none") }}
        </div>
        <ul>
          <li v-for="action in assessmentActions" :key="action">
            {{ action }}
          </li>
        </ul>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
import InsightsTextServices from "@/services/analytics/insights-text-services";
import { getAssessmentActions } from "@/customApi";

export default {
  name: "AssessmentInsightTooltip",
  props: {
    id: String
  },
  data() {
    return { assessmentActions: null };
  },
  methods: {
    async getActions() {
      try {
        var assessmentActions = await getAssessmentActions(this.id);
        this.assessmentActions = assessmentActions.map(x =>
          InsightsTextServices.methods.getIssueText(x)
        );
      } catch (error) {}
    }
  }
};
</script>
