import i18n from "@/plugins/i18n.js";
import store from "@/store";
import * as sentry from "@sentry/browser";
import { setLocaleDbValue, getLocaleDbValue } from "@/customApi";
import SupportedLocales from "@/plugins/locales/i18n-supported-locales.js";

const localeVariableKey = "Vitrue-Locale-Storage";

function convertToCommonLocale(locale) {
  switch (locale) {
    case "en":
      return "en-gb";
    case "es-es":
      return "es";
    case "es-ar":
    case "es-bo":
    case "es-cl":
    case "es-co":
    case "es-cr":
    case "es-do":
    case "es-ec":
    case "es-gt":
    case "es-hn":
    case "es-mx":
    case "es-ni":
    case "es-pa":
    case "es-pe":
    case "es-pr":
    case "es-py":
    case "es-sv":
    case "es-uy":
    case "es-ve":
      return "es-mx";
    default:
      return locale;
  }
}

export default {
  async setLocaleValue(newLocale) {
    i18n.locale = newLocale;
    localStorage.setItem(localeVariableKey, newLocale);

    if (!store.getters.signedIn || !store.getters.userId) {
      return;
    }

    try {
      setLocaleDbValue(newLocale);
    } catch (ex) {
      sentry.captureException(ex);
    }
  },
  async syncLocale() {
    var localStorageValue = localStorage.getItem(localeVariableKey);
    var browserLocale = this.getBrowserPreferredLocale();
    var dbValue = "";
    var valueToSet = "";

    // if it fails in backend we don't want to prohibit it from being set in front end
    if (store.getters.signedIn && store.getters.userId) {
      try {
        dbValue = await getLocaleDbValue();
      } catch (ex) {
        sentry.captureException(ex);
      }
    }

    // if local storage doesn't exist but db does, set locale to be that from DB.
    if (!localStorageValue && dbValue) {
      valueToSet = dbValue;
    } else if (localStorageValue) {
      valueToSet = localStorageValue;
    } else if (!localStorageValue && browserLocale) {
      valueToSet = browserLocale;
    }

    if (!valueToSet) {
      return;
    }
    await this.setLocaleValue(valueToSet);
  },
  getBrowserPreferredLocale() {
    var browserLocale = new String(navigator.language)?.toLowerCase();

    var localeToUse = convertToCommonLocale(browserLocale);
    return SupportedLocales.isLocaleValid(localeToUse) ? localeToUse : "";
  }
};
