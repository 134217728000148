export const deskAssessmentInsightCategories = [
    "posture",
    "habit",
    "setup",
    "health"
  ],
  assessmentTypes = {
    deskAssessment: "Desk assessment",
    dailyFeedback: "Pain coach daily feedback",
    checkIn: "Pain check in",
    burnoutAssessment: "Burnout assessment"
  },
  WorkSpacePriority = {
    MAX: 1,
    HIGH: 2,
    MEDIUM: 3,
    LOW: 4
  };
