var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pt-8 pb-2",
      class: _vm.$vuetify.breakpoint.smAndDown ? "px-2" : "px-12"
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "closeButton",
          attrs: { icon: "", color: "black" },
          on: { click: _vm.close }
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c("v-img", {
        attrs: {
          height: _vm.$vuetify.breakpoint.smAndDown ? "100px" : "200px",
          contain: "",
          src: require("@/assets/images/undraw_no_data.svg")
        }
      }),
      !_vm.showErrorMessage
        ? _c(
            "v-card-title",
            {
              staticClass: "justify-center mb-2",
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-0 pt-0" : "mt-5"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "dashboardHistoryComponents.patientBulkReminderDialog.title"
                  )
                )
              )
            ]
          )
        : _vm._e(),
      _vm.showErrorMessage
        ? _c(
            "v-card-title",
            {
              staticClass: "justify-center mb-2",
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-0 pt-0" : "mt-5"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "dashboardHistoryComponents.patientBulkReminderDialog.errorTitle"
                  )
                )
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-card-subtitle",
        [
          !_vm.showErrorMessage
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "dashboardHistoryComponents.patientBulkReminderDialog.reminderText.preText",
                        { "0": _vm.emails.length }
                      )
                    ) +
                    " "
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "dashboardHistoryComponents.patientBulkReminderDialog.reminderText.sendText"
                    )
                  ) + " "
                )
              ])
            : _vm._e(),
          _vm.showErrorMessage
            ? _c("div", {
                staticClass: "red--text mt-2 px-2",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "dashboardHistoryComponents.patientBulkReminderDialog.errorSubtitle"
                    )
                  )
                }
              })
            : _vm._e(),
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-list",
                    {
                      staticStyle: { "overflow-y": "auto" },
                      attrs: { "max-height": "250px" }
                    },
                    _vm._l(_vm.emails, function(email) {
                      return _c(
                        "v-list-item",
                        { key: email },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: _vm.getEmailColor(email) } },
                                [_vm._v(_vm._s(_vm.getEmailIcon(email)))]
                              )
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v(_vm._s(email))])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        width: "100%",
                        rounded: "",
                        color: "primary",
                        loading: _vm.loading,
                        disabled: _vm.loading
                      },
                      on: { click: _vm.SendReminders }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.bulkReminder")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.sendingCount > 0
            ? _c("v-row", { attrs: { justify: "center" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "dashboardHistoryComponents.patientBulkReminderDialog.sendingMessage",
                        { "0": _vm.sendingCount, "1": _vm.emails.length }
                      )
                    )
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }