<template>
  <v-card
    raised
    class="my-2 py-5 d-flex justify-center flex-grow-1"
    :class="verticalAlignment"
  >
    <v-row v-if="verticalLayout === true" class="px-5">
      <v-col cols="12">
        <v-col justify="center" class="py-2">
          <div class="d-flex justify-center mb-2">
            <v-img
              :src="require(`@/assets/images/${imageUrl}`)"
              max-width="125px"
              height="125px"
              contain
              eager
            />
          </div>
          <div
            class="text-center text-h6 vitrueDarkGrey--text font-weight-medium"
            v-text="title"
          />
          <div
            v-if="titleScore"
            id="titleScoreText"
            class="text-center text-h4"
            :style="barColourCSS"
            v-text="titleScore"
          />
        </v-col>
        <div id="divider" class="horizontal-bar" :style="barColourCSS" />
      </v-col>
      <v-col cols="12">
        <div class="text-wrap text-subtitle-1">
          <p
            v-for="(item, index) in text"
            :key="item"
            :class="index !== text.length - 1 ? 'mb-2' : ''"
            v-html="item"
          />
        </div>
        <div v-if="warningText">
          <v-row
            class="text-subtitle-1"
            v-for="text in warningText"
            :key="text"
          >
            <v-col cols="2" align-self="center">
              <v-row justify="center">
                <v-icon large color="red">warning</v-icon>
              </v-row>
            </v-col>
            <v-col cols="10">
              <p class="mb-0" v-html="text"></p>
            </v-col>
          </v-row>
        </div>
        <slot></slot>
      </v-col>
    </v-row>

    <v-row
      v-if="verticalLayout === false"
      justify="center"
      align="center"
      align-content="center"
    >
      <v-col cols="3" class="px-3">
        <v-row justify="center" class="mb-2">
          <v-img
            :src="require(`@/assets/images/${imageUrl}`)"
            contain
            max-width="125px"
            eager
          />
        </v-row>

        <div
          class="text-center text-h6 vitrueDarkGrey--text font-weight-medium"
          v-text="title"
        />
        <div
          v-if="titleScore"
          id="titleScoreText"
          class="text-center text-h4"
          :style="barColourCSS"
          v-text="titleScore"
        />
      </v-col>
      <v-col cols="1" class="align-self-stretch">
        <div id="divider" class="vertical-bar" :style="barColourCSS" />
      </v-col>
      <v-col cols="7" align-self="center">
        <div class="text-wrap text-subtitle-1">
          <p
            v-for="(item, index) in text"
            :key="index"
            :class="index !== text.length - 1 ? 'mb-2' : ''"
            v-html="item"
          />
        </div>
        <slot></slot>
        <div v-if="warningText">
          <v-row
            class="text-subtitle-1"
            v-for="text in warningText"
            :key="text"
          >
            <v-col cols="2" align-self="center">
              <v-row justify="center">
                <v-icon large color="red">warning</v-icon>
              </v-row>
            </v-col>
            <v-col cols="10">
              <p class="mb-0" v-html="text"></p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "InformationCard",
  props: {
    title: String,
    titleScore: String,
    text: Array,
    imageUrl: String,
    sideHeader: Boolean,
    warningText: Array,
    color: String,
    verticalAlignment: {
      type: String,
      default: "align-center"
    }
  },
  computed: {
    verticalLayout() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return this.sideHeader === false;
      }
    },
    barColourCSS() {
      return {
        "--color": this.color
      };
    }
  }
};
</script>

<style scoped>
#divider {
  background-color: var(--color);
}
#titleScoreText {
  color: var(--color);
}
</style>
