<template>
  <v-expansion-panel class="pb-2">
    <v-expansion-panel-header
      :class="$vuetify.breakpoint.xs ? 'text-subtitle-1' : 'text-h6'"
      color="vitrueMidGrey"
    >
      <v-row class="px-3" justify="space-between">
        <p
          class="text-left vitrueDarkGrey--text py-0 my-0 pr-5"
          v-text="title"
        ></p>
        <p
          class="text-right text-h6 py-0 my-0 pr-5 font-weight-bold header-text"
          v-bind:class="[goodScore === true ? 'green--text' : 'red--text']"
          v-text="score"
        ></p>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content color="pt-2">
      <component
        v-if="panelType != undefined"
        :is="panelType + 'Content'"
        v-bind="currentResults"
        :assessmentId="assessmentId ? assessmentId : undefined"
        :category="category"
        :cards="cards"
        :showConstantRecommendations="showConstantRecommendations"
        :showPregnancyContent="showPregnancyContent"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
const DeskAssessmentSummaryContent = () =>
  import("@/components/deskassessment/report/content/SummaryContent");
const PersonalisedDeskAssessmentRecommendationsContent = () =>
  import("@/components/deskassessment/report/content/RecommendationsContent");
const FixedDeskAssessmentRecommendationsContent = () =>
  import("@/components/deskassessment/report/content/RecommendationsContent");
const PainContent = () =>
  import("@/components/deskassessment/report/content/PainContent");
const WebcamAssessmentContent = () =>
  import("@/components/deskassessment/report/content/WebcamAssessmentContent");
const WorkSetupContent = () =>
  import("@/components/deskassessment/report/content/WorkSetupContent");
const BurnoutDetailsContent = () =>
  import("@/components/burnout-assessment/report/content/DetailsContent");
const FixedBurnoutRecommendationsContent = () =>
  import(
    "@/components/burnout-assessment/report/content/RecommendationsContent"
  );
const PersonalisedBurnoutRecommendationsContent = () =>
  import(
    "@/components/burnout-assessment/report/content/RecommendationsContent"
  );
const BurnoutSummaryContent = () =>
  import("@/components/burnout-assessment/report/content/SummaryContent");
const BurnoutKnowledgeBaseContent = () =>
  import("@/components/burnout-assessment/report/content/KnowledgeBaseContent");

export default {
  name: "ReportPanel",
  components: {
    DeskAssessmentSummaryContent,
    PersonalisedDeskAssessmentRecommendationsContent,
    FixedDeskAssessmentRecommendationsContent,
    PainContent,
    WebcamAssessmentContent,
    WorkSetupContent,
    BurnoutDetailsContent,
    FixedBurnoutRecommendationsContent,
    PersonalisedBurnoutRecommendationsContent,
    BurnoutSummaryContent,
    BurnoutKnowledgeBaseContent
  },
  props: {
    title: String,
    score: String,
    panelType: String,
    results: Object,
    goodScore: Boolean,
    assessmentId: String,
    assessmentType: String,
    category: String,
    showConstantRecommendations: Boolean,
    showPregnancyContent: Boolean,
    cards: Array
  },
  computed: {
    currentResults() {
      if (this.assessmentType === "deskassessment") {
        switch (this.panelType) {
          case "DeskAssessmentSummary": {
            return { results: this.results };
          }
          case "PersonalisedDeskAssessmentRecommendations": {
            return { results: this.results };
          }
          case "FixedDeskAssessmentRecommendations": {
            return { results: this.results };
          }
          case "PersonalisedBurnoutRecommendationsContent": {
            return { results: this.results };
          }
          case "FixedBurnoutRecommendationsContent": {
            return { results: this.results };
          }
          case "WorkSetup": {
            return { results: this.results.setup };
          }
          case "WebcamAssessment": {
            return { poseNetResults: this.results.webcam };
          }
          case "Pain": {
            return { results: this.results };
          }
        }
      }

      if (this.assessmentType === "burnout") {
        return { results: this.results };
      }

      return undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 40px !important;
}
</style>
