var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "black" },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c("v-card-title", { staticClass: "justify-center text-h4 pt-12" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "dashboardHistoryComponents.sendAssessmentsToAllUsersDialog.title"
              )
            ) +
            " "
        )
      ]),
      _c(
        "v-row",
        {
          staticStyle: { "min-height": "75%" },
          attrs: { justify: "center", "no-gutters": "" }
        },
        [
          !_vm.initialSendingFailed
            ? _c(
                "v-col",
                {
                  staticClass: "text-body-1 text-center",
                  attrs: { cols: "10", "align-self": "center" }
                },
                [
                  _c(
                    "p",
                    { staticClass: "mb-0", attrs: { id: "sendToUsersText" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "dashboardHistoryComponents.sendAssessmentsToAllUsersDialog.text",
                              { 0: _vm.totalUsers }
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.initialSendingFailed
            ? _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c("p", {
                    staticClass: "mt-2 red--text text-center",
                    attrs: { id: "errorText" },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "dashboardHistoryComponents.sendAssessmentsToAllUsersDialog.error"
                        )
                      )
                    }
                  }),
                  _c(
                    "v-list",
                    {
                      staticStyle: { "overflow-y": "auto" },
                      attrs: { id: "errorList", "max-height": "250px" }
                    },
                    _vm._l(_vm.failedUsers, function(user, index) {
                      return _c(
                        "v-list-item",
                        { key: user.id },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "error" } }, [
                                _vm._v("close")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-title",
                            { attrs: { id: "failedUser" + index } },
                            [_vm._v(_vm._s(user.email.value))]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "py-7", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-center px-2", attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "button",
                    color: "primary",
                    width: "100%",
                    "max-width": "200px",
                    rounded: "",
                    loading: _vm.sending,
                    disabled: _vm.disableSending
                  },
                  on: {
                    click: function($event) {
                      return _vm.sendAssessments()
                    }
                  }
                },
                [
                  !_vm.loading
                    ? _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "white" } },
                        [_vm._v("mdi-check")]
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.buttonText) + " ")
                ],
                1
              )
            ],
            1
          ),
          _vm.sending
            ? _c(
                "v-col",
                {
                  staticClass:
                    "text-center mt-4 text-subtitle-1 disabled--text",
                  attrs: { id: "sendingProgress", cols: "10" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "dashboardHistoryComponents.sendAssessmentsToAllUsersDialog.sendingMessage",
                          { "0": _vm.sendingCount, "1": _vm.totalUsers }
                        )
                      )
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }