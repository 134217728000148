<template>
  <v-alert :type="type" :text="true">
    <span v-html="message"></span>
    <slot> </slot>
  </v-alert>
</template>

<script>
export default {
  props: {
    message: String,
    type: String
  }
};
</script>
