var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    staticClass: "pb-2",
    staticStyle: { "min-width": "125px" },
    style: "max-width:" + _vm.maxWidth + "px",
    attrs: {
      items: _vm.items,
      label: _vm.label,
      multiple: "",
      "hide-details": "",
      clearable: "",
      "item-text": "text",
      "item-value": "value",
      "return-object": ""
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", _vm.tags)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          var index = ref.index
          return [
            index < 1
              ? _c("span", { staticClass: "font-weight-regular" }, [
                  _vm._v(_vm._s(item.text))
                ])
              : _vm._e(),
            index === 1
              ? _c("span", { staticClass: "grey--text caption ml-1" }, [
                  _vm._v(
                    "(+" +
                      _vm._s(_vm.tags.length - 1) +
                      " " +
                      _vm._s(
                        _vm.$t(
                          "dashboardHistoryComponents.assessmentHistoryTable.text.additionalTags"
                        )
                      ) +
                      ")"
                  )
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          var attrs = ref.attrs
          var on = ref.on
          return [
            _c(
              "v-list-item",
              _vm._g(
                _vm._b(
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var active = ref.active
                            return [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-checkbox", {
                                    attrs: { "input-value": active }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item === "No Tag"
                                            ? _vm.$t(
                                                "dashboardHistoryComponents.assessmentHistoryTable.text.noTag"
                                              )
                                            : item.text
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "v-list-item",
                  attrs,
                  false
                ),
                on
              )
            )
          ]
        }
      },
      {
        key: "no-data",
        fn: function() {
          return [
            _c("span", { staticClass: "ml-3 caption" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "dashboardHistoryComponents.assessmentHistoryTable.text.noTags"
                  )
                )
              )
            ])
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.tags,
      callback: function($$v) {
        _vm.tags = $$v
      },
      expression: "tags"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }