var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: { icon: "", color: "primary" },
      on: {
        click: function($event) {
          return _vm.csvExport()
        }
      }
    },
    [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-export ")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }