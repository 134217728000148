<template>
  <v-card min-height="500px" v-if="!results">
    <v-row style="height: 100%" justify="center" align="center" no-gutters>
      <v-progress-circular
        indeterminate
        size="200"
        width="10"
        color="primary"
      />
    </v-row>
  </v-card>
  <v-card v-else style="overflow: hidden">
    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
      persistent
    >
      <feedback-dialog v-model="feedbackDialog" />
    </v-dialog>
    <feedback-button
      v-if="viewingOwnResults"
      v-model="feedbackDialog"
      absolute
      :class="
        $vuetify.breakpoint.smAndDown ? 'feedbackSmallScreen' : 'feedback'
      "
    />

    <v-card-title class="pa-0 ma-0" style="z-index: 2">
      <v-toolbar dark color="primary" width="100%">
        <v-icon v-if="!viewingOwnResults" class="px-5">done</v-icon>
        <div v-if="viewingOwnResults">
          {{
            assessment.assessmentType === "Desk assessment"
              ? $t(
                  "dashboardHistoryComponents.patientHistoryDialog.deskAssessmentResult"
                )
              : $t(
                  "dashboardHistoryComponents.patientHistoryDialog.burnoutAssessmentResult"
                )
          }}
        </div>
        <div class="pt-5" v-else>
          <v-toolbar-title class="data-hj-suppress">{{
            assessment.name
          }}</v-toolbar-title>
          <p>
            {{
              $t("dashboardHistoryComponents.patientHistoryDialog.title", {
                "0": getFormattedDate(assessment.lastUpdatedAt)
              })
            }}
          </p>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon large @click="download" :loading="downloadInProgress"
          ><v-icon>mdi-download</v-icon></v-btn
        >
        <v-btn icon dark @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text id="reportSection" class="px-0" v-scroll.self="onScroll">
      <div ref="container">
        <DeskAssessmentReport
          id="deskAssessmentReport"
          :results="results"
          v-if="assessment.assessmentType === 'Desk assessment'"
          :assessmentId="assessment.id"
          @closeReport="$emit('input', false)"
        />
        <BurnoutAssessmentReport
          id="burnoutAssessmentReport"
          :burnoutResults="results"
          :assessmentId="assessment.id"
          v-if="assessment.assessmentType === 'Burnout assessment'"
          @closeReport="$emit('input', false)"
        />
      </div>
    </v-card-text>
    <v-snackbar id="errorMessage" v-model="error" color="red"
      ><p class="text-center mb-0">{{ $t("errorMessages.pdfGeneration") }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="error = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import DeskAssessmentReport from "@/components/deskassessment/report/Report.vue";
import BurnoutAssessmentReport from "@/components/burnout-assessment/report/BurnoutReport.vue";
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
import FeedbackDialog from "@/components/common/Feedback/FeedbackDialog.vue";
import DeskAssessmentSchema from "@/assets/json/DeskAssessment/DeskAssessmentQuestions.json";
import BurnoutAssessmentSchema from "@/assets/json/BurnoutAssessment/BurnoutAssessmentQuestions.json";
import printer from "@/services/print/pdfPrinter.js";
import { mapGetters } from "vuex";
import { viewingOwnResults } from "@/services/privacy-service.js";

export default {
  components: {
    DeskAssessmentReport,
    BurnoutAssessmentReport,
    FeedbackButton,
    FeedbackDialog
  },
  props: {
    assessment: Object,
    results: Object
  },
  data() {
    return {
      resultIsAvailable: false,
      feedbackDialog: false,
      downloadInProgress: false,
      error: false
    };
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.event("View report from dashboard", {
        event_category: "History"
      });
    }
  },
  computed: {
    ...mapGetters(["userEmail"]),
    assessmentType() {
      return this.assessment?.assessmentType;
    },
    schema() {
      return this.assessmentType === "Desk assessment"
        ? DeskAssessmentSchema
        : BurnoutAssessmentSchema;
    },
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    }
  },
  methods: {
    async download() {
      this.downloadInProgress = true;

      try {
        let email = this.viewingOwnResults
          ? this.userEmail
          : this.assessment.email.value;
        email = email ?? "";
        let date = this.getFormattedDate(this.assessment.completedAt);
        let type = this.assessment.assessmentType;
        let fileName = `${email}_${type}_${date}.pdf`.replace(" ", "");
        await printer.download(this.$refs.container, "l", fileName);
      } catch (err) {
        this.$logger.captureException(err);
        this.error = true;
      } finally {
        this.downloadInProgress = false;
      }
    },
    getFormattedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    onScroll() {
      var scrollableDiv = document.getElementById("reportSection");
      if (scrollableDiv) {
        this.nearBottomOfPage =
          scrollableDiv.scrollHeight - scrollableDiv.scrollTop <=
          scrollableDiv.clientHeight + 500;
      }
    }
  }
};
</script>

<style scoped>
.feedback {
  top: 50%;
  right: 20px;
}
.feedbackSmallScreen {
  top: 90%;
}
</style>
