<template>
  <div class="pb-2">
    <Ribbon
      id="pregnancyRibbon"
      image="parentalYoga.png"
      :text="$t('deskAssessment.report.pregnancyRibbonTitle')"
    />
    <v-row justify="start">
      <v-col
        cols="12"
        lg="4"
        v-for="(card, cardIndex) in cardsToShow"
        :key="cardIndex"
        ><InformationCard
          :id="card.id"
          class="fill-height"
          :title="$t(card.title)"
          :sideHeader="false"
          :color="$vuetify.theme.currentTheme.success"
          :imageUrl="card.image"
          :text="[$t(card.text)]"
          verticalAlignment="align-start"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import InformationCard from "@/components/common/report/cards/InformationReportCard";
import Ribbon from "@/components/common/Ribbon";
import PregnancyReport from "@/services/deskassessment/pregnancy-report.js";
export default {
  name: "PregnancyContent",
  components: {
    InformationCard,
    Ribbon
  },
  props: {
    results: Object
  },
  computed: {
    cardsToShow() {
      return PregnancyReport.getPregnancyInformationCards(this.results);
    }
  }
};
</script>
