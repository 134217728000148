<template>
  <v-card>
    <v-btn
      @click="close"
      style="position:absolute; right: 0; top 0"
      icon
      color="black"
    >
      <v-icon>clear</v-icon>
    </v-btn>
    <v-row no-gutters class="pa-12" justify="center">
      <v-img
        height="200px"
        contain
        src="@/assets/images/undraw_no_data.svg"
      ></v-img>
      <v-card-title class="mt-5 mb-2">{{
        $t("dashboardHistoryComponents.patientReminderDialog.title")
      }}</v-card-title>
      <v-card-subtitle>
        <span v-if="!!assessment.lastUpdatedAt">
          {{
            $t("dashboardHistoryComponents.patientReminderDialog.lastOpened", {
              "0": getFormattedDate(assessment.lastUpdatedAt)
            })
          }}
        </span>
        <span v-else-if="assessment.state === 'In Progress'">
          {{
            $t("dashboardHistoryComponents.patientReminderDialog.notFinished")
          }}
        </span>
        <span v-else>{{
          $t("dashboardHistoryComponents.patientReminderDialog.notStarted")
        }}</span>
        <br />
        <br />
        <span v-if="!emailAvailable">{{
          $t("dashboardHistoryComponents.patientReminderDialog.noEmail")
        }}</span>
      </v-card-subtitle>
      <span v-if="emailAvailable">
        <div
          v-if="showErrorMessage"
          class="red--text mt-2 px-2"
          v-html="
            $t('dashboardHistoryComponents.patientReminderDialog.errorMessage')
          "
        ></div>
      </span>
      <v-row justify="center" v-if="emailAvailable">
        <v-col cols="8">
          <v-tooltip
            top
            content-class="text-center"
            max-width="300"
            color="transparent"
            :disabled="!disableEmails"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                :class="disableEmails ? 'noClick' : ''"
              >
                <v-btn
                  width="100%"
                  color="primary"
                  rounded
                  :loading="loading"
                  :disabled="loading || disableEmails"
                  class="ma-2"
                  @click="SendReminder"
                  >{{
                    $t(
                      "dashboardHistoryComponents.patientReminderDialog.reminderButton"
                    )
                  }}</v-btn
                >
              </div>
            </template>
            <vitrue-tooltip :message="$t('tooltips.disabledByAdmin')" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
import { sendReminderToCompleteAssessment } from "@/customApi";
import { mapGetters } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import StateConverter from "@/services/converters/state-converter.js";

export default {
  components: {
    VitrueTooltip
  },
  props: {
    assessment: Object
  },
  data() {
    return {
      loader: null,
      loading: false,
      showErrorMessage: false
    };
  },
  methods: {
    SendReminder() {
      let currentUrl = window.location.href;
      let baseUrl = currentUrl.split("#")[0];
      baseUrl +=
        this.assessment.assessmentType === "Desk assessment"
          ? "#/deskassessment/"
          : "#/burnoutassessment/";
      this.showErrorMessage = false;
      this.loader = "loading";
      let state = this.assessment.state;
      const assessmentDto = {
        id: this.assessment.id,
        assessmentType: this.assessment.assessmentType,
        baseUrl: baseUrl,
        state: StateConverter.convertTextToState(state)
      };

      sendReminderToCompleteAssessment(this._i18n.locale, assessmentDto)
        .then(() => {
          this.close();
        })
        .catch(() => {
          this.showErrorMessage = true;
          this.resetLoader();
        });
    },
    close() {
      this.resetLoader();
      this.showErrorMessage = false;
      this.$emit("input", false);
    },
    resetLoader() {
      this.loading = false;
      this.loader = null;
    },
    getFormattedDate(date) {
      let formattedDate = new Date(date).toLocaleDateString();
      return formattedDate;
    }
  },
  computed: {
    ...mapGetters(["disableEmails"]),
    emailAvailable() {
      return !!this.assessment.email;
    }
  },
  watch: {
    loader() {
      const load = this.loader;
      this[load] = !this[load];
    }
  }
};
</script>
