var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-scroll-y-transition", [
    _c(
      "div",
      {
        staticClass: "rounded-lg text-body-2 tooltiptext text-center",
        class: _vm.position,
        style: "width:" + _vm.width + "px"
      },
      [_vm._v(" " + _vm._s(_vm.text) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }