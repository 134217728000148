var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-4 pt-0" },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "black" },
          on: {
            click: function($event) {
              return _vm.$emit("inputClose")
            }
          }
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pt-4", attrs: { justify: "center" } },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/images/" + _vm.imageName),
              "max-width": "200px"
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "10" } },
            [
              _c("p", { staticClass: "text-h5" }, [
                _vm._v(_vm._s(_vm.headingText))
              ]),
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _vm.reportSent === false
                    ? _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          rules: _vm.emailRules,
                          label: _vm.$t("textFields.email"),
                          type: "email",
                          "prepend-inner-icon": "email"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.sendReport($event)
                          }
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.showError
                ? _c("p", { staticClass: "text-body-1 warning--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "deskAssessment.results.sendDialog.errorMessage"
                          )
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.reportSent === false
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { sm: "6", md: "4" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "cancelButton",
                        color: "primary",
                        large: "",
                        outlined: "",
                        rounded: "",
                        width: "100%"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("inputClose")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { sm: "6", md: "4" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "sendButton",
                        color: "primary",
                        large: "",
                        rounded: "",
                        width: "100%"
                      },
                      on: { click: _vm.sendReport }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.send")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }