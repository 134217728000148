import BurnoutValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues";
import router from "@/router";

export default {
  getScores(results) {
    let scores = {};
    let totalSum = 0;
    let totalCount = 0;
    let categories = [
      "exhaustion",
      "cognitiveimpairment",
      "emotionalimpairment",
      "mentaldistance",
      "secondarysymptoms"
    ];
    Object.keys(results).forEach(category => {
      if (categories.includes(category)) {
        let result = Object.values(results[category]);
        let sum =
          result.length > 0 ? result.reduce((acc, cur) => acc + cur) : 0;
        // Add number of question to sum to convert 0-4 slider valye to a 1-5 score
        scores[category] = Math.round((sum / result.length + 1) * 100) / 100;

        // secondary symptoms should not contribute to overall score
        if (category !== "secondarysymptoms") {
          totalSum += sum;
          totalCount += result.length;
        }
      }
    });
    scores.overall = Math.round((totalSum / totalCount + 1) * 100) / 100;
    return scores;
  },
  getRiskPercentage(score) {
    return Math.round(((score - 1) / 4) * 100);
  },
  getRisk(score, category) {
    if (score < BurnoutValues[category].average) {
      return "lowRisk";
    } else if (score < BurnoutValues[category].high) {
      return "averageRisk";
    } else if (score < BurnoutValues[category].veryhigh) {
      return "highRisk";
    }
    return "veryHighRisk";
  },
  getRiskFromScore(score, category) {
    let risk = this.getRisk(score, category);
    return router.app.$t("burnoutScores." + risk);
  },
  getCategoryColor(score, category) {
    if (score >= BurnoutValues[category].veryhigh) {
      return "vitrueRed";
    } else if (score >= BurnoutValues[category].high) {
      return "vitrueYellow";
    } else if (score >= BurnoutValues[category].average) {
      return "vitrueBrightGreen";
    } else {
      return "vitrueDarkGreen";
    }
  }
};
