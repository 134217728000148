const VIDARoles = {
  Admin: 0,
  SuperAdmin: 10,
  RestrictedAdmin: 20,
  EndUser: 40
};

const SSORoles = {
  VIDATeamAdmin: 0,
  VIDACompanyAdmin: 10,
  VIDARestrictedAdmin: 20,
  VIDAUser: 40
};

export { VIDARoles, SSORoles };
