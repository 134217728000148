var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.results
    ? _c(
        "v-card",
        { attrs: { "min-height": "500px" } },
        [
          _c(
            "v-row",
            {
              staticStyle: { height: "100%" },
              attrs: { justify: "center", align: "center", "no-gutters": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: {
                  indeterminate: "",
                  size: "200",
                  width: "10",
                  color: "primary"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-card",
        { staticStyle: { overflow: "hidden" } },
        [
          _vm.feedbackDialog
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": _vm.$vuetify.breakpoint.smAndDown
                      ? "100%"
                      : "40%",
                    persistent: ""
                  },
                  model: {
                    value: _vm.feedbackDialog,
                    callback: function($$v) {
                      _vm.feedbackDialog = $$v
                    },
                    expression: "feedbackDialog"
                  }
                },
                [
                  _c("feedback-dialog", {
                    model: {
                      value: _vm.feedbackDialog,
                      callback: function($$v) {
                        _vm.feedbackDialog = $$v
                      },
                      expression: "feedbackDialog"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.viewingOwnResults
            ? _c("feedback-button", {
                class: _vm.$vuetify.breakpoint.smAndDown
                  ? "feedbackSmallScreen"
                  : "feedback",
                attrs: { absolute: "" },
                model: {
                  value: _vm.feedbackDialog,
                  callback: function($$v) {
                    _vm.feedbackDialog = $$v
                  },
                  expression: "feedbackDialog"
                }
              })
            : _vm._e(),
          _c(
            "v-card-title",
            { staticClass: "pa-0 ma-0", staticStyle: { "z-index": "2" } },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary", width: "100%" } },
                [
                  !_vm.viewingOwnResults
                    ? _c("v-icon", { staticClass: "px-5" }, [_vm._v("done")])
                    : _vm._e(),
                  _vm.viewingOwnResults
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.assessment.assessmentType ===
                                "Desk assessment"
                                ? _vm.$t(
                                    "dashboardHistoryComponents.patientHistoryDialog.deskAssessmentResult"
                                  )
                                : _vm.$t(
                                    "dashboardHistoryComponents.patientHistoryDialog.burnoutAssessmentResult"
                                  )
                            ) +
                            " "
                        )
                      ])
                    : _c(
                        "div",
                        { staticClass: "pt-5" },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "data-hj-suppress" },
                            [_vm._v(_vm._s(_vm.assessment.name))]
                          ),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dashboardHistoryComponents.patientHistoryDialog.title",
                                    {
                                      "0": _vm.getFormattedDate(
                                        _vm.assessment.lastUpdatedAt
                                      )
                                    }
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        large: "",
                        loading: _vm.downloadInProgress
                      },
                      on: { click: _vm.download }
                    },
                    [_c("v-icon", [_vm._v("mdi-download")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("input", false)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll.self",
                  value: _vm.onScroll,
                  expression: "onScroll",
                  modifiers: { self: true }
                }
              ],
              staticClass: "px-0",
              attrs: { id: "reportSection" }
            },
            [
              _c(
                "div",
                { ref: "container" },
                [
                  _vm.assessment.assessmentType === "Desk assessment"
                    ? _c("DeskAssessmentReport", {
                        attrs: {
                          id: "deskAssessmentReport",
                          results: _vm.results,
                          assessmentId: _vm.assessment.id
                        },
                        on: {
                          closeReport: function($event) {
                            return _vm.$emit("input", false)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.assessment.assessmentType === "Burnout assessment"
                    ? _c("BurnoutAssessmentReport", {
                        attrs: {
                          id: "burnoutAssessmentReport",
                          burnoutResults: _vm.results,
                          assessmentId: _vm.assessment.id
                        },
                        on: {
                          closeReport: function($event) {
                            return _vm.$emit("input", false)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "v-snackbar",
            {
              attrs: { id: "errorMessage", color: "red" },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { dark: "", text: "" },
                            on: {
                              click: function($event) {
                                _vm.error = false
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.error,
                callback: function($$v) {
                  _vm.error = $$v
                },
                expression: "error"
              }
            },
            [
              _c("p", { staticClass: "text-center mb-0" }, [
                _vm._v(_vm._s(_vm.$t("errorMessages.pdfGeneration")))
              ])
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }