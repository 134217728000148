import cards from "@/assets/json/DeskAssessment/RecommendationCards.json";
import DeskAssessmentResultsUtil from "@/services/deskassessment/desk-assessment-results-util.js";
import i18n from "@/plugins/i18n.js";
import * as Constants from "@/constants/constants.js";
import SummaryService from "@/services/deskassessment/desk-assessment-summary.js";
import router from "@/router";
import { viewingOwnResults } from "@/services/privacy-service.js";
import ResultService from "@/services/deskassessment/desk-assessment-results-util";
import { LinkTypes } from "@/services/recommendations/link-types.js";
import WebCamValues from "@/assets/json/DeskAssessment/WebCamValues.json";
import { WorkSpacePriority } from "@/constants/constants.js";

const isNotUserDashboard = route => route.path !== "/wellness";
const isSmoker = results => results.health.smoker === 1;
const isHighScreenAngle = results => results.webcam.screenHeightAngle > 5;
const hasBeanBag = results =>
  !!results.setup.deskItems?.find(x => x.id === "beanBag");

const feetNotSupported = results => results.setup.feetsupported === 2;
const noPlants = results => results.setup.plants === 2;
const noBreaks = results => results.setup.breaks === 2;
const noActiveBreaks = results =>
  results.setup.breaks === 1 && results.setup.breakActivity === 2;
const noLunchAwayFromDesk = results => results.setup.lunch >= 2;
const noWaterBottle = results => results.setup.water === 2;
const noNaturalLight = results =>
  results.setup.naturallight === 2 || results.setup.brightnesslevel < 0;
const noBreaksLongHours = results =>
  results.setup.breaks === 2 && results.setup.hours >= 9;
const hasClutter = results => results.setup.clutter === 1;
const hasPoorSetup = results => {
  return (
    DeskAssessmentResultsUtil.getVisualDeskPlannerRating(results.setup)
      .rating === i18n.t("deskAssessment.setup.sims.rating.needsImprovement")
  );
};
const hasKneePain = results => {
  return (
    results.pain.areas.leftknee !== undefined ||
    results.pain.areas.rightknee !== undefined
  );
};
const notPerfectScreenDistance = results => {
  return DeskAssessmentResultsUtil.getScreenDistanceScore(results) != 0;
};
const isPregnant = results => results.health.pregnancy === 1;
const pregnantAndCanViewResults = results => {
  return results.health.pregnancy === 1 && viewingOwnResults(router.app._route);
};

function showChairCard(results) {
  return !hasChair(results) && atHomeOrOffice(results);
}

function showStandingDesk(results) {
  var hasLongHours = results.setup.hours >= 9;
  var hasStandingDesk = results.setup.standingdesk === 1;
  return hasLongHours && !hasStandingDesk;
}

function needsWristRest(results) {
  var hasWristRest = results.setup.wristrest === 1;
  var hasWristPain = hasPainAreas(results, ["wrist"]);
  var ergonomicKeyboard = needsErgonomicEquipment(results);
  return hasWristPain && !hasWristRest && !ergonomicKeyboard;
}

const hasPainAreas = (results, areas) => {
  var resultAreas = Object.keys(results.pain.areas);
  return resultAreas.some(x => areas.some(a => x.includes(a)));
};

const getPainAreas = (results, areas) => {
  return Object.entries(results.pain.areas)
    .filter(x => areas.some(a => x[0].includes(a)))
    .map(x => x[1]);
};

function durationLongerThan3Months(results) {
  return Object.values(results.pain.areas).some(x => {
    return x.duration === 4;
  });
}

function painHasNotImproved(results) {
  return Object.values(results.pain.areas).some(x => {
    return x.improvement !== 1;
  });
}

function painLevelGreaterThan(results, level) {
  return Object.values(results.pain.areas).some(x => {
    return x.level > level;
  });
}

function hasRedFlag(results) {
  return Object.keys(results.pain.areas).some(x => {
    var painAreaResults = results.pain.areas[x];
    return DeskAssessmentResultsUtil.hasRedFlags(
      x,
      painAreaResults,
      Constants.assessmentTypes.deskAssessment
    );
  });
}

function showInPersonVisitation(results) {
  var setupHabitsScore =
    ResultService.calculateSetupAndHabitsPercentage(results);
  var hasHighPain = painLevelGreaterThan(results, 6);
  var painNotImprovingForMoreThanAWeek = Object.values(results.pain.areas).some(
    x => {
      return x.improvement > 1 && x.duration > 1;
    }
  );
  return (
    setupHabitsScore > 80 && (hasHighPain || painNotImprovingForMoreThanAWeek)
  );
}

function goodTableHeight(results) {
  return results.setup.deskHeight === 0.48;
}

function needsErgonomicEquipment(results) {
  var areas = getPainAreas(results, ["wrist", "elbow"]);
  var hasRelevantPain = results.setup.workpain !== 4;
  var painNotImproving = areas.some(x => x.improvement !== 1);
  var chronicPain = areas.some(x => x.duration === 4);
  var wristsStraight = results.setup.relaxedarms === 1;
  var spaceForWrists = results.setup.wristspace === 1;
  var correctPainCondition = hasRelevantPain && painNotImproving && chronicPain;
  var correctSetup =
    wristsStraight &&
    spaceForWrists &&
    goodTableHeight(results) &&
    hasMouse(results) &&
    hasKeyboard(results);
  return correctPainCondition && correctSetup;
}

function needsErgonomicKeyboard(results) {
  var needsErgoEquipment = needsErgonomicEquipment(results);
  var hasErgoKeyboard = hasErgonomicKeyboard(results);
  return needsErgoEquipment && !hasErgoKeyboard;
}

function needsErgonomicMouse(results) {
  var needsErgoEquipment = needsErgonomicEquipment(results);
  var hasErgoMouse = hasErgonomicMouse(results);
  return needsErgoEquipment && !hasErgoMouse;
}

function hasErgonomicMouse(results) {
  return results.setup.deskItems.some(x => x.id === "ergonomicMouse");
}

function hasErgonomicKeyboard(results) {
  return results.setup.deskItems.some(x => x.id === "ergonomicKeyboard");
}

function hasKeyboard(results) {
  return (
    results.setup.deskItems.some(x => x.id === "keyboard") ||
    hasErgonomicKeyboard(results)
  );
}

function hasMouse(results) {
  return (
    results.setup.deskItems.some(x => x.id === "mouse") ||
    hasErgonomicMouse(results)
  );
}
function getPregnantExplanation(results, card) {
  var text = pregnantAndCanViewResults(results)
    ? card.explanation.pregnancy
    : card.explanation.general;
  return i18n.t(text);
}

function showHeadphones(results) {
  var hasHeadSet = results.setup.headset === 1;
  var hasPain = hasPainAreas(results, ["neck", "shoulder"]);
  return !hasHeadSet && hasPain;
}

function hasGoodWebcamResult(results) {
  if (results.webcam.screenHeightAngle === null) {
    return false;
  }
  var goodHeight =
    Math.abs(results.webcam.screenHeightAngle) <
    WebCamValues.screenHeight.average;
  var goodDistance =
    results.webcam.distanceFromScreen > WebCamValues.distanceFromScreen.low &&
    results.webcam.distanceFromScreen < WebCamValues.distanceFromScreen.high;

  return goodHeight && goodDistance;
}

function getBreaksExplanation(results, card) {
  var inActiveBreaks = results.setup.breakActivity === 2;
  var text = inActiveBreaks
    ? card.explanation.inactiveBreaks
    : card.explanation.noBreaks;
  return i18n.t(text);
}

function getLinkTypeFromLocationAndEquipmentType(
  results,
  equipmentType,
  defaultType
) {
  switch (results.setup.workingfromhome) {
    case 2:
      return equipmentType === "basic"
        ? LinkTypes.EMAILREQUEST
        : LinkTypes.REQUEST;
    case 1:
    case 3:
    default:
      return defaultType ?? LinkTypes.PURCHASE;
  }
}

function conversationalNoise(results) {
  return results.setup.noise === 1;
}

function loudNoise(results) {
  return results.setup.noise > 2;
}

function tooColdAtHome(results) {
  var tooCold = results.setup.temperature === -2;
  var atHome = results.setup.workingfromhome === 1;
  return tooCold && atHome;
}

function tooHotAtHome(results) {
  var tooHot = results.setup.temperature === 2;
  var atHome = results.setup.workingfromhome === 1;
  return tooHot && atHome;
}

function hasChair(results) {
  return results.setup.deskItems.some(x => x.id.includes("chair"));
}

function inHQ(results) {
  return results.setup.officeLocation === 1;
}

function atHome(results) {
  return results.setup.workingfromhome === 1;
}

function inResidentOffice(results) {
  return results.setup.officeLocation === 2;
}

function hasAdjustableChair(results) {
  return results.setup.seatadjustable === 1;
}

function needsLumbarSupport(results) {
  var chair = hasChair(results);
  var hasBackSupport = results.setup.backsupport === 1;
  return chair && !hasBackSupport;
}

function hasAdjustableChairRiskFactors(results) {
  var wristsRelaxed = results.setup.relaxedarms === 1;
  var hasRelevantPain = hasPainAreas(results, [
    "midback",
    "lowerback",
    "gluteals"
  ]);
  var kneesPerpendicular = results.setup.kneeangle === 1;
  var armRestsNotCloserToDesk = results.setup.armrestsproximity === 2;
  return (
    !wristsRelaxed ||
    hasRelevantPain ||
    !kneesPerpendicular ||
    armRestsNotCloserToDesk
  );
}

function needsSeatWedge(results) {
  let chair = hasChair(results);
  let goodWebcam = hasGoodWebcamResult(results);
  let painNotImproving = painHasNotImproved(results);
  let chronicPain = durationLongerThan3Months(results);
  let keyboard = hasKeyboard(results);
  let mouse = hasMouse(results);
  let tableHeight = goodTableHeight(results);
  return (
    chair &&
    goodWebcam &&
    painNotImproving &&
    chronicPain &&
    keyboard &&
    mouse &&
    tableHeight
  );
}

function atHomeOrRemoteOffice(results) {
  return (
    results.setup.workingfromhome === 1 || results.setup.officeLocation === 2
  );
}

function atHomeOrOffice(results) {
  return results.setup.workingfromhome < 3;
}

function hasItem(results, item) {
  return results.setup.deskItems.some(x => x.id === item);
}

function needsLatopStand(results) {
  var hasLaptopWithStand = hasItem(results, "laptopWithStand");
  var hasLaptop = hasItem(results, "laptop");
  return hasLaptop && !hasLaptopWithStand;
}

function raiseMonitor(results) {
  var hasLaptopWithStand = hasItem(results, "laptopWithStand");
  var hasLaptop = hasItem(results, "laptop");
  var hasMonitor = hasItem(results, "computerScreen");
  var highAngle = isHighScreenAngle(results);
  return !hasLaptop && !hasLaptopWithStand && hasMonitor && highAngle;
}

function raiseLaptopStand(results) {
  var hasLaptopWithStand = hasItem(results, "laptopWithStand");
  var highAngle = isHighScreenAngle(results);
  return hasLaptopWithStand && highAngle;
}

function changeYourWristRest(results) {
  var hasWristRest = results.setup.wristrest === 1;
  var hasWristPain = hasPainAreas(results, ["wrist"]);
  return hasWristRest && hasWristPain;
}

const DeskAssessmentRecommendations = [
  {
    id: "pain_coach",
    json: cards.setup.painCoachCard,
    showOnPage: isNotUserDashboard,
    explanation: results => {
      var painArea = SummaryService.GetPriorityPainAreaOrDefault(results);
      var hasPainAreas = Object.keys(results.pain.areas).length !== 0;
      var customText = hasPainAreas
        ? i18n.t(`reportText.pain.painAreaNames.${painArea}.text`)
        : i18n.t(
            `deskAssessmentRecommendationCards.painCoach.noPainCustomText`
          );
      return i18n.t(cards.setup.painCoachCard.explanation, {
        customText: customText
      });
    },
    linkType: () => LinkTypes.INTERNAL,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 10
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "ergonomic_keyboard_upgrade",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "ergonomic_keyboard_budget",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardBudget,
    linkType: () => LinkTypes.PURCHASE,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "regular_keyboard_upgrade",
    showAnswer: results => !hasKeyboard(results),
    json: cards.setup.regularKeyboardUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_keyboard_budget",
    showAnswer(results) {
      return hasKeyboard(results) === false;
    },
    json: cards.setup.regularKeyboardBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_mouse_budget",
    showAnswer(results) {
      return hasMouse(results) === false;
    },
    json: cards.setup.regularMouseBudget,
    linkType: () => LinkTypes.PURCHASE,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_mouse_upgrade",
    showAnswer: results => !hasMouse(results),
    json: cards.setup.regularMouseUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "mouse_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "ergonomic_mouse",
    showAnswer: results => needsErgonomicMouse(results),
    json: cards.setup.ergonomicMouse,
    linkType: () => LinkTypes.REGULAR,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "smoking",
    showAnswer: isSmoker,
    json: cards.setup.smoking,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 1,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "raise_screen_upgrade",
    showAnswer: needsLatopStand,
    json: cards.setup.raiseYourScreenCardUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "laptop_stand_upgrade",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "raise_screen_budget",
    showAnswer: needsLatopStand,
    json: cards.setup.raiseYourScreenCardBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "laptop_stand_budget",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "beanBag",
    showAnswer(results) {
      return hasBeanBag(results) && !showChairCard(results);
    },
    json: cards.setup.beanBag,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "chair",
    showAnswer(results) {
      return showChairCard(results);
    },
    json: cards.setup.chair,
    linkType: () => LinkTypes.PURCHASE,
    type: "chair",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "feet_supported_upgrade",
    showAnswer: feetNotSupported,
    json: cards.setup.feetsupportedUpgrade,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedUpgrade),

    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "feet_supported_budget",
    showAnswer: feetNotSupported,
    json: cards.setup.feetsupportedBudget,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedBudget),

    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "back_support_upgrade",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportUpgrade,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportUpgrade),

    linkType: () => LinkTypes.PURCHASE,
    type: "lumbarSupport_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_budget",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportBudget,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportBudget),

    linkType: () => LinkTypes.REGULAR,
    type: "lumbarSupport_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_upgrade",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "wristrest_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_budget",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "wristrest_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "hours_upgrade",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursUpgrade,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursUpgrade),

    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "hours_budget",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursBudget,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursBudget),

    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "greenery",
    showAnswer: noPlants,
    json: cards.setup.addSomeGreenery,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 1
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breaks",
    showAnswer: noBreaks,
    json: cards.setup.breaks,
    explanation: results => getPregnantExplanation(results, cards.setup.breaks),

    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breakActivity",
    showAnswer(results) {
      return noActiveBreaks(results) && !isPregnant(results);
    },
    json: cards.setup.breakActivity,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breakActivity_pregnant",
    showAnswer(results) {
      return noActiveBreaks(results) && pregnantAndCanViewResults(results);
    },
    json: cards.setup.breakActivityPregnant,

    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "lunch",
    showAnswer: noLunchAwayFromDesk,
    json: cards.setup.lunch,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "water_bottle",
    showAnswer: noWaterBottle,
    json: cards.setup.getAWaterBottle,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.getAWaterBottle),

    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "natural_light",
    showAnswer: noNaturalLight,
    json: cards.setup.getSomeNaturalLight,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "meditation",
    showAnswer: noBreaksLongHours,
    json: cards.setup.meditation,
    explanation: results =>
      getBreaksExplanation(results, cards.setup.meditation),
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "clutter",
    showAnswer: hasClutter,
    json: cards.setup.clutter,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breakIdeas",
    showAnswer: noActiveBreaks,
    json: cards.setup.breakIdeas,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "fresh_air",
    showAnswer: () => true,
    json: cards.setup.getSomeFreshAir,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "adjust_chair",
    showAnswer(results) {
      return (
        atHomeOrOffice(results) &&
        hasChair(results) &&
        hasAdjustableChair(results) &&
        hasAdjustableChairRiskFactors(results)
      );
    },
    json: cards.setup.adjustChair,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "raise_your_monitor_card",
    showAnswer: results => raiseMonitor(results),
    json: cards.setup.raiseYourMonitor,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "raise_your_laptop_stand_card",
    showAnswer: results => raiseLaptopStand(results),
    json: cards.setup.raiseYourLaptopStand,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "low_noise_budget",
    showAnswer: results => conversationalNoise(results),
    json: cards.setup.lowNoiseBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "noise_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "low_noise_upgrade",
    showAnswer: results => conversationalNoise(results),
    json: cards.setup.lowNoiseUpgrade,
    linkType: () => LinkTypes.REGULAR,
    type: "noise_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_noise_budget",
    showAnswer: results => loudNoise(results),
    json: cards.setup.highNoiseBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "noise_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_noise_upgrade",
    showAnswer: results => loudNoise(results),
    json: cards.setup.highNoiseUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "noise_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "headphones_budget",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesBudget,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "headphones_upgrade",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_budget",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return !hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_upgrade",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return !hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "seat_wedge_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_hip_budget",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackHipBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_hip_upgrade",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackHipUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "seat_wedge_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_hip_budget",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && !lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeHipBudget,
    linkType: () => LinkTypes.PURCHASE,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_hip_upgrade",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && !lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeHipUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "seat_wedge_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "poor_setup_ykavx",
    showAnswer: hasPoorSetup,
    json: cards.setup.poorSetup_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "custom_knee_pain_ykavx",
    showAnswer: hasKneePain,
    json: cards.setup.customKneePain_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "monitor_distance_ykavx",
    showAnswer: notPerfectScreenDistance,
    json: cards.setup.monitorDistance_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "smoking_ykavx",
    showAnswer: isSmoker,
    json: cards.setup.smoking_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 1,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "raise_screen_upgrade_ykavx",
    showAnswer: needsLatopStand,
    json: cards.setup.raiseYourScreenCardUpgrade_ykavx,
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    type: "laptop_stand_upgrade",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "feet_supported_upgrade_ykavx",
    showAnswer: feetNotSupported,
    json: cards.setup.feetsupportedUpgrade_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedUpgrade_ykavx),

    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "feet_supported_budget_ykavx",
    showAnswer: feetNotSupported,
    json: cards.setup.feetsupportedBudget_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedBudget_ykavx),

    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "back_support_upgrade_ykavx",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportUpgrade_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportUpgrade_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "lumbarSupport_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_budget_ykavx",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportBudget_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportBudget_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "lumbarSupport_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_upgrade_ykavx",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsUpgrade_ykavx,
    linkType: () => LinkTypes.REGULAR,
    type: "wristrest_upgrade",
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "hours_upgrade_ykavx",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursUpgrade_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursUpgrade_ykavx),
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "hours_budget_ykavx",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursBudget_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursBudget_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "lunch_ykavx",
    showAnswer: noLunchAwayFromDesk,
    json: cards.setup.lunch_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "water_bottle_ykavx",
    showAnswer: noWaterBottle,
    json: cards.setup.getAWaterBottle_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.getAWaterBottle_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "natural_light_ykavx",
    showAnswer: noNaturalLight,
    json: cards.setup.getSomeNaturalLight_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "meditation_ykavx",
    showAnswer: noBreaksLongHours,
    json: cards.setup.meditation_ykavx,
    explanation: results =>
      getBreaksExplanation(results, cards.setup.meditation_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "clutter_ykavx",
    showAnswer: hasClutter,
    json: cards.setup.clutter_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "fresh_air_ykavx",
    showAnswer: () => true,
    json: cards.setup.getSomeFreshAir_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "physical_activity_classes_cMwjk",
    showAnswer: results => durationLongerThan3Months(results),
    json: cards.setup.physicalActivityClasses_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8.9,
      easeOfUse: 9.5,
      painImpact: 9.3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wellbeing_programme_cMwjk",
    showAnswer: results =>
      durationLongerThan3Months(results) ||
      painHasNotImproved(results) ||
      painLevelGreaterThan(results, 5),
    json: cards.setup.wellbeingProgramme_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 4,
      easeOfUse: 7,
      painImpact: 8.8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "msk_programme_cMwjk",
    showAnswer: results => painHasNotImproved(results),
    json: cards.setup.mskProgramme_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 10,
      painImpact: 9.2
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "self_care_pack_cMwjk",
    showAnswer: results => painHasNotImproved(results),
    json: cards.setup.selfCarePack_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 6,
      painImpact: 8.8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "your_support_cMwjk",
    showAnswer: results => painHasNotImproved(results),
    json: cards.setup.yourSupport_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 7.1
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "occupational_health_cMwjk",
    showAnswer: results => hasRedFlag(results),
    json: cards.setup.occupationalHealth_cMwjk,
    linkType: () => LinkTypes.EMAIL,
    custom: true,
    type: "occupational_health_cMwjk",
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9.5,
      painImpact: 10
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "occupational_health_cMwjk",
    showAnswer: results =>
      painLevelGreaterThan(results, 5) && hasRedFlag(results) === false,
    json: cards.setup.occupationalHealth_cMwjk,
    linkType: () => LinkTypes.EMAIL,
    type: "occupational_health_cMwjk",
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9.5,
      painImpact: 10
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "react_cMwjk",
    showAnswer: results => painLevelGreaterThan(results, 5),
    json: cards.setup.react_cMwjk,
    linkType: () => LinkTypes.EMAIL,
    custom: true,
    scores: {
      easeOfAccess: 8.9,
      easeOfUse: 6.1,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "peer_support_cMwjk",
    showAnswer: results => hasRedFlag(results),
    json: cards.setup.peerSupport_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9.6,
      easeOfUse: 9.8,
      painImpact: 9.8
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "book_session_cMwjk",
    showAnswer: results => hasRedFlag(results),
    json: cards.setup.bookSession_cMwjk,
    linkType: () => LinkTypes.EMAIL,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9.5,
      painImpact: 10
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "cycle_to_work_kItGM",
    showAnswer: () => true,
    json: cards.setup.cycleToWork_kItGM,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "in_person_visit_ttqWel",
    showAnswer: results => showInPersonVisitation(results),
    json: cards.setup.inPersonVisit_ttqWel,
    linkType: () => LinkTypes.APPOINTMENT,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_keyboard_upgrade_ttqWel",
    showAnswer: results => !hasKeyboard(results),
    json: cards.setup.regularKeyboardUpgrade,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    custom: true,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_keyboard_budget_ttqWel",
    showAnswer(results) {
      return hasKeyboard(results) === false;
    },
    json: cards.setup.regularKeyboardBudget,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(
        results,
        "specialised",
        LinkTypes.REGULAR
      ),
    custom: true,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "ergonomic_keyboard_upgrade_ttqWel",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardUpgrade,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    custom: true,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "ergonomic_keyboard_budget_ttqWel",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardBudget,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    custom: true,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "regular_mouse_budget_ttqWel",
    showAnswer(results) {
      return hasMouse(results) === false;
    },
    json: cards.setup.regularMouseBudget,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    custom: true,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_mouse_upgrade_ttqWel",
    showAnswer: results => !hasMouse(results),
    json: cards.setup.regularMouseUpgrade,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    custom: true,
    type: "mouse_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "ergonomic_mouse_upgrade_ttqWel",
    showAnswer: results => needsErgonomicMouse(results),
    json: cards.setup.ergonomicMouseUpgrade_ttqWel,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(
        results,
        "specialised",
        LinkTypes.REGULAR
      ),
    custom: true,
    type: "mouse_upgrade_ttqWel",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "ergonomic_mouse_budget_ttqWel",
    showAnswer: results => needsErgonomicMouse(results),
    json: cards.setup.ergonomicMouseBudget_ttqWel,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(
        results,
        "specialised",
        LinkTypes.REGULAR
      ),
    custom: true,
    type: "mouse_budget_ttqWel",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "standingBreaks",
    showAnswer: results => noBreaks(results) || noActiveBreaks(results),
    json: cards.setup.standingBreaks,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "headphones_budget_ttqWel",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesBudget,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "headphones_upgrade_ttqWel",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesUpgrade,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_noise_budget_ttqWel",
    showAnswer: results => loudNoise(results),
    json: cards.setup.highNoiseBudget,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    type: "noise_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_noise_upgrade_ttqWel",
    showAnswer: results => loudNoise(results),
    json: cards.setup.highNoiseUpgrade,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    type: "noise_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "low_temperature",
    showAnswer: results => tooColdAtHome(results),
    json: cards.setup.lowTemperature,
    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_temperature",
    showAnswer: results => tooHotAtHome(results),
    json: cards.setup.highTemperature,
    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "chair_ttqWel",
    showAnswer(results) {
      return !hasChair(results) && atHomeOrRemoteOffice(results);
    },
    json: cards.setup.chair,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "chair",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "building_guide_ttqWel",
    showAnswer: results => inHQ(results),
    json: cards.setup.buildingGuide_ttqWel,
    custom: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "back_support_upgrade_ttqWel",
    showAnswer(results) {
      return needsLumbarSupport(results) && !isPregnant(results);
    },
    json: cards.setup.backsupportUpgrade_ttqWel,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "lumbarSupport_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_upgrade_pregnant_ttqWel",
    showAnswer(results) {
      return needsLumbarSupport(results) && isPregnant(results);
    },
    json: cards.setup.backsupportUpgradePregnant_ttqWel,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "lumbarSupport_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_budget_ttqWel",
    showAnswer: results => needsLumbarSupport(results),
    json: cards.setup.backsupportBudget,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportBudget),
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "adjust_chair_home_ttqWel",
    showAnswer(results) {
      return (
        atHome(results) &&
        hasChair(results) &&
        hasAdjustableChair(results) &&
        hasAdjustableChairRiskFactors(results)
      );
    },
    json: cards.setup.adjustChair,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "adjust_chair_resident_office_ttqWel",
    showAnswer(results) {
      return (
        inResidentOffice(results) &&
        hasChair(results) &&
        hasAdjustableChair(results) &&
        hasAdjustableChairRiskFactors(results)
      );
    },
    json: cards.setup.adjustChair,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "adjust_chair_hq_ttqWel",
    showAnswer(results) {
      return inHQ(results) && hasAdjustableChairRiskFactors(results);
    },
    json: cards.setup.adjustChairHQ_ttqWel,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "raise_your_screen_card_budget_ttqWel",
    showAnswer: results => needsLatopStand(results),
    json: cards.setup.raiseYourScreenCardBudget,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "laptop_stand_budget",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "raise_your_screen_card_upgrade_ttqWel",
    showAnswer: results => needsLatopStand(results),
    json: cards.setup.raiseYourScreenCardUpgrade_ttqWel,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "laptop_stand_upgrade",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "change_your_wrist_rest_card",
    showAnswer: results => changeYourWristRest(results),
    json: cards.setup.changeYourWristRest,
    linkType: () => LinkTypes.REGULAR,
    type: "wristrest_budget",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_upgrade_ttqWel",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsUpgrade_ttqWel,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "wristrest_upgrade",
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_budget_ttqWel",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsBudget_ttqWel,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "wristrest_budget",
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "feet_supported_upgrade_ttqWel",
    showAnswer: feetNotSupported,
    json: cards.setup.feetsupportedUpgrade_ttqWel,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedUpgrade_ttqWel),
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "feet_supported_budget_ttqWel",
    showAnswer: feetNotSupported,
    json: cards.setup.feetsupportedBudget_ttqWel,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedBudget_ttqWel),

    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "be_me_app_ykavx",
    json: cards.fixed.beMeApp_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "desk_stretches_ykavx",
    json: cards.fixed.deskStretches_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "aches_and_pains_ykavx",
    json: cards.fixed.achesAndPains_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "back_tension_ykavx",
    json: cards.fixed.backTension_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "personal_energy_ykavx",
    json: cards.fixed.personalEnergy_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "health_and_wellbeing_hub_ykavx",
    json: cards.fixed.healthAndWellbeingHub_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "anytime_health_line_ykavx",
    json: cards.fixed.anytimeHealthline_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  }
];

function DefaultExerciseRecommendations(jsonFile) {
  return {
    warmUpCard: {
      showAnswer: results => {
        if (results.health.pregnancy === 1) {
          return shouldShowGeneralExerciseCardIfPregnant(results);
        }
        return true;
      },
      json: jsonFile.pain.warmUpCard,
      linkType: () => LinkTypes.VIDEO
    },
    neckExerciseCard: {
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "neck");
      },
      json: jsonFile.pain.neckExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    midBackExerciseCard: {
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "midback");
      },
      json: jsonFile.pain.midBackExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    lowerBackExerciseCard: {
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "lowerback");
      },
      json: jsonFile.pain.lowerBackExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    wristExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftwrist") ||
          shouldShowPainAreaExerciseCard(results, "rightwrist")
        );
      },
      json: jsonFile.pain.wristExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    hipExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "lefthip") ||
          shouldShowPainAreaExerciseCard(results, "righthip")
        );
      },
      json: jsonFile.pain.hipExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    elbowExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftelbow") ||
          shouldShowPainAreaExerciseCard(results, "rightelbow")
        );
      },
      json: jsonFile.pain.elbowExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    shoulderExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftshoulder") ||
          shouldShowPainAreaExerciseCard(results, "rightshoulder")
        );
      },
      json: jsonFile.pain.shoulderExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    ankleExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftankle") ||
          shouldShowPainAreaExerciseCard(results, "rightankle")
        );
      },
      json: jsonFile.pain.ankleExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    footExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftankle") ||
          shouldShowPainAreaExerciseCard(results, "rightankle")
        );
      },
      json: jsonFile.pain.footExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    kneeExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftknee") ||
          shouldShowPainAreaExerciseCard(results, "rightknee")
        );
      },
      json: jsonFile.pain.kneeExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    pectoralExerciseCard: {
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "chest");
      },
      json: jsonFile.pain.pectoralExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    glutealExerciseCard: {
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "gluteals");
      },
      json: jsonFile.pain.glutealExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    calfExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftcalf") ||
          shouldShowPainAreaExerciseCard(results, "rightcalf")
        );
      },
      json: jsonFile.pain.calfExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    hamstringExerciseCard: {
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "lefthamstring") ||
          shouldShowPainAreaExerciseCard(results, "righthamstring")
        );
      },
      json: jsonFile.pain.hamstringExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    abdominalExerciseCard: {
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "abs");
      },
      json: jsonFile.pain.abdominalExerciseCard,
      linkType: () => LinkTypes.VIDEO
    }
  };
}

function shouldShowGeneralExerciseCardIfPregnant(results) {
  // if first trimester always show general exercise
  if (results.health.pregnancyTrimester === 1) {
    return true;
  }

  // if not first trimester only show general exercise as long as pain level is between 1-3
  // if there is abs pain do not show general exercise regardless
  let painAreas = Object.values(results.pain.areas);
  let allAreasThreeOrLess = painAreas.every(function (area) {
    return area.level <= 3;
  });
  return allAreasThreeOrLess;
}

function shouldShowPainAreaExerciseCard(results, area) {
  let painAreaInResults = results.pain.areas[area];
  if (painAreaInResults === undefined) {
    return false;
  }

  // if not pregnant then always show exercise card if displaying pain
  let userNotPregnant = results.health.pregnancy !== 1;
  if (userNotPregnant) {
    return true;
  }

  // if second or third trimester and area is ab pain - do not show recommendations
  let lateStagePregnancy =
    results.health.pregnancyTrimester === 2 ||
    results.health.pregnancyTrimester === 3;
  if (lateStagePregnancy && area == "abs") {
    return false;
  }

  // if pregnant and area is between 1-3 then shown the exercise card
  return painAreaInResults.level <= 3;
}

export { DefaultExerciseRecommendations, DeskAssessmentRecommendations };
