import store from "@/store";
import { userTeamMemberInformation, getSimpleUserDetails } from "@/customApi";

export default {
  async getTeamMemberInfo() {
    let teamMemberId = store.getters.teamMemberId;
    let teamMemberInfo = store.getters.teamMemberInfo;

    if (
      !teamMemberId ||
      !teamMemberInfo ||
      teamMemberInfo?.teamMemberId !== teamMemberId
    ) {
      teamMemberInfo = await userTeamMemberInformation();
      store.commit("setTeamMemberId", teamMemberInfo?.teamMemberId);
      store.commit("setTeamMemberInfo", teamMemberInfo);
      store.commit("setTeamIdpName", teamMemberInfo?.teamIDP);
    }

    store.dispatch("setFeatureFlags", teamMemberInfo);
    return teamMemberInfo;
  },
  async updateEndUserInfo() {
    let userEmail = store.getters.userEmail;
    let teamInfo = store.getters.currentUserTeamAndSubscriptionBasicInformation;

    if (!teamInfo || !teamInfo.accessTier || !userEmail) {
      let userDetails = await getSimpleUserDetails();
      await store.dispatch("setSimpleUserDetails", userDetails);
    }
  }
};
