<template>
  <v-card
    class="px-4 pt-4 emojiContainer"
    :color="feedbackColor"
    style="overflow-x: hidden"
  >
    <v-card-text>
      <v-row align="center" justify="center" no-gutters>
        <div v-if="!submitted">
          <v-col cols="12" class="text-center">
            <p :class="'display-1 ' + textColor + '--text'">
              {{ $t("feedback.dialog.title") }}
            </p>
            <p :class="textColor + '--text'">
              {{ $t("feedback.dialog.info") }}
            </p>
            <feedback-emojis
              v-model="reaction"
              showLabels
              :dark="isDark()"
              @input="showGiveFeedbackMessage = false"
              @hover="hoverEmoji = $event"
            />
            <v-slide-x-transition>
              <div
                v-show="showGiveFeedbackMessage"
                class="errorMessage white--text mt-2 px-2"
              >
                {{ $t("feedback.dialog.emojiInstruction") }}
              </div>
            </v-slide-x-transition>
            <v-textarea
              v-model="feedback"
              clearable
              :color="textColor"
              :dark="isDark()"
              no-resize
              style="min-height: 80px"
              hide-details
            >
              <template v-slot:label>
                <div>{{ $t("feedback.dialog.textInsutruction") }}</div>
              </template>
            </v-textarea>
            <v-slide-x-transition>
              <div
                v-show="showErrorSubmittingMessage"
                class="errorMessage white--text mt-2 px-2"
              >
                {{ $t("feedback.dialog.errorMessage") }}
              </div>
            </v-slide-x-transition>
          </v-col>
          <v-row class="pt-3" justify="center">
            <v-btn
              class="ma-3"
              max-width="200px"
              @click="resetAndClose()"
              outlined
              :color="secondaryButtonColor"
              >{{ $t("buttons.cancel") }}</v-btn
            >
            <v-btn
              class="ma-3"
              max-width="200px"
              @click="submitFeedback()"
              :color="primaryButtonColor"
              >{{ $t("buttons.submit") }}</v-btn
            >
          </v-row>
        </div>
        <v-row v-if="submitted" style="min-height: 400px" align="center">
          <v-btn class="closeButton" icon @click="resetAndClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-col>
            <v-row justify="center">
              <v-img
                src="@/assets/gifs/hugEmoji.gif"
                max-height="150px"
                max-width="150px"
                contain
              />
            </v-row>
            <div class="headline blue--text text-center">
              {{ $t("feedback.dialog.successTitle") }}
            </div>
            <div class="body-1 text-center" text-center>
              <b>{{ $t("feedback.dialog.successInfo") }}</b>
            </div>
            <div class="body-1 gray--text text-center">
              {{
                $t("feedback.dialog.closeCountdown", {
                  "0": timeLeft
                })
              }}
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import FeedbackEmojis from "@/components/common/Feedback/FeedbackEmojis.vue";
import { mapMutations, mapGetters } from "vuex";
import { createFeedback } from "../../../customApi";

export default {
  name: "FeedbackDialog",
  components: {
    FeedbackEmojis
  },
  data() {
    return {
      reaction: null,
      feedback: null,
      showGiveFeedbackMessage: false,
      showErrorSubmittingMessage: false,
      submitted: false,
      timeLeft: 3,
      countdownCallback: null,
      hoverEmoji: null
    };
  },
  props: {
    assessmentId: String
  },
  computed: {
    ...mapGetters(["userEmail"]),
    textColor() {
      return this.isDark() ? "white" : "gray";
    },
    secondaryButtonColor() {
      return this.isDark() ? "white" : "primary";
    },
    primaryButtonColor() {
      return this.isDark() ? "white" : "primary";
    },
    feedbackColor() {
      return this.reaction ? this.reaction.color : this.hoverEmoji?.color;
    }
  },
  methods: {
    ...mapMutations(["addAsyncRequest", "removeAsyncRequest"]),
    isDark() {
      return this.reaction !== null;
    },
    async submitFeedback() {
      this.showGiveFeedbackMessage = this.reaction === null;
      this.showErrorSubmittingMessage = false;
      if (this.showGiveFeedbackMessage) {
        return;
      }
      let feedbackDto = {
        Email: { value: this.userEmail },
        Score: this.reaction.value,
        WrittenFeedback: this.feedback,
        AssessmentId: this.assessmentId,
        Url: this.$route.path
      };
      if (this.reaction) {
        try {
          this.addAsyncRequest();
          this.$gtag.event("Submit Regular Feedback", {
            event_category: "Feedback"
          });
          await createFeedback(feedbackDto);
          this.submitted = true;
          this.reaction = null;
          this.countdownCallback = window.setInterval(
            this.updateCountdown,
            1000
          );
        } catch (err) {
          this.showErrorSubmittingMessage = true;
        } finally {
          this.removeAsyncRequest();
        }
      }
    },
    updateCountdown() {
      this.timeLeft--;
      if (this.timeLeft === 0) {
        window.clearInterval(this.countdownCallback);
        this.resetAndClose();
      }
    },
    resetAndClose() {
      this.timeLeft = 3;
      this.submitted = false;
      this.reaction = null;
      this.feedback = "";
      this.showGiveFeedbackMessage = false;
      this.showErrorSubmittingMessage = false;
      this.$emit("input", false);
    }
  }
};
</script>

<style scoped>
.emojiContainer {
  transition: background-color 1s;
}
.errorMessage {
  background: red;
  display: inline-block;
}
.closeButton {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
