export const LinkTypes = {
  REGULAR: "regularLink",
  PURCHASE: "purchaseLink",
  EMAIL: "emailLink",
  VIDEO: "videoLink",
  REQUEST: "requestLink",
  EMAILREQUEST: "emailRequestLink",
  INTERNAL: "internalRoute",
  APPOINTMENT: "appointmentLink",
  TIPS: "tipsLink"
};
