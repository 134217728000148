var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "VueGlow",
              {
                staticStyle: {
                  "border-radius": "24px",
                  height: "100%",
                  width: "100%"
                },
                attrs: {
                  intensity: _vm.intensity(hover),
                  elevation: _vm.getElevation(hover),
                  mode: "hex",
                  color: _vm.hexColour
                }
              },
              [
                _c(
                  "v-card",
                  {
                    staticStyle: { "border-radius": "24px" },
                    attrs: {
                      color: _vm.color,
                      dark: "",
                      ripple: "",
                      height: "100%",
                      width: "100%",
                      disabled: _vm.disabled || _vm.loading
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("input", true)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "pt-5 px-5 pb-0 fill-height",
                        class: _vm.chooseGradient,
                        staticStyle: { position: "relative" }
                      },
                      [
                        _c("v-icon", { attrs: { size: "5vw" } }, [
                          _vm._v(_vm._s(_vm.icon))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "top-right ma-4 mr-6",
                            style: _vm.$vuetify.breakpoint.smAndDown
                              ? "width:20px;"
                              : "width: 30px;"
                          },
                          [
                            _vm.loading
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "white",
                                    size: _vm.$vuetify.breakpoint.smAndDown
                                      ? "20"
                                      : "30"
                                  }
                                })
                              : !_vm.hideIcon
                              ? _c("BouncingImage", {
                                  attrs: {
                                    imageSource: "emojis/letter.png",
                                    playAnimation: hover && !_vm.loading,
                                    smallBounce: ""
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("v-card-text", { staticClass: "pa-0" }, [
                          _c("span", { staticClass: "text-h5" }, [
                            _vm._v(_vm._s(_vm.title))
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "text-body-2" }, [
                            _vm._v(_vm._s(_vm.subtitle))
                          ])
                        ])
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }