var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vitrue-dialog",
    {
      attrs: {
        title: _vm.$vuetify.breakpoint.xs
          ? _vm.$t("dashboardCards.wellness.teaser.titleShort")
          : _vm.$t("dashboardCards.wellness.teaser.title"),
        image: "wellness/heartBeat.png"
      },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event)
        }
      }
    },
    [
      _c(
        "v-card",
        { attrs: { rounded: "xl", flat: "" } },
        [
          _c(
            "v-row",
            {
              staticStyle: { "overflow-y": "auto", "max-height": "42vh" },
              attrs: { justify: "center", "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "pa-3", attrs: { cols: "12", lg: "8" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c("p", { staticClass: "text-center text-body-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "dashboardCards.wellness.teaser.description"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _vm._l(_vm.teaserPoints, function(point) {
                        return _c(
                          "v-col",
                          {
                            key: point.title,
                            attrs: { cols: "12", sm: "6", lg: "4" }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: { justify: "center", "no-gutters": "" }
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        width: "150px",
                                        contain: "",
                                        src: require("@/assets/images/" +
                                          point.image)
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-body-1 font-weight-bold text-center"
                              },
                              [_vm._v(" " + _vm._s(point.title) + " ")]
                            ),
                            _c(
                              "p",
                              { staticClass: "text-body-1 text-center" },
                              [_vm._v(_vm._s(point.description))]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticStyle: { height: "75px" },
              attrs: { justify: "center", "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto", "align-self": "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        rounded: "",
                        width: _vm.$vuetify.breakpoint.xs ? "200px" : "375px"
                      },
                      on: {
                        click: function($event) {
                          _vm.$router.push("/wellness", function() {})
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.tryItYourself")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }