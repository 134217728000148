var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "my-2 py-5 d-flex justify-center flex-grow-1",
      class: _vm.verticalAlignment,
      attrs: { raised: "" }
    },
    [
      _vm.verticalLayout === true
        ? _c(
            "v-row",
            { staticClass: "px-5" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-2", attrs: { justify: "center" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center mb-2" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/images/" + _vm.imageUrl),
                              "max-width": "125px",
                              height: "125px",
                              contain: "",
                              eager: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass:
                          "text-center text-h6 vitrueDarkGrey--text font-weight-medium",
                        domProps: { textContent: _vm._s(_vm.title) }
                      }),
                      _vm.titleScore
                        ? _c("div", {
                            staticClass: "text-center text-h4",
                            style: _vm.barColourCSS,
                            attrs: { id: "titleScoreText" },
                            domProps: { textContent: _vm._s(_vm.titleScore) }
                          })
                        : _vm._e()
                    ]
                  ),
                  _c("div", {
                    staticClass: "horizontal-bar",
                    style: _vm.barColourCSS,
                    attrs: { id: "divider" }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "text-wrap text-subtitle-1" },
                    _vm._l(_vm.text, function(item, index) {
                      return _c("p", {
                        key: item,
                        class: index !== _vm.text.length - 1 ? "mb-2" : "",
                        domProps: { innerHTML: _vm._s(item) }
                      })
                    }),
                    0
                  ),
                  _vm.warningText
                    ? _c(
                        "div",
                        _vm._l(_vm.warningText, function(text) {
                          return _c(
                            "v-row",
                            { key: text, staticClass: "text-subtitle-1" },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: { cols: "2", "align-self": "center" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { large: "", color: "red" } },
                                        [_vm._v("warning")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "10" } }, [
                                _c("p", {
                                  staticClass: "mb-0",
                                  domProps: { innerHTML: _vm._s(text) }
                                })
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._t("default")
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.verticalLayout === false
        ? _c(
            "v-row",
            {
              attrs: {
                justify: "center",
                align: "center",
                "align-content": "center"
              }
            },
            [
              _c(
                "v-col",
                { staticClass: "px-3", attrs: { cols: "3" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-2", attrs: { justify: "center" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/images/" + _vm.imageUrl),
                          contain: "",
                          "max-width": "125px",
                          eager: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass:
                      "text-center text-h6 vitrueDarkGrey--text font-weight-medium",
                    domProps: { textContent: _vm._s(_vm.title) }
                  }),
                  _vm.titleScore
                    ? _c("div", {
                        staticClass: "text-center text-h4",
                        style: _vm.barColourCSS,
                        attrs: { id: "titleScoreText" },
                        domProps: { textContent: _vm._s(_vm.titleScore) }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "align-self-stretch", attrs: { cols: "1" } },
                [
                  _c("div", {
                    staticClass: "vertical-bar",
                    style: _vm.barColourCSS,
                    attrs: { id: "divider" }
                  })
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "7", "align-self": "center" } },
                [
                  _c(
                    "div",
                    { staticClass: "text-wrap text-subtitle-1" },
                    _vm._l(_vm.text, function(item, index) {
                      return _c("p", {
                        key: index,
                        class: index !== _vm.text.length - 1 ? "mb-2" : "",
                        domProps: { innerHTML: _vm._s(item) }
                      })
                    }),
                    0
                  ),
                  _vm._t("default"),
                  _vm.warningText
                    ? _c(
                        "div",
                        _vm._l(_vm.warningText, function(text) {
                          return _c(
                            "v-row",
                            { key: text, staticClass: "text-subtitle-1" },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: { cols: "2", "align-self": "center" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { large: "", color: "red" } },
                                        [_vm._v("warning")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "10" } }, [
                                _c("p", {
                                  staticClass: "mb-0",
                                  domProps: { innerHTML: _vm._s(text) }
                                })
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }