<template>
  <v-dialog
    v-if="showSignUpDialog && !signedIn"
    v-model="showSignUpDialog"
    max-width="500px"
    persistent
  >
    <sign-up
      reportPageSignUp
      :errorMsg="errorMessage"
      :assessmentId="assessmentId"
      :fromLinkGenerator="!userEmail"
      @regularSignInComplete="closeDialog"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SignUp from "@/components/authentication/SignUp";

export default {
  props: {
    assessmentType: String,
    assessmentId: String
  },
  components: {
    SignUp
  },
  mounted() {
    if (this.$route.query.error) {
      this.errorMessage = this.$route.query.error;
    }

    if (!this.signedIn && this.teamId) {
      this.setUpSignupDialog();
    }
  },
  watch: {
    signedIn(val) {
      if (val) {
        this.resetSideBarAndScroll();
      }
    },
    teamId(val) {
      if (!this.signedIn && val) {
        this.setUpSignupDialog();
      }
    }
  },
  data() {
    return {
      showSignUpDialog: true,
      errorMessage: null
    };
  },
  beforeDestroy() {
    this.resetSideBarAndScroll();
  },
  computed: {
    ...mapGetters(["signedIn", "userEmail", "teamId"])
  },
  methods: {
    ...mapMutations(["setDummySideBar"]),
    setScroll(enabled) {
      document.body.style.overflow = enabled ? "" : "hidden";
      document.body.style.height = enabled ? "" : "100vh";
    },
    resetSideBarAndScroll() {
      this.setDummySideBar(false);
      this.setScroll(true);
    },
    setUpSignupDialog() {
      this.setDummySideBar(true);
      this.setScroll(false);
      let assessment = {
        id: this.assessmentId,
        assessmentType: this.assessmentType
      };
      sessionStorage.setItem("reportAssessment", JSON.stringify(assessment));
    },
    closeDialog() {
      this.resetSideBarAndScroll();
      sessionStorage.removeItem("reportAssessment");
      this.showSignUpDialog = false;
      if (this.assessmentType === "Desk assessment") {
        hj("stateChange", this.$router.currentRoute.path + `/deskreport`);
      }
    }
  }
};
</script>
