import jsPDF from "jspdf";
import * as html2canvas from "html2canvas";

async function createPDF(element, orientation, fileName) {
  const pdf = new jsPDF({
    orientation: orientation,
    unit: "px",
    format: "a4",
    hotfixes: ["px_scaling"]
  });

  await AddScreenShotToPDF(element, pdf);
  pdf.save(fileName);
}

async function AddScreenShotToPDF(element, pdf) {
  let pageWidth = pdf.internal.pageSize.getWidth();
  let pageHeight = pdf.internal.pageSize.getHeight();

  let canvas = await CreateCanvasFromElement(element);
  let ctx = canvas.getContext("2d");
  let printHeight = element.clientWidth * (pageHeight / pageWidth);

  let position = 0;
  while (position > -element.clientHeight) {
    let img = GetCroppedImage(ctx, position, printHeight, element.clientWidth);

    pdf.addImage(
      img,
      "PNG",
      25,
      0,
      pageWidth - 50,
      pageHeight,
      `alias${position}`,
      "FAST"
    );
    position -= printHeight;
    if (position > -element.clientHeight) {
      pdf.addPage();
    }
  }
}

function GetCroppedImage(context, position, printHeight, clientWidth) {
  let imageData = context.getImageData(
    0,
    -position * 2,
    clientWidth * 2,
    printHeight * 2
  );
  let canvas = document.createElement("canvas");
  canvas.width = clientWidth * 2;
  canvas.height = printHeight * 2;
  let ctx1 = canvas.getContext("2d");
  ctx1.putImageData(imageData, 0, 0);
  return canvas.toDataURL("image/png");
}

async function CreateCanvasFromElement(element) {
  return await html2canvas(element, {
    width: element.clientWidth,
    height: element.clientHeight,
    scale: 2
  });
}

export default {
  download(element, orientation, fileName) {
    return new Promise(async (resolve, reject) => {
      try {
        await createPDF(element, orientation, fileName);
        return resolve();
      } catch (err) {
        return reject(err);
      }
    });
  }
};
