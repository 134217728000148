var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { class: _vm.$vuetify.breakpoint.smAndDown ? "pa-4" : "pa-8" },
    [
      _c(
        "v-btn",
        {
          staticClass: "closeButton",
          attrs: { icon: "", "x-large": "" },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-8", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6", sm: "4", lg: "3" } },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/images/onboardingTour/completedChecklist.jpg"),
                  contain: "",
                  "max-width": "300px"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "p",
        {
          staticClass: "text-center mb-10",
          class: _vm.$vuetify.breakpoint.smAndDown ? "text-h4" : "text-h3"
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("onboarding.adminCheckList.allCompleteTitle")) +
              " "
          )
        ]
      ),
      _c(
        "p",
        {
          staticClass: "text-center mb-12",
          class: _vm.$vuetify.breakpoint.smAndDown ? "text-h5" : "text-h4"
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("onboarding.adminCheckList.allCompleteSubTitle")) +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }