<template>
  <div>
    <v-hover v-for="tag in item.tags" :key="tag.id" v-slot:default="{ hover }">
      <v-chip
        class="ma-1"
        :class="hover && editable ? 'white--text' : ''"
        :outlined="!hover || !editable"
        close-icon="close"
        :close="editable && hover && !disabled"
        :color="tag.color"
        @click:close="deleteTag(item, tag)"
        small
      >
        <div class="chipText">
          {{ tag.name }}
        </div>
      </v-chip>
    </v-hover>
    <v-btn
      v-if="addMultipleTags && item.tags.length !== 0 && !disabled"
      class="mx-2"
      icon
      color="primary"
      @click="toggleAddTags"
    >
      <v-icon> mdi-plus </v-icon>
    </v-btn>
    <div @click.stop v-if="editable && !disabled">
      <v-combobox
        v-if="item.tags.length === 0 || showAddTagDropdown"
        class="mx-2"
        style="max-width: 50%"
        :items="tagsToDisplayInDropDown"
        :label="
          $t('dashboardHistoryComponents.assessmentHistoryTags.addTagLabel')
        "
        solo
        flat
        dense
        hide-details
        item-text="name"
        :disabled="disabled"
        @input="addTag(item, $event.name ? $event.name : $event)"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                v-html="
                  $t(
                    'dashboardHistoryComponents.assessmentHistoryTags.pressEnterInstruction'
                  )
                "
              >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-chip :color="item.color" outlined>
            {{ item.name }}
          </v-chip>
        </template>
        <template v-slot:append-item v-if="manageTags">
          <v-btn
            color="primary"
            class="ma-3 mb-0"
            small
            @click="$emit('showManageTags')"
            >{{ $t("buttons.manageTags") }}</v-btn
          >
        </template>
      </v-combobox>
    </div>
  </div>
</template>

<script>
import { deleteEndUserTags, addEndUserTags } from "@/customApi";

export default {
  name: "TableTagsDropDown",
  props: {
    editable: Boolean,
    item: Object,
    tags: Array,
    manageTags: Boolean,
    addMultipleTags: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      showAddTagDropdown: false
    };
  },
  mounted() {
    this.updateTagColors();
  },
  watch: {
    tags() {
      this.updateTagColors();
    }
  },
  computed: {
    tagsToDisplayInDropDown() {
      const alreadyAssignedTags = this.item.tags.map(x => x.id);
      return this.tags.filter(
        x =>
          x.expiredAt === null &&
          !alreadyAssignedTags.includes(x.id) &&
          x.name.toLowerCase() != "everything"
      );
    }
  },
  methods: {
    updateTagColors() {
      this.assignColors();
      this.assignTagColors();
    },
    getNextTagColor() {
      let colorIndex = 0;
      let labelColors = this.$vuetify.theme.currentTheme.labelColors;
      if (this.tags.length > 0) {
        let lastColor = this.tags[this.tags.length - 1].color;
        colorIndex = labelColors.indexOf(lastColor);
        colorIndex = colorIndex === labelColors.length - 1 ? 0 : colorIndex + 1;
      }
      return labelColors[colorIndex];
    },
    getTagToAdd(tagName) {
      let existingTag = this.tags.find(
        x =>
          x.name.toLowerCase() === tagName.toLowerCase() && x.expiredAt === null
      );
      if (!existingTag) {
        return {
          name: tagName,
          color: this.getNextTagColor()
        };
      } else if (existingTag.expiredAt) {
        return {
          name: tagName,
          color: existingTag.color
        };
      } else {
        return existingTag;
      }
    },
    async addTag(item, tagName) {
      if (!tagName || tagName.toLowerCase() == "everything") {
        return;
      }
      let dto = {
        Id: item.id,
        Tags: [{ Name: tagName }]
      };
      try {
        let existingTag = this.tags.find(
          x =>
            x.name.toLowerCase() === tagName.toLowerCase() &&
            x.expiredAt === null
        );
        let tagToAdd = this.getTagToAdd(tagName);
        item.tags.push(tagToAdd);

        var tags = await addEndUserTags(dto);
        if (!existingTag) {
          tags[0].color = tagToAdd.color;
          item.tags.splice(0, 1, tags[0]);
          this.tags.push(tags[0]);
          this.$emit("input", this.tags);
        }
        this.showAddTagDropdown = false;
      } catch (error) {
        this.$logger.captureException(error);
        item.tags.splice(item.tags.length - 1, 1);
        this.$emit(
          "error",
          this.$t(
            "dashboardHistoryComponents.assessmentHistoryTags.error.creation"
          )
        );
      }
    },
    async deleteTag(item, tag) {
      let dto = {
        Id: item.id,
        Tags: [{ id: tag.id }]
      };
      try {
        let tagIndex = item.tags.indexOf(tag);
        item.tags.splice(tagIndex, 1);
        await deleteEndUserTags(dto);
      } catch (error) {
        this.$logger.captureException(error);
        item.tags.push(tag);
        this.$emit(
          "error",
          this.$t(
            "dashboardHistoryComponents.assessmentHistoryTags.error.deletion"
          )
        );
      }
    },
    assignColors() {
      let colorIndex = 0;
      let labelColors = this.$vuetify.theme.currentTheme.labelColors;
      for (let i = 0; i < this.tags.length; i++) {
        this.tags[i].color = labelColors[colorIndex];
        if (colorIndex === labelColors.length - 1) {
          colorIndex = 0;
        } else {
          colorIndex++;
        }
      }
    },
    assignTagColors() {
      this.item.tags.forEach(tag => {
        if (!tag.color) {
          let tagWithColor = this.tags.find(t => t.name === tag.name);
          tag.color = tagWithColor ? tagWithColor.color : "primary";
        }
      });
      this.item.tags = [...this.item.tags];
    },
    toggleAddTags() {
      this.showAddTagDropdown = !this.showAddTagDropdown;
    }
  }
};
</script>
