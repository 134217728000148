<template>
  <v-card max-width="400px">
    <v-card-text class="pa-1">
      <div class="text-caption text-wrap text-center" v-html="message"></div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "vitrue-tooltip",
  props: {
    message: String
  }
};
</script>

<style scoped>
.theme--light .v-card {
  border: 2px;
  border-style: solid !important;
  border-color: var(--v-primary-base) !important;
}
</style>
