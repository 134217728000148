export default {
  convertTextToState(stateText) {
    switch (stateText) {
      case "Pending":
      case "Completed":
      case "Failed":
        return stateText;
      case "Failed To Send Invitation":
        return "FailedToSentInvitation";
      case "In Progress":
        return "InProgress";
      default:
        return "Failed";
    }
  }
};
