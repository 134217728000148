<template>
  <v-card>
    <v-btn @click="$emit('close')" class="close-button" icon color="black">
      <v-icon>clear</v-icon>
    </v-btn>
    <v-row no-gutters class="pa-12" justify="center">
      <v-img
        height="200px"
        contain
        src="@/assets/images/undraw_no_data.svg"
      ></v-img>
      <v-card-title class="mt-5 mb-2">{{
        $t("dashboardHistoryComponents.restrictedAssessment.title")
      }}</v-card-title>
      <v-card-subtitle class="mb-3">
        {{ bodyText }}
      </v-card-subtitle>
      <v-row no-gutters justify="center">
        <v-col cols="8">
          <v-btn
            width="100%"
            rounded
            color="primary"
            @click="$router.push('/analytics', () => {})"
          >
            {{ $t("buttons.analytics") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "RestrictedAssessmentDialog",
  props: {
    assessmentType: String
  },
  computed: {
    ...mapGetters(["anonymousResults"]),
    bodyText() {
      if (this.anonymousResults) {
        return this.$t(
          "dashboardHistoryComponents.restrictedAssessment.anonymousText"
        );
      }
      return this.$t("dashboardHistoryComponents.restrictedAssessment.text", {
        "0": this.assessmentType.toLowerCase()
      });
    }
  }
};
</script>
